<div class="container">
    <div [formGroup]='deliveryForm'>
        <div class="delivery-form">
             <app-vehicle-delivery [vehicleDetails]='vehicleDetails'></app-vehicle-delivery>
             <asp-trade-in-info formControlName="tradeIn" from='delivery'></asp-trade-in-info>
             <div class="deliveryavailability">
              <div class='deliveryavailability-header'>{{ 'transportation.appointmentAvailability' | translate }}</div>
              <div class="deliveryavailability-content">
                <app-toggle-location *ngIf = "hasRemoteLocation" [ngClass]="{'app-toggle-location': hasRemoteLocation }" [allTransportOptions]="transportOptions$ | async"
                  [formControlName]="'transportationTypeCode'" (click)="checkZipCodes(deliveryForm.value.remoteZipCode)">
                </app-toggle-location>
      
                <ng-container *ngIf="deliveryForm.value.transportationTypeCode === 'REMOTE'" class="remote">
                  <div>{{ 'transportation.pleaseEnterYourZipcodeToCheckService' | translate }}</div>
                  <div class="zip" [ngClass]="{'error' : !isValidRemoteZip}">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'transportation.zipcode' | translate }}</mat-label>
                      <input matInput required  #zip 
                      placeholder="ZipCode" 
                      formControlName="remoteZipCode" 
                      (input)="checkZipCodes(zip.value)"/>
                      <mat-error>{{ 'transportation.sorryRemoteServicesAreNotAvailableOnTheEnteredZipcode' | translate }}</mat-error>
                    </mat-form-field>
                    <div class="tick" [ngClass]="{'selected' : isValidRemoteZip}"></div>
                  </div>
                </ng-container>
      
                <app-availability class='app-availability' formControlName='availability' [options]="availabilityOptions$ | async"
                [isAvailabilityLoading]="availabilityLoading$ | async" [availability]="availability$ | async"
                [selectedTransport]="deliveryForm.value.transportationTypeCode"
                (monthChanged)="onMonthChange($event)"></app-availability>
              </div>
              <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
            </div>
           
             <app-delivery-checklist></app-delivery-checklist>
             <app-contact-email-info formControlName="customer" [apptType]="apptType"></app-contact-email-info>
             <app-customer-info formControlName="address" [location]="deliveryForm.get('transportationTypeCode').value"
              [department]="'sales'"  [remoteValidZipcodes]="remoteValidZipcodes" ></app-customer-info>
             <div class='appointment-submit'>
              <button class='submit' [class.submitting]="isSubmitting" (click)='onSubmit()'
                [ngClass]="isValidForm() ? 'enabled':'btn-disabled'" [disabled]="isSubmitting">
                {{(isSubmitting ? ('appointmentInformation.submitting') : ('appointmentInformation.submit')) | translate}}
                <app-loading *ngIf="isSubmitting" loadingSymbol="dots" color="white" size=".6"></app-loading>
              </button>
            </div>
            <div class='appointment-details'>
              {{ "testDrive.yourAppointmentIsOn" | translate}} {{ getTranslation(deliveryForm.get('availability').value.appointmentStartDate | date : 'MMMM')}}
      {{deliveryForm.get('availability').value.appointmentStartDate | date : 'dd'}} {{'testDrive.aT' | translate }} {{deliveryForm.get('availability').value.timeSlotId }}
        {{ (deliveryForm.get('transportationTypeCode').value == 'REMOTE' ? ('testDrive.atRemoteLocation') :  ('testDrive.atDealershipLocation')) | translate }}
            </div>
        </div>
    </div>
</div>
