import { AuthService } from './../../services/auth.service';
import { Component, Input, OnInit, SimpleChanges, OnChanges, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CancelBottomSheetComponent } from '../cancel-bottom-sheet/cancel-bottom-sheet.component';
import { AddToCalendarComponent } from '../add-to-calendar/add-to-calendar.component';
import { ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../../services/asp/appointment.service';
import { ServiceAppointmentState } from '../../../store/service-appointment/service-appointment.reducer';
import { Store } from '@ngrx/store';
import { EditServiceAppointmentFromConfirm } from '../../../store/service-appointment/service-appointment.actions';
import * as util from '../../services/util.service';
import { IAppointment, AppointmentType } from '@signal/asp-data-commons';
import { DealerState } from '../../../store/dealer/dealer.reducer';
import * as fromSelectors from '../../../store/dealer/dealer.selectors';
import { Observable } from 'rxjs';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { AppointmentFormService } from '../../../service-appointment/appointment-form.service';
import { unsubscribeSubscriptions } from './../../../shared/services/util.service';
import { SubscriptionList } from '../../../shared/models/asp.types';
import {TranslateService} from '@ngx-translate/core';
import { ConfirmStatus } from '@signal/asp-data-commons/lib/enums/enum';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnChanges,AfterViewInit, OnDestroy {
  @Input() carImage = '/config/pub/3d/toyota/1006410/1000870/Exterior/3/1200_663_PNG/5f20d79-34590b1-9437dad-5cf1819-8942d14-105a308-25b1b67-2ec34e5-77f04a3-8bfecb5-a0034b0.png';
  @Input() appointment: IAppointment;
  @Input() makeModelYear: string;
  @Input() detailMap: { [key: string]: string };
  @Input() view: string;
  public calendarEvent: ICalendarEvent;
  registerUrl = '';
  signedIn: boolean;

  baseImageUrl: string;
  dealerPhone$: Observable<string>;
  dealerName$: Observable<string>;
  dealerWebsiteLink: string;
  setDefaultImg = util.setDefaultImg;
  private subs: SubscriptionList = {};
  isFromMail:boolean = false;
  isLoginVisible:boolean = true;
  isConfirm:boolean = false;
  dealerName: string;
  isManageMail:boolean = false;
  constructor(private readonly viewportScroller: ViewportScroller,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router,
    private readonly appointmentService: AppointmentService,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dealerState: Store<DealerState>,
    private readonly authService: AuthService,
    private readonly analyticsService : AnalyticsService,
    private readonly appointmentFormService : AppointmentFormService,
    private readonly route: ActivatedRoute,
    private readonly translate : TranslateService,
    private readonly changeDetector: ChangeDetectorRef) {
    this.baseImageUrl = util.imageBaseURL;
    this.registerUrl = util.environment.registerUrl;
    this.subs.route = this.route.queryParams.subscribe(params => {
      if(params && params.isConfirm === "true"){
        this.isConfirm = params.isConfirm;
      }
    });
    this.subs.authService = this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    this.subs.dealerName = this.dealerName$.subscribe(name=>{
      this.dealerName = name;
    })
    this.dealerPhone$ = this.dealerState.select(fromSelectors.selectDealerPhoneNumber);
    this.isLoginVisible = (util.countryCd==='US')?true:false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty(('appointment'))) {
      this.carImage = this.view === 'confirm' ? this.appointment?.vehicle?.imageUrl : this.carImage;
      if(this.isFromMail && !this.isManageMail && this.appointment){
        this.onAddToCalendar();
      }
      if(this.isConfirm && this.appointment){
        this.appointment.confirmAppointmentStatus = ConfirmStatus.CONFIRMED;
        this.subs.appointmentConfirm =  this.appointmentService.confirmAppointment(this.appointment).subscribe((data) => {
        });
      }
    }
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.subs.websiteLink = this.dealerState.select(fromSelectors.selectDealerWebsiteLink).subscribe(websiteLink => {
      this.dealerWebsiteLink = websiteLink;
    });
   this.subs.transportOptRemote = this.dealerState.select(fromSelectors.transportOptRemote).subscribe(res =>{
      if(res && res.cost){
        this.appointmentFormService.setCostAndFlatRateHrsForCompEligibleRental(res.cost,res.flatRateHours);
      }
    })

     
  }

  createAccount(){
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'create account clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
  }

  getDetailKeys() {
    if (this.detailMap) {
      return Object.keys(this.detailMap);
    }
  }

  getTranslation(key:string){
    if(!key || !this.detailMap[key]) {
      return '';
    }
    if(key === 'transportation.transportation') {
      this.detailMap[key] = this.translate.instant(this.detailMap[key]);
    }
    const value = this.detailMap[key];
    let translatedValue : string = value;
    if(value && value.includes('\n')){
      translatedValue = this.addressShort(value);
    }
    if(translatedValue){
      translatedValue = translatedValue.replace('Starts at', this.translate.instant('selectService.startsAt'));
      translatedValue = translatedValue.replace('Ranges from', this.translate.instant('selectService.rangesFrom'));
      translatedValue = translatedValue.replace('Lexus Complimentary Maintenance', this.translate.instant('selectService.lexusComplimentaryMaintenance'));
      translatedValue = translatedValue.replace('Covered by Toyota Care', `${this.appointment?.dealerCode?.startsWith('6') ? this.translate.instant('selectService.lexusComplimentaryMaintenance') 
      : this.translate.instant('selectService.coveredByToyotaCare')}`);
    }
    return translatedValue;
  }
  
  addressShort(value:string) {
    const address:string[]=value?.split('\n');
      let translatedAddress="";
      address.forEach((item)=>{
        if(item && item.includes(':'))
      {
        const fields: string[] = item?.split(':');
        let splittedAddress = '';
        fields.forEach((field)=>{
          splittedAddress+= (field ? this.translate.instant(field) : field) + ':';
        })
        translatedAddress += splittedAddress.slice(0, -1)+ '\n';
      }
      else{
        translatedAddress += (item ? this.translate.instant(item) : item)+ '\n';
      }
       }) 
     return translatedAddress.slice(0,-1);
  }

  onEdit() {
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'edit appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true);
    if (this.appointment.appointmentType === AppointmentType.TEST_DRIVE) {
      util.navigateToRoute('testdrive', '/testdrive', this.router, this.addQueryParam());
    } else if (this.appointment.appointmentType === AppointmentType.SERVICE) {
      this.serviceAppointmentState.dispatch(new EditServiceAppointmentFromConfirm());
      util.navigateToRoute('service', '/service', this.router, this.addQueryParam());
    } else if (this.appointment.appointmentType === AppointmentType.DELIVERY) {
      util.navigateToRoute('delivery', '/delivery', this.router, this.addQueryParam());
    }else if (this.appointment.appointmentType === AppointmentType.LEARNING){
      util.navigateToRoute('learning','/learning', this.router, this.addQueryParam());
    }
  }

  addQueryParam() {
    return {
      queryParams: {
        id: this.appointment.appointmentId
      }
    };
  }
  
  onCancel(): void {
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'cancel appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    const bottomSheetRef = this.bottomSheet.open(CancelBottomSheetComponent);
    this.subs.bottomSheetRef = bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      if (dataFromChild === 'yes') {
        this.subs.cancelSub = this.appointmentService.cancel(this.appointment).subscribe((data) => {
          if (this.signedIn) {
            util.navigateToRoute('dashboard', '/dashboard', this.router);
          } else {
            util.navigateToRoute('welcome', '/welcome', this.router);
          }
        });
      }
    });
  }

  onManage() {
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'view and manage appointments clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    if (this.signedIn) {
      util.navigateToRoute('dashboard', '/dashboard', this.router);
    } else {
      window.open(this.registerUrl);
    }
  }

  onAddToCalendar() {
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'add to calendar clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
    const start = this.detailMap['selectService.time'].split('at');
    let custAdd;
    if(this.appointment.appointmentType === AppointmentType.SERVICE){
       custAdd = this.appointment?.pickUpAddress ? this.appointment?.pickUpAddress : this.appointment.customer?.address;
    }
    else{
      custAdd = this.appointment.customer?.otherAddress ? this.appointment.customer?.otherAddress : this.appointment.customer?.address;
    }
    const address = this.appointment.transportationTypeCode === 'REMOTE' ? 
    `${custAdd?.addressLine1}, ${custAdd?.addressLine2 ? custAdd?.addressLine2 + ', ' : ''} ${custAdd?.city}, ${custAdd?.state} - ${custAdd?.zip}` : this.dealerName;
    this.calendarEvent = {
      title: `${this.translate.instant(`dashboardAppointments.${this.appointment.appointmentType?.toLowerCase()}`)} ${this.translate.instant('delivery.for')} ${this.appointment.vehicle.model ? util.titleCase(this.appointment.vehicle.model) : `VIN ${this.appointment.vehicle.vin}`} ${this.translate.instant('testDrive.at')} ${this.dealerName}`,
      start: new Date(`${start[0]}, ${new Date().getFullYear()} ${start[1]}`),
      duration: 60,
      address: this.getLocationTranslation(this.detailMap['transportation.location']),
      description: `${this.translate.instant('dashboardAppointments.your')} ${this.translate.instant(`dashboardAppointments.${this.appointment.appointmentType?.toLowerCase()}`)?.toLowerCase()}  ${this.translate.instant('delivery.for')} ${this.makeModelYear} ${this.translate.instant('dashboardAppointments.is')} ${this.translate.instant(`common.${this.appointment.appointmentStatus?.toLowerCase()}`)?.toLowerCase()} ${this.translate.instant('testDrive.at')} ${address}.`
    };
    this.bottomSheet.open(AddToCalendarComponent, {
      data: this.calendarEvent
    });
  }

  dealerInfoClick(){
    const linkTrackingData = {
      content_section : 'service is scheduled ',
      link_module : 'confirmation details',
      link_text : 'dealer info clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true)
  }

  redirectToT3() {
    if (!!this.dealerWebsiteLink) {
      window.location.href = this.dealerWebsiteLink;
    }
  }
  ngAfterViewInit(): void {
    this.subs.router = this.route.queryParams.subscribe(params => {
      if(params && params.isFromMail){
        this.isFromMail = true;
      }
      if(params && params.isManageMail) {
        this.isManageMail = true;
      }
    })
    
  }
  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
    this.isFromMail = false;
    this.isConfirm = false;
    this.isManageMail = false;
    this.changeDetector.detach();
  }
  getAppointmentMsg(appointmentType:string, appointmentStatus:string){
    
    if(appointmentType && appointmentStatus)
    {
      let type = this.translate.instant(`dashboardAppointments.${appointmentType?.toLowerCase()}`);
      const status = this.translate.instant(`common.${appointmentStatus?.toLowerCase()}`);
      if(type == 'Learning'){
        type = 'Learning Appointment'
      }
      if(this.isConfirm){
        
        const status = this.translate.instant('common.confirmed');
        this.appointment.confirmAppointmentStatus = ConfirmStatus.CONFIRMED;
        return `Your ${appointmentType} is ${status}`;
      }else{
        return (type && status) ? this.translate.instant('dashboardAppointments.yourAppointmentTypeIsAppointmentStatus', 
      {appointmentType: type,appointmentStatus: status}) : `Your ${appointmentType} is ${appointmentStatus}`;
    
      }
    }
  }

  getAppointmentSummaryMsg(appointmentType, appointmentStatus) {
    const transObj = {
      "Test Drive": 'dashboardAppointments.testDrive',
      "Service": 'dashboardAppointments.service',
      "Delivery": 'dashboardAppointments.delivery',
      "Learning": 'dashboardAppointments.learning'
    }
    if(this.isFromMail === true && appointmentType) {
      return `${this.translate.instant(transObj[appointmentType])} ${this.translate.instant('confirm.appointmentSummary')}`;
    }
    else {
      return this.translate.instant('confirm.appointmentSummary');
    }
  }

  getLocationTranslation(location:string){
    return this.getTranslation(location).replace(this.dealerName, `${this.dealerName},`)
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
   
}
