<div class="container">
    <form [formGroup]='deliveryForm' class="delivery">
        <div class="delivery-form">
            <mat-accordion multi>
                <app-vehicle-delivery [vehicleDetails]='vehicleDetails' [dealerCode]="dealerCode"></app-vehicle-delivery>

                <div style="padding-bottom: 2rem;">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="title">
                                {{ 'delivery.customerInformation' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <app-contact-email-info class="customer-info" formControlName="customer"
                            [showContactInfo]="false" [apptType]="apptType"></app-contact-email-info>
                    </mat-expansion-panel>
                </div>
                <div class="deliveryavailability-content" style="padding-bottom: 2rem;">
                    <mat-expansion-panel style="width: 100%">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="title">
                                {{ 'delivery.appointmentLocation' | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                    <div *ngIf="isUpdate ? deliveryForm.value.transportationTypeCode : true">
                        <app-toggle-location *ngIf="hasRemoteLocation"
                            [ngClass]="{'app-toggle-location': hasRemoteLocation }"
                            [allTransportOptions]="transportOptions$ | async"
                            [formControlName]="'transportationTypeCode'"
                            (click)="checkZipCodes(deliveryForm.value.remoteZipCode)"
                            [department]="department">
                        </app-toggle-location>
                    </div>

                        <ng-container *ngIf="deliveryForm.value.transportationTypeCode === 'REMOTE'" class="remote">
                            <div class="text-center">{{ 'transportation.pleaseEnterYourZipcodeToCheckService' |
                                translate }}</div>
                            <div class="zip" [ngClass]="{'error' : !isValidRemoteZip}">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'transportation.zipcode' | translate }}</mat-label>
                                    <input matInput required #zip placeholder="ZipCode" formControlName="remoteZipCode"
                                        (input)="checkZipCodes(zip.value)" />
                                    <mat-error>{{ 'transportation.sorryRemoteServicesAreNotAvailableOnTheEnteredZipcode'
                                        | translate }}</mat-error>
                                </mat-form-field>
                                <div class="tick" [ngClass]="{'selected' : isValidRemoteZip}"></div>
                            </div>
                        </ng-container>

                        <app-customer-info formControlName="address"
                            [location]="deliveryForm.get('transportationTypeCode').value" [department]="'sales'"
                            [remoteValidZipcodes]="remoteValidZipcodes" [from]="'sales'"></app-customer-info>
                    </mat-expansion-panel>

                </div>

                <div *ngIf="!(availabilityOptionsLoading$ | async)">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="title">
                                {{ ('transportation.salesAdvisor' | translate) | titlecase}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-availability *ngIf="languageId!=='' && isUpdate;else noEdit" class='app-availability' formControlName='availability' [source]="'sales'"
                            [options]="availabilityOptions$ | async"
                            [isAvailabilityLoading]="availabilityLoading$ | async"
                            [availability]="availability$ | async" [apptType]="apptType"
                            [lockSalesConsultant]="lockSalesConsultant"
                            [selectedTransport]="deliveryForm.value.transportationTypeCode"
                            [customerLanguage]="languageId"
                            [isEditMode]="isUpdate">
                        </app-availability>
                        <ng-template #noEdit>
                            <app-availability class='app-availability' formControlName='availability' [source]="'sales'"
                                [options]="availabilityOptions$ | async"
                                [isAvailabilityLoading]="availabilityLoading$ | async"
                                [availability]="availability$ | async" [apptType]="apptType"
                                [lockSalesConsultant]="lockSalesConsultant"
                                [selectedTransport]="deliveryForm.value.transportationTypeCode">
                            </app-availability>
                        </ng-template>


                    </mat-expansion-panel>
                </div>

                <asp-trade-in-info formControlName="tradeIn" from='delivery'></asp-trade-in-info>

                <app-delivery-checklist></app-delivery-checklist>
            </mat-accordion>
        </div>
    </form>
</div>