<div class='back-to-dashboard' (click)="backToDashboard()">
</div>
<div class="heading">
    <ng-container *ngIf="(customerDetails$ | async)?.firstName">
        {{ 'delivery.for' | translate}} {{(customerDetails$ | async)?.firstName |titlecase }} {{(customerDetails$ | async)?.lastName |titlecase }}
    </ng-container>
</div>
<div class="container">
    <app-select-learning-vehicle [showMileageField]="false"></app-select-learning-vehicle>

    <div [formGroup] = "techAppointmentForm">

        <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
        <mat-panel-title>
            <div class='comments-header'>
                {{ 'selectService.customersQuestions' | translate}}
            </div>
        </mat-panel-title>
        <form [formGroup]='techAppointmentForm' class='comments-info'>
            <mat-form-field class="no-fixed-height comment-width" appearance="outline">
            <textarea matInput rows='5' formControlName='appointmentComments'></textarea>
            </mat-form-field>
        </form>

    <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
        
                <mat-panel-title>
                <div class='comments-header'>
                    {{ 'transportation.appointmentAvailability' | translate}}
                </div>
                </mat-panel-title>
            <app-availability *ngIf="editBool && languageId!==''; else noEdit" class='app-availability' formControlName='availability'
            [options]="availabilityOptions$ | async" [isAvailabilityLoading]="availabilityLoading$ | async"
            [availability]="availability$ | async" [selectedTransport]="DEALER" (monthChanged)="onMonthChange($event)" 
            [isEditMode]="editBool" [customerLanguage]="languageId">
            </app-availability>
            <ng-template #noEdit>
                <app-availability  class='app-availability' formControlName='availability'
                [options]="availabilityOptions$ | async" [isAvailabilityLoading]="availabilityLoading$ | async"
                [availability]="availability$ | async" [selectedTransport]="DEALER" (monthChanged)="onMonthChange($event)">
                </app-availability>
            </ng-template>
        <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
        <mat-panel-title>
            </mat-panel-title>

        <app-contact-email-info formControlName="customer" [apptType]="apptType"></app-contact-email-info>

        <div id="accept-terms">
            <span class="label">
              {{getDisclaimerText}}
            </span>
        </div>
        
        <div class='appointment-submit'>
            <button class='submit' [class.submitting]="isSubmitting" [ngClass]="isValidForm() ? 'enabled':'btn-disabled'" (click)="onSubmit()" [disabled]="isSubmitting">{{'appointmentInformation.submit' | translate}}
                <app-loading *ngIf="isSubmitting" loadingSymbol="dots" color="white" size=".6"></app-loading>
            </button>
        </div>
        
        <div class='appointment-details'>
            {{'testDrive.yourAppointmentIsOn' | translate}}{{getTranslation(techAppointmentForm.get('availability')?.value?.appointmentStartDate | date : 'MMMM')}} {{ techAppointmentForm.get('availability')?.value?.appointmentStartDate | date : ' dd' }} 
            {{'testDrive.aT' | translate}} {{ techAppointmentForm.get('availability')?.value?.timeSlotId }} at
            {{ techAppointmentForm.get('transportationTypeCode').value == 'REMOTE' ? ('testDrive.atRemoteLocation' | translate) : ('testDrive.atDealershipLocation' | translate) }}
        </div>
    </div>
</div>
