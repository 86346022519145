import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SalesAppointmentState } from './../../../store/sales-appointment/sales-appointment.reducer';
import * as fromActions from './../../../store/sales-appointment/sales-appointment.actions';
import * as fromSelector from './../../../store/sales-appointment/sales-appointment.selectors';
import { IChecklist, IChecklistRequest } from '@signal/asp-data-commons';
import _ from 'lodash';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscriptionList } from '../../../shared/models/asp.types';
import { convertMinsToHourMins, unsubscribeSubscriptions } from './../../../shared/services/util.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit, OnDestroy {
  @Input() isUpdate: boolean;

  onlineChecklist$: Observable<IChecklist[]>;
  private subs: SubscriptionList = {};
  private lastValue: any;
  checklistItems = [];
  comments = {}
  formattedOnlineChecklist = [];
  selectedCategoryTracker = {};
  allOnlineCategories = [];
  step: FormGroup;
  checklistForm$: Observable<IChecklistRequest[]>;
  allChecklistLoading$: Observable<boolean>;
  temp: boolean = true;

  constructor(
    private readonly salesState: Store<SalesAppointmentState>,
    private readonly formBuilder: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.salesState.dispatch(new fromActions.LoadAllChecklist());
    this.onlineChecklist$ = this.salesState.select(fromSelector.selectOnlineChecklist);
    this.allChecklistLoading$ = this.salesState.select(fromSelector.selectAllChecklistLoading);
    this.subs.onlineChecklist = this.onlineChecklist$.subscribe(list => {
      this.formattedOnlineChecklist = this.getSortedChecklist(list);
      this.allOnlineCategories = Object.keys(this.formattedOnlineChecklist).sort();
      if (list.length && !this.isUpdate) {
        this.initializeTracker();
        this.ready(this.allOnlineCategories, this.formattedOnlineChecklist);
        this.updateChecklistToState();
      }

      this.checklistForm$ = this.salesState.select(fromSelector.selectChecklistForm);
      this.subs.checklistFromState = this.checklistForm$.subscribe(data => {
        if (data && data.length && this.isUpdate && this.allOnlineCategories.length) {
          if (_.isEqual(data, this.lastValue)) {
            return;
          }
          this.lastValue = data;
          this.initializeTracker();
          this.ready(this.allOnlineCategories, this.formattedOnlineChecklist);
          this.setFormValuesToSelectedChecklist(data);
        }
      })
    })
  }

  initializeTracker() {
    this.allOnlineCategories.forEach(cat => {
      this.selectedCategoryTracker[cat] = { total: this.formattedOnlineChecklist[cat].length, selected: 0, selectionText: "" }
    });
  }

  private findSelectedChecklist(selectedChecklist: IChecklistRequest[], item) {
    return selectedChecklist.find(c => c.checklistId === item.checklistId);
  }

  private setChildChecklist(group: FormGroup, childArray: FormArray, selectedChildlist) {
    const item = group.value;
    let totalTime = 0;
    childArray.controls.forEach(child => {
      if (selectedChildlist.includes(child.value.id) && !child.value.isChecked) {
        child.get('isChecked').patchValue(true);
        group.get('childSelected').patchValue(item.childSelected + 1);
        totalTime += child.value.duration;
      }
      else if (child.value.isChecked) {
        child.get('isChecked').patchValue(false);
        group.get('childSelected').patchValue(item.childSelected - 1);
      }
    });
    group.get('totalTime').patchValue(totalTime);
  }

  private setSelectedValueToForm(cat, group, checklist) {
    const item = group.value;
    const checklistData = checklist.details;
    const selectedCount = this.selectedCategoryTracker[cat].selected;
    this.selectedCategoryTracker[cat].selected = item.isChecked ? selectedCount : selectedCount + 1;
    group.get('isChecked').patchValue(true);
    group.get('totalTime').patchValue(checklistData?.childChecklist?.length ? 0 : item.duration);
    if (checklistData.childChecklist && checklistData?.childChecklist?.length) {
      const childArray = group.controls['childChecklist'] as FormArray;
      const selectedChildlist = checklistData?.childChecklist.map(x => x.id);
      this.setChildChecklist(group, childArray, selectedChildlist);
    }
    group.get('displayTotalDuration').patchValue(convertMinsToHourMins(group.value.totalTime));
  }

  setFormValuesToSelectedChecklist(checklistRequest: IChecklistRequest[]) {
    this.allOnlineCategories.forEach(i => {
      const catArray = this.step.controls[i] as FormArray;
      catArray.controls.forEach(item => {
        const selectedChecklist = this.findSelectedChecklist(checklistRequest, item.value);
        if (!!selectedChecklist) {
          item.get('commentsFromUser').patchValue(selectedChecklist.comments ? selectedChecklist.comments : '');
          if (!item.value.isMandatory) {
            this.setSelectedValueToForm(i, item, selectedChecklist);
          }
          item.get('isExpanded').patchValue(item.value.userComments && item.value.commentsRequired &&
            item.value.isChecked && !item.value.commentsFromUser ? true : false);
          this.setCommentsValidator(item);
        }
        this.selectedCategoryTracker[i].selectionText = this.selectedCategoryTracker[i].selected === catArray.length ? "deselect all" : 'select all';
      })

    })
  }

  getSortedChecklist(checklist: IChecklist[]) {
    let checklistItems = [...checklist];
    checklistItems.sort((a: IChecklist, b: IChecklist) => {
      return a.displayName?.localeCompare(b.displayName);
    })
    return _.groupBy(checklistItems, 'category');
  }

  updateChecklistToState() {
    const selectedChecklist = this.getSelectedChecklist();
    if (_.isEqual(selectedChecklist, this.lastValue)) {
      return;
    }
    this.lastValue = selectedChecklist.data;
    this.salesState.dispatch(new fromActions.CreateChecklist(selectedChecklist));
    if(this.subs) {
      unsubscribeSubscriptions(this.subs);
    }
  }

  ready(cat, list) {
    const obj = {};
    cat.forEach(i => {
      const temp = this.setSelected(i, list[i]);
      obj[i] = this.formBuilder.array(temp);
    })
    this.step = this.formBuilder.group(obj);
  }

  createData(arr) {
    const temp = arr.map(item => {
      return {
        checklistId: item.checklistId,
        comments: item.commentsFromUser,
        details: item
      }
    })
    return this.deleteExtraKeys(_.cloneDeep({ data: temp }), ["isChecked", "childSelected", "isExpanded", "commentsFromUser", "totalTime", "displayTotalDuration"], ["isChecked", "displayTime"]);
  }

  deleteExtraKeys(obj, parentKeyList, childKeyList) {
    obj.data.forEach(item => {
      parentKeyList.forEach(key => {
        delete item.details[key];
      })
      if (item?.details?.childChecklist?.length) {
        item.details.childChecklist.forEach((child, i) => {
          if (!child.isChecked) {
            delete item.details.childChecklist[i];
          }
          else {
            childKeyList.forEach(key => {
              delete child[key];
            })
          }
        })
      }
    })
    return obj;
  }

  private setCommentsValidator(checklistGroup) {
    const commentsItem = checklistGroup.get('commentsFromUser') as FormControl;
    if (checklistGroup.value.isChecked && checklistGroup.value.userComments && checklistGroup.value.commentsRequired && !checklistGroup.value.commentsFromUser) {
      commentsItem.setValidators(Validators.required);
    } else {
      commentsItem.clearValidators();
    }
    commentsItem.updateValueAndValidity();
  }

  getChecklistDuration(checklist: any, childChecklistArr: any[]) {
    let duration = 0;
    if (checklist.isChecked && checklist.isChildChecklistEnabled && childChecklistArr && childChecklistArr.length > 0) {
      duration = childChecklistArr.map(s => s.online && s.isChecked ? s.duration : 0).reduce((total, current) => { return total + current }, 0);
    }
    else if (checklist.isChecked) {
      duration = checklist.duration;
    }
    return duration;
  }

  setSelected(cat, list) {
    const checklistArr = list.map(i => {
      let childChecklistArr = this.formBuilder.array([]);
      let obj = _.cloneDeep(i)
      if ((obj.autoSelect && !this.isUpdate) || obj.isMandatory) {
        obj.isChecked = true;
        this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].selected + 1;
        obj.childSelected = 0;
        if (obj.isChildChecklistEnabled && obj.childChecklist && obj.childChecklist.length > 0) {
          obj.childChecklist.forEach(j => {
            if (j.online) {
              j.isChecked = true;
              obj.childSelected = obj.childSelected + 1;
              j.displayTime = convertMinsToHourMins(j.duration);
              childChecklistArr.push(this.formBuilder.group(j));
            }
          });
        }
      } else {
        obj.isChecked = false;
        obj.childSelected = 0;
        if (obj.isChildChecklistEnabled && obj.childChecklist && obj.childChecklist.length > 0) {
          let childSelected = 0;
          obj.childChecklist.forEach(j => {
            if (j.online) {
              j.isChecked = false;
              if (j.autoSelect && !this.isUpdate) {
                j.isChecked = true;
                obj.childSelected = obj.childSelected + 1;
                obj.isChecked = true;
                childSelected += 1;
              }
              this.selectedCategoryTracker[cat].selected = childSelected > 0 ? this.selectedCategoryTracker[cat].selected + 1 :
                this.selectedCategoryTracker[cat].selected;
              j.displayTime = convertMinsToHourMins(j.duration);
              childChecklistArr.push(this.formBuilder.group(j));
            }
          })
        }
      }
      this.selectedCategoryTracker[cat].selectionText = this.selectedCategoryTracker[cat].selected === list.length ? "deselect all" : 'select all';
      obj.commentsFromUser = "";
      obj.isExpanded = obj.userComments && obj.commentsRequired && obj.isChecked ? true : false;
      obj.totalTime = this.getChecklistDuration(obj, childChecklistArr.value);
      obj.displayTotalDuration = convertMinsToHourMins(obj.totalTime);
      delete obj.childChecklist;
      const group = this.formBuilder.group({ ...obj, childChecklist: childChecklistArr });
      this.setCommentsValidator(group);
      return group;
    })
    return checklistArr;
  }

  onParentCheckboxChange(e, cat, index) {
    const group = this.categoryFormArray(cat, index);
    const item = group.value;
    if (e.source.id === item.checklistId) {
      item.isChecked = e.checked;
      item.isExpanded = e.checked;
      if (!e.checked) {
        this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].selected - 1;
      } else {
        this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].selected + 1;
      }
      item.childChecklist.forEach(c => {
        c.isChecked = e.checked;
      })
      if (e.checked) {
        item.childSelected = item.childChecklist.length ? item.childChecklist.length : 0;
        let totalTime = 0;
        item.childChecklist.forEach(c => {
          totalTime = totalTime + c.duration;
        })
        item.totalTime = item.childChecklist.length ? totalTime : item.duration;
        item.displayTotalDuration = convertMinsToHourMins(item.totalTime);
      } else {
        item.childSelected = 0;
        item.totalTime = 0;
        item.displayTotalDuration = convertMinsToHourMins(item.totalTime);
      }
    }
    this.selectedCategoryTracker[cat].selectionText = this.selectedCategoryTracker[cat].selected ===
      this.step.controls[cat].value.length ? "deselect all" : 'select all';
    group.patchValue(item);
    this.setCommentsValidator(group);
    this.updateChecklistToState();
  }

  categoryFormArray(cat: string, index) {
    return (this.step.controls[cat] as FormArray).controls[index] as FormGroup;
  }

  getSelectedChecklist() {
    const data = _.cloneDeep(this.allOnlineCategories.map(cat => this.step.controls[cat].value).reduce((acc, curVal) => {
      return acc.concat(curVal)
    }, []));
    const filtered = data.filter(x => x.isChecked);
    return this.createData(filtered);
  }

  onChildCheckboxChange(e, cat, checklistId, childId, index, childIndex) {
    const group = this.categoryFormArray(cat, index);
    const item = group.value;
    if (item.checklistId === checklistId) {
      const c = item.childChecklist[childIndex];
      if (c.id === childId) {
        c.isChecked = e.checked;
        if (e.checked) {
          this.selectedCategoryTracker[cat].selected = item.childSelected === 0 ?
            this.selectedCategoryTracker[cat].selected + 1 : this.selectedCategoryTracker[cat].selected;
          item.childSelected = item.childSelected + 1;
          item.totalTime = item.totalTime + c.duration;
          item.displayTotalDuration = convertMinsToHourMins(item.totalTime);
        }
        else {
          item.childSelected = item.childSelected - 1;
          if (item.childSelected === 0) {
            this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].selected - 1;
          }
          item.totalTime = item.totalTime - c.duration;
          item.displayTotalDuration = convertMinsToHourMins(item.totalTime);
        }
        item.isChecked = this.checkAllChild(item.childChecklist);
      }
      item.childChecklist[childIndex] = c;
    }
    this.selectedCategoryTracker[cat].selectionText = this.selectedCategoryTracker[cat].selected ===
      this.step.controls[cat].value.length ? "deselect all" : 'select all';
    group.patchValue(item);
    this.setCommentsValidator(group);
    this.updateChecklistToState();
  }

  checkAllChild(list) {
    return list.some(i => i.isChecked)
  }

  clearSelections(cat) {
    if (this.selectedCategoryTracker[cat].selectionText === "deselect all") {
      this.step.controls[cat].value.forEach((item, i) => {
        if (!item.isMandatory && item.isChecked) {
          const group = this.categoryFormArray(cat, i);
          group.controls.isChecked.patchValue(false);
          this.selectedCategoryTracker[cat].selectionText = "select all";
          this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].selected - 1;
          item.childChecklist.forEach(c => {
            c.isChecked = false;
          })
          group.controls.totalTime.patchValue(0);
          group.controls.displayTotalDuration.patchValue(convertMinsToHourMins(group.value.totalTime));
          group.controls.isExpanded.patchValue(false);
          this.setCommentsValidator(group);
        }
      })
    } else {
      this.step.controls[cat].value.forEach((item, i) => {
        const group = this.categoryFormArray(cat, i);
        group.controls.isChecked.patchValue(true);
        this.selectedCategoryTracker[cat].selectionText = "deselect all";
        this.selectedCategoryTracker[cat].selected = this.selectedCategoryTracker[cat].total;
        let totalTime = 0;
        item.childChecklist.forEach(c => {
          c.isChecked = true;
          totalTime = totalTime + c.duration;
        });
        group.controls.isExpanded.patchValue(group.value.userComments && group.value.commentsRequired &&
          group.value.isChecked && !group.value.commentsFromUser ? true : false);
        group.controls.totalTime.patchValue(item.childChecklist.length ? totalTime : item.duration);
        group.controls.displayTotalDuration.patchValue(convertMinsToHourMins(group.value.totalTime));
        this.setCommentsValidator(group);
      });
    }
    this.updateChecklistToState();
  }

  handleTextArea(cat, index) {
    const group = this.categoryFormArray(cat, index);
    this.setCommentsValidator(group);
    this.updateChecklistToState();
  }

  commentsFromUserControlHasError(cat, index) {
    return (this.categoryFormArray(cat, index) as FormGroup).get('commentsFromUser').errors?.required;
  }

  ngOnDestroy(): void {
    unsubscribeSubscriptions(this.subs);
  }

}
