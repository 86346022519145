import { AuthService } from './../../shared/services/auth.service';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, Output, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import { GsapAnimationService } from '../../shared/services/gsap-animation.service';
import * as util from '../../shared/services/util.service';
import { Make } from '../../shared/models/brand.model';
import * as dashboardActions from '../../store/dashboard/dashboard.actions';
import { selectAppointmentsForVin } from '../../store/dashboard/dashboard.selectors';
import { VehicleModelYearList } from '../../shared/models/service-appointments/series-data';
import { UserVehicleService } from '../../shared/services/user-vehicle.service';
import { ActivatedRoute } from '@angular/router';
import { iif, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IToyotaLexusModelDetails, ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import * as fromDashboard from '../../store/dashboard/dashboard.selectors';
import * as fromSelectors from '../../store/service-appointment/service-appointment.selectors';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';
import { LoadModelYearAndModels, UpdateIsRedirected, UpdateServiceForm } from '../../store/service-appointment/service-appointment.actions';
import { SubscriptionList } from '../../shared/models/asp.types';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import * as fromValidator from '../service-appointment.validator';
import { AspBannerService } from '../../shared/components/asp-banner/asp-banner.service';
import { VehicleService } from '../../shared/services/vehicle.service';
import { VehicleImage } from '../../shared/models/vehicle-image';
import { IVehicle } from '@signal/asp-data-commons';
import { VehicleCardComponent } from './vehicle-card/vehicle-card.component';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { serviceSettings } from '../../store/dealer/dealer.selectors';
import { FactoryPrintService } from '../../shared/components/factory-services-dialog/factory-print.service';
import { DateTime } from 'luxon'
import { AnalyticsService } from './../../shared/services/analytics.service';
import { TranslateService} from '@ngx-translate/core';
import { Telematics } from './../../shared/models/dashboard/telematics.model';
@Component({
  selector: 'app-select-vehicle',
  templateUrl: './select-vehicle.component.html',
  styleUrls: ['./select-vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectVehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() stepIndex = 0;
  @Output() toShowFooter = new EventEmitter();
  isOwnVehicleHasNoAppt: boolean = false;
  vehicles: IVehicle[] = [];
  step: FormGroup;
  title: string;
  switcherText: string;

  signedIn = false;
  isMakeOther = false;
  isOwnVehicle = true;
  userCd:string;
  makes: Make[] = [];
  models : string[];
  yearmodels: string[] = [];
  vehicleId: string;
  previousMakeModelYear='';
  telematicsLoading : boolean = false;
  isOwnVehicle$: Observable<boolean>;
  isUserVehiclesLoading$: Observable<boolean>;
  isEditMode$: Observable<boolean>;
  isRedirected$: Observable<boolean>;
  modelYearAndModelsLoading$: Observable<boolean>;
  newvehicle: any;
  isVinMandate: boolean;
  hasExistingAppt = false;
  existingApptText='';
  estimatedMiles: string;
  enterMiles: string;
  milesIsRequired: string;
  logedIn: boolean = false;
  showMultipleVehicle: boolean = false;
  isLoginVisible: boolean = true;

  private modelYearSeries: VehicleModelYearList;
  private subs: SubscriptionList = {};
  private modelYearAndModelsData$: Observable<IToyotaLexusModelDetails>;
  private isEditMode: boolean;
  private readonly isRedirected: boolean;
  private lastVehicleSelected: string;
  private toyotaLexusModelDetails: IToyotaLexusModelDetails;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly gsap: GsapAnimationService,
    private readonly userVehicleService: UserVehicleService,
    private readonly vehicleService: VehicleService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    private readonly bannerService: AspBannerService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly authService: AuthService,
    private readonly dealerState: Store<DealerState>,
    private factoryPrintService:FactoryPrintService,
    private readonly analyticsService : AnalyticsService,
    private readonly translate : TranslateService
  ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
    this.authService.user.subscribe(user => {
      if (user && !this.authService.isExpired()) {
        this.logedIn = true;
        this.userCd=user?.extension_tmsguid;
      }
    });
    this.makes = util.getMakes();
    this.bindThis();

    this.activatedRoute.queryParams.subscribe(params => {
      this.vehicleId = params.vehicleId;
    });
    this.isLoginVisible = (util.countryCd === 'US')?true:false;
    this.estimatedMiles = this.translate.instant(`welcome.${this.translate.instant('COUNTRYTRANSLATION.estimatedMiles')}`);
    this.enterMiles = this.translate.instant(`welcome.${this.translate.instant('COUNTRYTRANSLATION.enterMiles')}`);
    this.milesIsRequired = this.translate.instant(`welcome.${this.translate.instant('COUNTRYTRANSLATION.milesIsRequired')}`);
  }

  ngOnInit(): void {
    this.dashboardState.select(fromDashboard.selectServiceApptForDashboard).subscribe((response) => {
      this.newvehicle = response.filter((item) => {
        return item.isOwnVehicle;
      });
    });
    this.ready();
  }

  onVehicleFieldChange(
    change: { field: string; value: string },
    vehicle: IVehicle
  ) {
    /* for now only mileage is changing, hence no checking for field & value */
    setTimeout(() => {
      if (this.step.controls.vin.value !== vehicle.vin) {
        this.serviceAppointmentState.dispatch(new UpdateServiceForm({
          path: 'vehicleForm',
          value: { ...vehicle, mileage: +(change?.value || 0), isOwnVehicle: true }
        }));
      } else {
        this.setFormControlValue('mileage', +(change?.value || 0));
      }
    });
  }

  /**
   * Sets the necessary fields and form validators based on vehicle type 'Own' or 'Different'
   */
  toggleVehicleType() {
    this.resetAllInputs();
    if (this.isOwnVehicle || !this.signedIn) {
      this.toShowFooter.emit(true);
      this.setOwnVehicleFalse();
    } else {
      this.setOwnVehicleTrue();
    }
  }

  onSelect(type,selection){
    const commonTrackingData = {
      content_section : 'vehicle tab',
      link_module : 'vehicle details',
    }
    const makeSelectTrackingData = {
      ...commonTrackingData,
      link_text : type,
      link_input_field : selection.value
    }
    this.analyticsService.serviceSchedulerVehicleData = {
      ...this.analyticsService.serviceSchedulerVehicleData,
      make : selection
    };
    this.analyticsService.trackLink(makeSelectTrackingData,true);
  }

  /**
   * Sets the subscriptions of the current step form
   *
   * 1. Make formControl - to check whether 'Other' is chosen or not
   */
  setFormSubscription() {
    const commonTrackingData = {
      content_section : 'vehicle tab',
      link_module : 'vehicle details',
    }
    this.subs.makeSub = this.step.get('make').valueChanges.subscribe((make: string) => {
      if(make){
        this.analyticsService.serviceSchedulerVehicleData = {
          ...this.analyticsService.serviceSchedulerVehicleData,
          make : make
        };
      }
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      if (this.isOtherMake(make)) {
        this.isMakeOther = true;
        this.step.controls.year.reset(null, { emitEvent: false });
        this.step.controls.model.reset(null, { emitEvent: false });
      } else {
        this.isMakeOther = false;
        if (make && make.toLocaleLowerCase() === 'toyota') {
          this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
        } else if (make && make.toLocaleLowerCase() === 'lexus') {
          this.modelYearSeries = this.toyotaLexusModelDetails.lexus;
        }
        this.step.controls.year.reset('', { emitEvent: false });
        this.step.controls.model.reset('', { emitEvent: false });
        const namesToDeleteSet = new Set(util.scionModelList);
        if(this.modelYearSeries){
          const modelArray = Object.keys(this.modelYearSeries).sort()
          const toyotaOrLexusModels = modelArray.filter(r=>{
            return !namesToDeleteSet.has(r);
          })
          if(make && make.toLocaleLowerCase() === 'scion'){
            const modelYearSeries = this.toyotaLexusModelDetails.toyota;
            const scionModels = Object.keys(modelYearSeries).sort().filter(r=>{
              return namesToDeleteSet.has(r);
            })
            this.models = scionModels;
          }else{
            this.models = toyotaOrLexusModels;
          }
        }else{
          this.models = [];
        }
       // this.years = util.getModelYears(this.modelYearSeries);
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels = [...this.modelYearSeries[chosenYear]].reverse() as string[];
        }
      }
      // this.gsap.animateYearSeriesInput();
      this.resetGroupTwoFields(make);
    });

    this.subs.yearSub = this.step.get('model').valueChanges.subscribe((data: string) => {
      if(data){
        this.analyticsService.serviceSchedulerVehicleData = {
          ...this.analyticsService.serviceSchedulerVehicleData,
          model : data
        };
      }
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      if (!this.isMakeOther) {
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels =  [...this.modelYearSeries[chosenYear]].reverse() as string[];
        }
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.modelSub = this.step.get('year').valueChanges.subscribe((data: string) => {
      if(data){
        this.analyticsService.serviceSchedulerVehicleData = {
          ...this.analyticsService.serviceSchedulerVehicleData,
          year : data
        };
      }
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.vinSub = this.step.get('vin').valueChanges.subscribe((data: string) => {
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      if(data){
        this.analyticsService.serviceSchedulerVehicleData = {
          ...this.analyticsService.serviceSchedulerVehicleData,
          vin : data
        }
      }
      
      if(data.length === 17)
     {
       this.vehicleService.getVehicleColorForLexus({vin : data},false).subscribe(data=>{
        if(data  && data.status?.code === 201 && data.data && data.data.length> 0){
          this.factoryPrintService.setUrl(data.data[0].imageUrl);
          this.bannerService.show();
          this.showVehicle(data.data[0].imageUrl);
        }
      })
    }
      this.resetGroupOneFields(data);
    });

    this.subs.vehicleImageSub = this.step.valueChanges.subscribe(value => {
      /* When the vehicle info is partial, no need to set image */
      if (!((value.make && value.year && value.model) || (value.vin && value.vin.length === 17))) {
        if(!this.isEditMode){
          this.hideVehicle();
        }
        return;
      }

      const chosenVehicle = `${value.make}#${value.year}#${value.model}`;
      if (this.lastVehicleSelected === chosenVehicle) {
        return;
      }

      this.lastVehicleSelected = chosenVehicle;
      if (value.isOwnVehicle && this.isOwnVehicleHasNoAppt) {
        this.bannerService.show();
        this.bannerService.update(value.imageUrl);
      } else {
        /* Other makes don't have images in EFC */
        if (this.isOtherMake(value.make)) {
          this.step.controls.imageUrl.setValue(util.GLOBAL_CONSTANT.placeholderCarImage);
          return;
        }
        const brand = value.make.toUpperCase().substr(0, 3);
        let model;
        if(this.previousMakeModelYear !== `${value.make}${value.model}${value.year}`){
          this.previousMakeModelYear = `${value.make}${value.model}${value.year}`;
          this.hideVehicle();
        }
        if (value.make.toLowerCase() === 'toyota') {
          model = value?.model?.toLowerCase()?.replace(' ', '');
          if(!value.isOwnVehicle){
          this.vehicleService.getVehicleColors(brand, value.year, model, '').subscribe((data: VehicleImage) => {
            const exteriorColors = data.colors?.exterior;
            const exteriorColor = exteriorColors[0];
            const relativeURL = brand?.toLowerCase() === 'lex'?
            exteriorColor?.applicable[0]?.override?.images?.on_swatch_image :
            exteriorColor?.applicable[0]?.override?.images?.car_jelly_image ;

            /*Updating the image url in the form*/
            this.step.controls.imageUrl.setValue(relativeURL);
            this.bannerService.show();
            this.showVehicle(relativeURL);

          },err=>{
            this.step.controls.imageUrl.setValue(undefined);
            this.factoryPrintService.setUrl(undefined);
            this.bannerService.show();
            this.hideVehicle();
          });
        }else{
          this.showVehicle(value.imageUrl);
        }
        } else if (value.make.toLowerCase() === 'lexus') {
          model = value?.model?.toLowerCase()?.replace(/[0-9]/g, '')?.replace(' ', '');
          if(!value.isOwnVehicle){
            this.vehicleService.getVehicleColorForLexus(value, false).subscribe(data=>{
            if(data  && data.status?.code === 201 && data.data?.model && data.data?.model.length> 0){
              this.bannerService.show();
              this.showVehicle(data.data.model[0]);
            }else{
              this.hideVehicle();
            }
            },err=>{
              this.step.controls.imageUrl.setValue(undefined);
              this.bannerService.show();
              this.hideVehicle();
            })
          }else{
            this.showVehicle(value.imageUrl);
          }
        }else{
          this.hideVehicle();
        }
      }
    });
  }

  inputBlurEvent(fieldName : string){
    const inputFieldTrackingData = {
      content_section : 'vehicle tab',
      module_link : 'vehicle details',
      link_text : fieldName,
    };
    if(fieldName !== "vin"){
      inputFieldTrackingData['link_input_field'] = this.step.get(fieldName).value;
    }
    const vehicleData = this.analyticsService.serviceSchedulerVehicleData;
    vehicleData[fieldName] = this.step.get(fieldName).value;
    this.analyticsService.serviceSchedulerVehicleData = vehicleData;
    this.analyticsService.trackLink(inputFieldTrackingData,true);
  }

  resetGroupOneFields(value: string) {
    if (!!value) {
      this.hasExistingAppt = false;
      if (value.length >= 17 ) {
        if(this.logedIn){
        const queryText = value;
        this.dashboardState.dispatch(new dashboardActions.LoadAppointmentsForVin({ queryText }));
        this.dashboardState.select(selectAppointmentsForVin).subscribe(response => {
          if (response && response.length > 0 && response[0]) {
            const dateArray = [];
            let count=0;
            const today = DateTime.now().toFormat('yyyy-MM-dd');
            const currentdate = DateTime.fromFormat(today,'yyyy-MM-dd');
            const length = response.length;
            for(let i=0;i<length;i++){
              const minDate=DateTime.fromFormat(response[i].appointmentStartDate,'yyyy-MM-dd');
              if(minDate>=currentdate && count <=9)
             {
              const displayDate = minDate.toFormat('MMM dd');
              dateArray.push(displayDate);
              this.hasExistingAppt = true;
              count++;
              }
            }
            const lastElement = count>1 && dateArray.length>0 ? 'and '+ dateArray.splice(count-1,1) : '';
            this.existingApptText = `${this.translate.instant('welcome.vehicleHasUpcomingAppointmentsScheduledOn')} ${dateArray.join()} ${lastElement}`;
          }
          else
           {
            this.hasExistingAppt = false;
             this.step.controls.isInputVinBased.setValue(true);
            }
            //if user is logedIn then telematics call will be performed
            if(this.logedIn){
              this.fetchTelematics(value);
            }
        });
      }
      else
           {
            this.hasExistingAppt = false;
             this.step.controls.isInputVinBased.setValue(true);
            }
    }
      this.step.controls.make.reset('', { emitEvent: false });
      this.step.controls.year.reset('', { emitEvent: false });
      this.step.controls.model.reset('', { emitEvent: false });
    }
  }
  fetchTelematics(vin:string){
    if(!this.telematicsLoading){
      if(this.isEditMode){
        this.serviceAppointmentState.dispatch(new UpdateServiceForm({
          path: 'vehicleForm',
          value: { ...this.vehicles[0], mileage: 'fetching data from telematics...' }
        }))
        this.setTelematics(vin,this.vehicles[0].mileage);
      }else{
          this.setFormControlValue("mileage",this.translate.instant('welcome.fetchingDataFromTelematics'));
          this.setTelematics(vin,null);
      }
    }
  }

  private setTelematics(vin,previousMilage) {
    this.telematicsLoading = true;
    this.userVehicleService.getVehicleHealthReport(this.userCd, this.vehicles[0]?.vehicleId|| vin, vin)
      .subscribe(data => {
        if (data.status.code === '200') {
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          const telematicsData = Telematics.getTelematics(vehicleStatus, vehicleAlerts);
          const mileage = telematicsData?.mileage ? telematicsData?.mileage : previousMilage;
          if(this.isEditMode){
            this.serviceAppointmentState.dispatch(new UpdateServiceForm({
              path: 'vehicleForm',
              value: { ...this.vehicles[0], mileage: mileage }
            }));
          }else{
            this.step.controls['mileage'].setValue(mileage);
          }
        }
        else{
        if(this.isEditMode){
          this.serviceAppointmentState.dispatch(new UpdateServiceForm({
            path: 'vehicleForm',
            value: { ...this.vehicles[0], mileage: previousMilage }
          }));
        }
        else{
        this.step.controls['mileage'].setValue(previousMilage);
        }}
        this.step.controls['mileage'].enable();
        this.telematicsLoading = false;
      }
      ,err=>{
          if(this.isEditMode){
            this.serviceAppointmentState.dispatch(new UpdateServiceForm({
              path: 'vehicleForm',
              value: { ...this.vehicles[0], mileage: previousMilage  }
            }));
          }else{
            this.step.controls['mileage'].setValue(previousMilage);
          }
          this.step.controls['mileage'].enable();
          this.telematicsLoading = false;
      });
    
  }

  resetGroupTwoFields(value: string) {
    if (!!value) {
      this.step.controls.isInputVinBased.setValue(false);
      this.hasExistingAppt = false;
      this.step.controls.vin.reset('', { emitEvent: false });
    }
  }

  /**
   * Triggers the value change event of the given formGroup, by re-setting one of the form control value
   *
   */
  triggerValueChangeEvent(step: FormGroup) {
    this.setFormControlValue('isOwnVehicle', step?.controls.isOwnVehicle.value);
  }

  /** Sets the form control value to the given form control name in the formGroup
   *
   */
  setFormControlValue(formControlName: string, formControlValue: any) {
    this.step.controls[formControlName].markAsDirty();
    this.step.controls[formControlName].setValue(formControlValue);
  }

  /**
   * Validates the VIN input having error for 17 digit validation
   *
   * @returns true if control is valid
   */
  isNot17Digit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[.]{17}$'
    );
  }

  /**
   * Validates the VIN input having error for alpha numeric validation
   */
  isNotCharAndDigit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[\\w\\d]*$'
    );
  }

  onKeyPress($event) {
  }

  ready() {
    this.prepareForm();
    this.setUpStore();
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

  ngAfterViewInit(): void {
  }

  vehicleSelected(vehicle: IVehicle, vehicleCardComponent: VehicleCardComponent) {
    this.step.patchValue({ ...vehicle });
    vehicleCardComponent.triggerOnMileageChange();
  }

  private resetAllInputs() {
    ['mileage', 'description', 'imageUrl', 'vehicleId'].forEach(fc => this.step.controls[fc].reset('', { emitEvent: false }));
    this.resetGroupOneFields('value');
    this.resetGroupTwoFields('value');
  }

  /**
   * Prepares the form group for vehicle selection with necessary form controls with default value
   */
  private prepareForm() {
    this.step = this.formBuilder.group({
      isOwnVehicle: [true],
      isInputVinBased: [false],
      description: [''],
      imageUrl: [''],
      vehicleId: [''],
      vin: [''],
      make: [''],
      otherMake: [''],
      year: [''],
      model: [''],
      mileage: [null],
    }
    );
    this.step.setValidators(fromValidator.chooseVehicleValidator());
    this.dealerState.select(serviceSettings).subscribe(settings => {
      this.isVinMandate = settings?.portal?.mandateVin;
      if (this.isVinMandate) {
        this.step.controls.vin.setValidators(this.vinValidator());
      }
    });
    this.setFormSubscription();
  }

  private bindThis() {
    this.isNotCharAndDigit = this.isNotCharAndDigit.bind(this);
    this.isNot17Digit = this.isNot17Digit.bind(this);
  }

  private setOwnVehicleTrue() {
    this.isOwnVehicle = true;
    this.title = 'dashboardAppointments.chooseFromYourVehicles';
    this.switcherText = 'testDrive.chooseAnotherVehicle';
    this.step.controls.isOwnVehicle.setValue(this.isOwnVehicle);
  }

  login() {
    this.authService.loginB2c();
  }

  private setOwnVehicleFalse() {
    this.isOwnVehicle = false;
    this.title = 'welcome.enterYourVehicleDetails';
    this.switcherText = 'welcome.chooseFromMyVehicle';
    this.step.controls.isOwnVehicle.setValue(this.isOwnVehicle);
    this.showMultipleVehicle = true
  }

  private isOtherMake(make: string) {
    return make.toLowerCase() === 'other';
  }

  private setInitialVehicleSelection() {
    this.isRedirected$.pipe(take(1)).subscribe(isRedirected => {
      if (this.vehicleId && this.vehicles?.length) {
        const vehicle = this.vehicles.find(v => `${v.vehicleId}` === `${this.vehicleId}`);
        if (this.step.controls.vin.value !== vehicle.vin && !isRedirected) {
          this.setTelematics(vehicle.vin,vehicle.mileage);
          this.serviceAppointmentState.dispatch(new UpdateServiceForm({ path: 'vehicleForm', value: { ...vehicle, isOwnVehicle: true } }));
        }
      }
    });
  }

  private setUpStore() {
    this.serviceAppointmentState.dispatch(new LoadModelYearAndModels());

    /* Observables */
    this.isUserVehiclesLoading$ = this.dashboardState.select(fromDashboard.selectUserVehiclesLoading);
    this.isEditMode$ = this.serviceAppointmentState.select(fromSelectors.selectIsEditMode);
    this.isRedirected$ = this.serviceAppointmentState.select(fromSelectors.selectIsRedirected);
    this.isOwnVehicle$ = this.serviceAppointmentState.select(fromSelectors.selectVehicleFormIsOwnVehicle);
    this.modelYearAndModelsData$ = this.serviceAppointmentState.select(fromSelectors.selectModelYearAndModelsData);
    this.modelYearAndModelsLoading$ = this.serviceAppointmentState.select(fromSelectors.selectModelYearAndModelsLoading);

    /* Explicit subscriptions to the store slices */
    this.subs.isOwnVehicleSub = this.isOwnVehicle$.subscribe(o => {
      o ? this.setOwnVehicleTrue() : this.setOwnVehicleFalse();
    });

    /* For the guest user the default value for 'isOwnVehicle' is false */
    this.isRedirected$.pipe(take(1)).subscribe(isRedirected => {
      if (!this.signedIn && !isRedirected) {
        const vehicle = { ...this.step.value, isOwnVehicle: false };
        this.serviceAppointmentState.dispatch(new UpdateServiceForm({ path: 'vehicleForm', value: vehicle }));
      }
      this.serviceAppointmentState.dispatch(new UpdateIsRedirected({ isRedirect: false }));
    });


    this.subs.displayVehiclesSub =
      this.isEditMode$.pipe(
        switchMap((isEditMode: boolean) =>
          iif(() => {
            this.isEditMode = isEditMode;
            return isEditMode;
          },
            this.serviceAppointmentState.select(fromSelectors.selectAppointmentVehicleAsArray),
            this.dashboardState.select(fromDashboard.selectUserVehicles)
          ))).subscribe(vehicles => {
            this.vehicles = vehicles;
            if (this.isEditMode) {
              this.prepareFormForEdit(vehicles);
            } else {
              this.setInitialVehicleSelection();
            }
          });

    this.subs.modelYearAndModelsDataSub = this.isEditMode$.pipe(
      mergeMap((isEditMode: boolean) =>
        iif(() => !isEditMode, this.modelYearAndModelsData$)
      )
    ).subscribe(data => {
      this.toyotaLexusModelDetails = data;
      this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
      const namesToDeleteSet = new Set(util.scionModelList);
      if(this.modelYearSeries){
        const modelArray = Object.keys(this.modelYearSeries).sort()
        const toyotaOrLexusModels = modelArray.filter(r=>{
          return !namesToDeleteSet.has(r);
        })
        const scionModels = modelArray.filter(r=>{
          return namesToDeleteSet.has(r);
        })
        if(this.step.value.make && this.step.value.make.toLocaleLowerCase() === 'scion'){
          this.models = scionModels;
        }else{
          this.models = toyotaOrLexusModels;
        }

      }else{
        this.models = [];
      }
    });
  }

  private prepareFormForEdit(vehicles) {
    this.bannerService.update(vehicles[0].imageUrl);
    this.bannerService.showCarImage();
    this.unsubCreateModeSubscription();

    /* Disable the formControls (fc) which are not editable */
    ['make', 'otherMake', 'year', 'model', 'vin']
      .forEach(fc => this.step.controls[fc]
        .disable({ emitEvent: false }));
        if(vehicles[0].vin){
          this.fetchTelematics(vehicles[0].vin)
        }
      this.models = vehicles.map(v => v.model);
    this.yearmodels = vehicles.map(v => v.year);
    this.isMakeOther=(vehicles[0].make?.toString().toLowerCase()==='other' && 
    vehicles[0].isOwnVehicle===false) ? true : false;
  }

  private unsubCreateModeSubscription() {
    ['vinSub', 'modelSub', 'yearSub', 'makeSub']
      .forEach(sub => this.subs[sub]?.unsubscribe());
  }
  toShowFooterDetails(event) {
    this.isOwnVehicleHasNoAppt = event;
    this.toShowFooter.emit(event);
  }
  vinValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (!this.step.controls.vin.value) {
        return { required: this.translate.instant('welcome.vinIsRequired') };
      } else {
        return null;
      }
    };
  }
  showVehicle(relativeURL){
    this.bannerService.showCarImage();
    this.bannerService.update(relativeURL);
    this.factoryPrintService.setUrl(relativeURL);
  }
  hideVehicle(){
    this.bannerService.hideCarImage();
    this.bannerService.update(undefined);
    this.factoryPrintService.base64CarImg = undefined;
  }
}
