import { Injectable } from '@angular/core';
import { DateTime } from 'luxon'
import { IDateAvailability } from '@signal/asp-data-commons';
import { IAvail, SalesAppointmentState } from '../../store/sales-appointment/sales-appointment.reducer';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../../store/sales-appointment/sales-appointment.selectors';
import { take } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import * as fromActions from '../../store/sales-appointment/sales-appointment.actions';

@Injectable({
  providedIn: 'root'
})
export class SalesDataShareService {
    constructor(   private readonly salesAppointmentState: Store<SalesAppointmentState>,
        private readonly analyticsService: AnalyticsService
        ){}
    public getEndOfMonth(date: string) {
        const parseDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
        const endOfMonth = DateTime.fromFormat(`${parseDate.toFormat('yyyy-MM')}-01`, 'yyyy-MM-dd').plus({ month: 1, day: -1 });
        return endOfMonth;
      }
    public isSuggestedTimeslot(suggestedTimes: IDateAvailability[], availability: IAvail) {
        return suggestedTimes.some(t =>
          t.date === availability.appointmentStartDate &&
          t.timeSlots.some(r => r.timeSlotId.indexOf(availability.timeSlotId)));
    }
    trackSubmit(analyticsSessionId) {
        forkJoin([
          this.salesAppointmentState.select(fromSelectors.appointmentDetails).pipe(take(1)),
          this.salesAppointmentState.select(fromSelectors.availability).pipe(take(1))
        ]).pipe(take(1)).subscribe(([appointment, availabilityForm]) => {
          const testDriveTrackingData: any = {
            testscheduler_session_id: analyticsSessionId,
            content_section: 'delivery Appointment',
            link_text: 'Appointment Submit',
            testscheduler_advisor_preference: appointment.availability.advisorId !== '-1',
            testscheduler_language_preference: appointment.availability.languageId !== '-1',
            testscheduler_is_suggested_timeslot: this.isSuggestedTimeslot(availabilityForm.data.suggestedTimes, appointment.availability)
          };
          this.analyticsService.trackLink(testDriveTrackingData);
        });
      }
      onMonthChange($event: DateTime) {
        const startdate = $event.toFormat('yyyy-MM-dd');
        const newenddate: string = DateTime.local().plus({ days: 40 }).toFormat('yyyy-MM-dd');
        const monthEndDate = this.getEndOfMonth(startdate).toFormat('yyyy-MM-dd');
        const diffdates = (new Date(startdate) < new Date(newenddate)) && (new Date(newenddate) < new Date(monthEndDate));
  
        this.salesAppointmentState.select(fromSelectors.getAvailabilityDetails)
          .pipe(take(1))
          .subscribe(details => {
            const date = $event.toFormat('yyyy-MM-dd');
            if (diffdates) {
              this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
                { date, advisorId: details.advisorId, transport: details.transport, enddt: newenddate }));
            }
            else if ((new Date(startdate) > new Date(newenddate))) {
              return;
            }
            else {
              this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
                { date, advisorId: details.advisorId, transport: details.transport }));
            }
          });
  
      }
      setDataFormOnEdit(data){
        const apptData = {
            vehicle: data.vehicle,
            tradeIn: {
              tradeInCheckbox: data.tradeInVehicle ? true : false,
              vehicle: {
                year: data.tradeInVehicle ? data.tradeInVehicle.year : '',
                make: data.tradeInVehicle ? data.tradeInVehicle.make : '',
                model: data.tradeInVehicle ? data.tradeInVehicle.model : '',
                vin: data.tradeInVehicle ? data.tradeInVehicle.vin : '',
                mileage: data.tradeInVehicle ? data.tradeInVehicle.mileage : 0,
                trim: data.tradeInVehicle ? data.tradeInVehicle.trim : ''
              },
              title: data.title || '',
              trepresentativeInfo: data.representative || '',
              comments: data.appointmentComments || ''
            },
              transportationTypeCode: data.transportationTypeCode,
              availability: {
                timeSlotId: data.timeSlotId,
                advisorId: data.advisorId,
                languageId: data.languageId,
                appointmentStartDate: data.appointmentStartDate
              },
            customer: data.customer
          };
          return apptData;
      }
}
