import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent<D> implements OnInit, OnDestroy {
  private readonly destroyed = new Subject<void>();
  @Input() minDate1; // = '2020-07-20';
  @Input() maxDate1; // = '2020-09-24';
  isPreviousMonthDisabled: boolean = false;
  isNextMonthDisabled: boolean = false;
  today = new Date();
  endDate: string = DateTime.local().plus({ days: 40 }).toFormat('yyyy-MM-dd');
  enableNext: boolean;

  constructor(
    private readonly calendar: MatCalendar<D>, private readonly dateAdapter: DateAdapter<D>,
    private readonly translate: TranslateService,
    @Inject(MAT_DATE_FORMATS) private readonly dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnInit(): void {
    this.enableDisablePrevNextButtons();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    if (this.dateAdapter.isValid(this.calendar.activeDate)) {
      const month = this.dateAdapter.format(this.calendar.activeDate, 'MMMM');
      const year = this.dateAdapter.format(this.calendar.activeDate, 'yyyy');
      return `${this.translate.instant(`common.${month}`)} ${year}`;
    }
    return;
  }

  previousClicked(mode: 'month' | 'year') {
    if (mode === 'month' && (this.today.getMonth()) === this.dateAdapter.getMonth(this.calendar.activeDate)
      && (this.today.getFullYear()) === this.dateAdapter.getYear(this.calendar.activeDate)) {
      this.enableDisablePrevNextButtons();
    }
    else {
      this.calendar.activeDate = mode === 'month' ?
        this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
        this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
      this.enableDisablePrevNextButtons();
      this.calendar.monthSelected.emit(this.calendar.activeDate);
    }
  }
  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate = mode === 'month' ?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    this.enableDisablePrevNextButtons();
    this.calendar.monthSelected.emit(this.calendar.activeDate);
  }

  private enableDisablePrevNextButtons() {
    this.isPreviousMonthDisabled = ((this.today.getMonth()) === this.dateAdapter.getMonth(this.calendar.activeDate)) && (
      (this.today.getFullYear()) === this.dateAdapter.getYear(this.calendar.activeDate)
    );
    this.isNextMonthDisabled = ((new Date(this.endDate).getMonth()) === this.dateAdapter.getMonth(this.calendar.activeDate)) && (
      (new Date(this.endDate).getFullYear()) === this.dateAdapter.getYear(this.calendar.activeDate)
    );
  }
}
