import { ISalesAppointmentForm, SalesAppointmentState, SeriesDetailsForYear, IAvail } from './sales-appointment.reducer';
import { Model, SeriesData, Trim, VehicleDetails } from '../../shared/models/sales-appointments/series-data';
import { VehicleColor } from '../../shared/models/sales-appointments/vehicle-color';
import _ from 'lodash';
import {  AppointmentType, IAsyncData, IAvailabilityOptions, IChecklist, IChecklistRequest, IDealerInfo,
   IDeliveryAppointment, ISalesAppointment, IVehicle, TransportationType } from '@signal/asp-data-commons';
import produce from 'immer';
import { convertMinsToHourMins, environment, titleCase } from './../../../modules/shared/services/util.service';
import {DateTime} from 'luxon';

export function createSalesAppointment(state: SalesAppointmentState, payload?:any, allTestDriveVehicles?:any): ISalesAppointment {
  const appointment = state.appointment;
  const vehicle = { ...appointment.vehicle };
  // vehicle.make = _.startCase(_.toLower(environment.brand));
  vehicle.imageUrl = vehicle.imageUrl ? vehicle.imageUrl :  state.exteriorColors.data.find(x => x.id === appointment.vehicle?.exteriorColor)?.carImage;
  const tradeIn = appointment.tradeIn;
  const availability = { ...appointment.availability };
  const customer = appointment.customer;
  const transportationTypeCode = appointment.transportationTypeCode;
  const apptEmail = customer.emailAddress;
  const address = appointment.address;

  let appt: ISalesAppointment = {
    appointmentType: payload.appointmentType,
    emailAddress: apptEmail,
    languageId: availability.languageId,
    advisorId: availability.advisorId,
    timeSlotId: availability.timeSlotId,
    appointmentComments:appointment.appointmentComments,
    transportationTypeCode,
    appointmentStartDate: availability.appointmentStartDate,
    vehicle,
    customer: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      emailAddress: customer.emailAddress,
      phoneNumber: customer.phoneNumber,
    }
  };

  if(payload.appointmentType === AppointmentType.TEST_DRIVE){
    /* * to select correct vin in case of new create appointment */
    // if(!state?.editDetails?.appointmentId){
      const tempOtherVins = state.availability.data.availableDates
    .find(data => data.date === availability.appointmentStartDate).timeSlots
    .find(d => d.timeSlotId === availability.timeSlotId).availableVins;

    /* *only update a vehicle details when allTestDriveVehicles has a value gt 0 */
    if(allTestDriveVehicles?.length>0) {
        vehicle.otherVins = tempOtherVins.map(v => {
          const tempData = allTestDriveVehicles.find(dt => dt.vin === v);
          if (tempData.vin) {
            return {
              "vin": tempData.vin,
              "trim": tempData.trim,
              "imageUrl": getDataFromObj(tempData.imageUrl),
              "exteriorColor": getDataFromObj(tempData.exteriorColorCode)
            }
          }
        });
    
    
        if(vehicle.otherVins?.length>0) {
          vehicle.vin = vehicle.otherVins[0].vin;
          vehicle.trim =  vehicle.otherVins[0].trim;
          vehicle.imageUrl = vehicle.otherVins[0].imageUrl;
          vehicle.exteriorColor = vehicle.otherVins[0].exteriorColor;
        }
        vehicle.description=""
    }
    // }

  const otherVehicles = [...appointment.otherVehicles];

  if (otherVehicles.length && otherVehicles[0].year && otherVehicles[0].trim && otherVehicles[0].model) {
    appt.otherVehicles = [];
    otherVehicles.forEach((otherVehicle) =>
      appt.otherVehicles.push({
        ...otherVehicle,
        make: _.startCase(_.toLower(environment.brand))
      }));
  }

  if (checkCommentsCondition(tradeIn)) {
    let apptComments = 'Appointment-Comments';
    apptComments += tradeIn.dealerComments ? ` ::: Comments-From-Dealer - ${tradeIn.dealerComments}` : '';
    apptComments += tradeIn.internalComments ? ` ::: Dealer-Internal-Comments - ${tradeIn.internalComments}` : '';
    apptComments += tradeIn.additionalComments ? ` ::: Customer-Additional-Comments - ${tradeIn.additionalComments}` : '';  
    apptComments += tradeIn.tradeInComments ? ` ::: Trade-in - ${tradeIn.tradeInComments}` : '';  
    appt.appointmentComments = apptComments;
  }

  }
  else if(payload.appointmentType === AppointmentType.DELIVERY) {
    appt = setDeliveryAppointment(payload, appointment, availability, customer, transportationTypeCode, vehicle, tradeIn, address);
  }

  if (tradeIn && tradeIn.tradeInCheckbox) {
    let tradeInVehicle = [];
      tradeIn.vehicle.forEach((vehicle, index)=> {
        tradeInVehicle.push({...vehicle, mileage: vehicle.mileage  ? +vehicle.mileage : 0});
      })
    appt.tradeInVehicle = tradeIn.vehicle;
  }

  appt.dropOffAddress = address;
  // if (transportationTypeCode === 'REMOTE') {
  //   appt.customer.address = {
  //     ...customer.address,
  //     country: 'USA'
  //   };
  // }
  return JSON.parse(JSON.stringify(appt).replace(/null/g, '""'));
}

function setDeliveryAppointment(payload, appointment, availability, customer, transportationTypeCode, vehicle, tradein, deliveryAddress?) {
  const apptEmail = customer.emailAddress;
  const appt: IDeliveryAppointment = {
    stockNumber: vehicle.stockNo ? vehicle.stockNo : 'NA',
    deliveryAddress,
    appointmentType: payload.appointmentType,
    emailAddress: apptEmail,
    languageId: availability.languageId,
    advisorId: availability.advisorId,
    timeSlotId: availability.timeSlotId,
    transportationTypeCode,
    appointmentStartDate: availability.appointmentStartDate,
    vehicle,
    appointmentCreatedRoles: ["ASP_ROLES"],
    customer: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      emailAddress: customer.emailAddress,
      phoneNumber: customer.phoneNumber,
    }
  };
  if (tradein.title) {
    appt.title = tradein.title;
  }
  if (tradein.trepresentativeInfo) {
    appt.representative = tradein.trepresentativeInfo;
  }

  if (checkCommentsCondition(tradein)) {
    let apptComments = 'Appointment-Comments';
    apptComments += tradein.dealerComments ? ` ::: Comments-From-Dealer - ${tradein.dealerComments}` : '';
    apptComments += tradein.internalComments ? ` ::: Dealer-Internal-Comments - ${tradein.internalComments}` : '';
    apptComments += tradein.additionalComments ? ` ::: Customer-Additional-Comments - ${tradein.additionalComments}` : '';  
    apptComments += tradein.tradeInComments ? ` ::: Trade-in - ${tradein.tradeInComments}` : '';  
    appt.appointmentComments = apptComments;
  }

  if(appointment.checklistRequest && appointment.checklistRequest.length > 0){
    appt.checklistRequest = appointment.checklistRequest;
  }
  return appt
}

function checkCommentsCondition(tradein) {
  return (tradein.dealerComments || tradein.internalComments || tradein.tradeInComments || tradein.additionalComments) ? true : false;
}

function getDataFromObj(data) {
  return data || "";
}

export function editDataPopulatedInState(a: ISalesAppointment): ISalesAppointmentForm {
  const state: ISalesAppointmentForm = {
    vehicle: { vin:null,...a.vehicle },
    otherVehicles: [],
    appointmentComments: a.appointmentComments || '',
    tradeIn: {
      tradeInCheckbox: !!a.tradeInVehicle,
      vehicle: a.tradeInVehicle && a.tradeInVehicle.length > 0 ? getTradeInVehicle(a.tradeInVehicle) : [ {
        year: null,
        make: null,
        model: null,
        vin: null,
        mileage: null,
        trim: null,
        isOwnVehicle: false
      }],
      title: a.title || '',
      trepresentativeInfo: a.representative || '',
      tradeInComments : a.appointmentComments ? getComment(a.appointmentComments, 'Trade-in') : '',
      dealerComments: a.appointmentComments ? getComment(a.appointmentComments, 'Comments-From-Dealer') : '',
      internalComments: a.appointmentComments? getComment(a.appointmentComments, 'Dealer-Internal-Comments'): '',
      additionalComments: a.appointmentComments ? getComment(a.appointmentComments, 'Customer-Additional-Comments') : ''
    },
    transportationTypeCode: a.transportationTypeCode,
    remoteZipCode: (a.dropOffAddress && a.transportationTypeCode === 'REMOTE') ? a.dropOffAddress.zip : '',
    availability: {
      advisorId: a.advisorId,
      languageId: a.languageId,
      timeSlotId: a.timeSlotId ? a.timeSlotId : '',
      appointmentStartDate: a.appointmentStartDate ? a.appointmentStartDate : '',
    },
    customer: {
      ...a.customer
    },
    address: {
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      country: 'USA',
      ...a.dropOffAddress
    }
  };

  if (!a.otherVehicles) {
    state.otherVehicles.push({
      year: null,
      model: '',
      trim: ''
    });
  } else {
    state.otherVehicles = produce(a.otherVehicles, draftVehicle => {
      draftVehicle.forEach(vehicle => {
        delete vehicle.make;
      });
    });
  }

  if (a.dropOffAddress) {
    state.address = {...state.address,...a.dropOffAddress};
  }
  if(a.appointmentType === AppointmentType.DELIVERY ) {
    if(a.checklistRequest && a.checklistRequest.length) {
      const checklistRequest = [];
      a.checklistRequest.forEach(item=> {
        const data = {comments: '', ...item};
        checklistRequest.push(data);
      })
      state.checklistRequest = checklistRequest;
    }
  }

  //delete state.vehicle.make;

  if(!state.vehicle.imageUrl) {
    state.vehicle.imageUrl = "";
  }

  delete state.vehicle.description;
  return state;
}

export function getComment(data, field) {
  let returnComment = '';
  data.split(':::').forEach(comment=> {
    if(comment.includes(field)) {
      returnComment = comment.replace(`${field} - `, '').trim();
    }
  })
  return returnComment;
}

export function getTradeInVehicle(vehicles : IVehicle[]) : IVehicle[]{
  const tradeInVehicles = [];
  vehicles.forEach(tradeInVehicle=> {
    tradeInVehicles.push({
      year: tradeInVehicle &&  tradeInVehicle.year ? tradeInVehicle.year : '',
      make: tradeInVehicle && tradeInVehicle.make ? tradeInVehicle.make : '',
      model: tradeInVehicle && tradeInVehicle.model ? tradeInVehicle.model : '',
      vin: tradeInVehicle && tradeInVehicle.vin ? tradeInVehicle.vin : '',
      mileage: tradeInVehicle && tradeInVehicle.mileage ? +tradeInVehicle.mileage : 0,
      trim: tradeInVehicle && tradeInVehicle.trim ? tradeInVehicle.trim : '',
      isOwnVehicle : tradeInVehicle && tradeInVehicle.isOwnVehicle ? tradeInVehicle.isOwnVehicle : false
    })
  })
  return tradeInVehicles;
}

export function updateSalesAppointment(state: SalesAppointmentState,payload?:any, allTestDriveVehicles?:any): ISalesAppointment {
  const updateAppointment = createSalesAppointment(state,payload, allTestDriveVehicles);
  updateAppointment.appointmentId = state.editDetails.appointmentId;
  updateAppointment.dealerCode = state.editDetails.dealerCode;
  return updateAppointment;
}

export function getSeriesAndTrim(value: any): SeriesDetailsForYear[] {
  const testDriveYears: number[] = getYearList();
  const seriesYearArray: SeriesDetailsForYear[] = [];
    value.series_data.forEach(seriesYear => {
      if (testDriveYears.findIndex(x => x === +seriesYear.year) > -1) {
        const seriesDataArray: SeriesData[] = [];
        seriesYear.series_list.forEach(element => {
          const seriesData: SeriesData = {
            modelCode: element.code,
            model: {
              modelCode: element.code,
              modelTitle: element.title
            },
            mileage: element.mileage?.combined_mpg,
            price: element.price?.starting_msrp,
            trims: []
          };
          element.trims.forEach(item => {
            const trim: Trim = {
              trimCode: item.code,
              trimTitle: item.title
            };
            seriesData.trims.push(trim);
          });
          seriesDataArray.push(seriesData);
        });
        seriesYearArray.push({
          year: seriesYear.year,
          seriesDetails: seriesDataArray
        });
      }
    });

  return seriesYearArray;
}

export const getDetailKeys = (vehicles: any) => {
  if (vehicles) {
    return Object.keys(vehicles);
  }
  return [];
}


function objectTransform(dataYearArr, tempKey1, tempKey2, groupFactor) {
  let yearDataArr = []
  let testKeys = getDetailKeys(dataYearArr);
  testKeys.forEach(key => {
    let dt = _.groupBy(dataYearArr[key], groupFactor);
    let tempOBJ = {
      [tempKey1]: key,
      [tempKey2]: dt
    };
    yearDataArr.push(tempOBJ);
  })
  return yearDataArr;
}

export function getInventoryVehicles(data){
  return data.data.filter(item => item.status)
}

function objectTransformOnTrimMarketingTitle(dataYearArr, tempKey1, tempKey2, groupFactor) {
  let dataArr = []
  let keys = getDetailKeys(dataYearArr);
  keys.forEach(key => {
    let groupedRes = _.groupBy(dataYearArr[key], groupFactor);
    let finalRes = convertEntriesOnMarketingTitle(groupedRes);
    let tempOBJ = {
      [tempKey1]: key,
      [tempKey2]: finalRes
    };
    dataArr.push(tempOBJ);
  })
  return dataArr;
}

function convertEntriesOnMarketingTitle(data) {
  let tObj = {}
  for(const [key,val] of Object.entries(data)){
    let ans = _.groupBy(val,"marketingTitle");
    let keys = getDetailKeys(ans);
    keys.forEach((key)=>{
      let keyName = ans[key][0].trim+"$%$"+key;
      tObj[keyName] = ans[key];
    })
  }
  return tObj;
}

export const transformVehicleInventory = (vehicles:  any, isAllVehicles: boolean) => {
  let filteredOnlineVehicles = Array.isArray(vehicles) ? (isAllVehicles ? vehicles.filter(d=> d.status === true): 
  vehicles.filter(d=> d.status === true && !d.expiredAt)): [];
  let dataYearArr = _.groupBy(filteredOnlineVehicles, 'year')
  let yearDataFinal = objectTransform(dataYearArr, "year", "series_List", "model")
  
  yearDataFinal.forEach((data)=>{
    data.series_List = objectTransformOnTrimMarketingTitle(data.series_List, "model", "trims", "trim")
  })

  return yearDataFinal;
}

export const getYearList = (): number[] => {
  const date = new Date();
  return [date.getFullYear(), date.getFullYear() + 1];
};

export const getSeriesList = (seriesData: IAsyncData<SeriesDetailsForYear[]>, year: number): Model[] => {
  if (seriesData.data.length === 0) {
    return [];
  }

  const seriesDetailList = seriesData.data.find(x => +x.year === year)?.seriesDetails;
  const modelList: Model[] = [];
  if (seriesDetailList) {
    seriesDetailList.forEach(item => {
      if (modelList.findIndex(x => x.modelCode === item.modelCode) === -1) {
        modelList.push(item.model);
      }
    });
  }
  return modelList;
};

export const getTrimList = (seriesData: IAsyncData<SeriesDetailsForYear[]>, year: number, model: string): Trim[] => {
  if (seriesData.data.length === 0) {
    return [];
  }

  const seriesDetailList = seriesData.data.find(x => +x.year === year)?.seriesDetails;
  let trimList: Trim[] = [];
  if (seriesDetailList) {
    seriesDetailList.forEach(item => {
      if (item.modelCode === model) {
        trimList = item.trims;
      }
    });
  }
  return trimList;
};

export const getMileage = (seriesData: IAsyncData<SeriesDetailsForYear[]>, year: number, model: string): string => {
  if (seriesData.data.length === 0) {
    return '0.0';
  }

  const seriesDetailList = seriesData.data.find(x => +x.year === year)?.seriesDetails;
  let mileage = '0.0';
  if (seriesDetailList) {
    seriesDetailList.forEach(item => {
      if (item.modelCode === model) {
        mileage = item.mileage;
      }
    });
  }
  return mileage;
};

export const getPrice = (seriesData: IAsyncData<SeriesDetailsForYear[]>, year: number, model: string): string => {
  if (seriesData.data.length === 0) {
    return '0';
  }

  const seriesDetailList = seriesData.data.find(x => +x.year === year)?.seriesDetails;
  let price = '0';
  if (seriesDetailList) {
    seriesDetailList.forEach(item => {
      if (item.modelCode === model) {
        price = item.price;
      }
    });
  }
  return price;
};

export function getExteriorColors(value: any): VehicleColor[] {
  const exteriorColors: VehicleColor[] = [];
  value.colors.exterior.forEach(element => {
    const imageUrl = element.applicable && element.applicable.length ? element.applicable[0].override?.images?.car_jelly_image : undefined ;
    exteriorColors.push({
      id: element.code,
      colorCode: '#' + element.hex_value[0],
      carImage: imageUrl
    });
  });
  
  return exteriorColors;
}

export function getVehicleDetails(yearList, modelList, trimList, exteriorColors, mileage, price): VehicleDetails {
 
  return {
    exteriorColors,
    yearList,
    modelList,
    trimList,
    mileage,
    price
  };
}

export function updateAdvisorOnLanguageChange(state: SalesAppointmentState, value: IAvail): string {
  let advisorId = value.advisorId;
  const advisors = state.availabilityOptions?.data?.advisors
    ?.filter(a => a?.languages && a?.languages.length ? a?.languages?.some(l => l.languageId === value.languageId): []);
  if (advisors && !advisors.find(x => x.id === advisorId)) {
    advisorId = '-1';
  }
  return advisorId;
}

export function updateTimeSlotOnDateChange(state: SalesAppointmentState, value: IAvail): string {
  let timeSlotId = value?.timeSlotId;

  if (state.appointment.availability.appointmentStartDate !== value.appointmentStartDate) {
    const timeSlots = state.availability.data.availableDates
      ?.find(a => a.date === value.appointmentStartDate)
      ?.timeSlots;

    const openTimeSlots = timeSlots?.filter(x => x?.isOpen);
    timeSlotId = openTimeSlots?.length ?  openTimeSlots[0].timeSlotId : timeSlotId;
  }

  return timeSlotId;
}

export function getVehicleDetailsForVinTestDrive( state, exteriorColors,vehicle,mileage,price):VehicleDetails {
  const seriesData = state.allSeriesDetails?.data;
  const modelCode = seriesData?.model?.toLowerCase().replace(' ','');
  return {
    yearList:[seriesData?.year],
    modelList:[{modelTitle:seriesData?.model,modelCode:modelCode}],
    trimList:[{trimCode:seriesData?.trim,trimTitle:seriesData?.marketingTitle}],
    exteriorColors,
    mileage, 
    price
  }
};

/* Footer Summary */
export function appointmentVehicleFormToSummaryProjector(vehicleForm: IVehicle) {
  /* Vehicle summary */
  const chosenVehicle = (vehicleForm.year && vehicleForm.model) ? `${vehicleForm.year} ${vehicleForm.model}` : '';
  return [{
    key: 'selectService.vehicle',
    value: chosenVehicle
  }];
}

export function checklistDuration(checklist) {
  let duration = 0;
  if (checklist && checklist.isChildChecklistEnabled && checklist.childChecklist && checklist.childChecklist.length > 0) {
    duration = checklist.childChecklist.map(s => s.online ? s.duration : 0).reduce((total, current) => { return total + current }, 0)
  }
  else {
    duration = checklist?.duration;
  }
  return duration;
}

export function getChecklistDuration(selectedChecklist) {
  let selectedChecklistHours = 0;
  if (selectedChecklist && selectedChecklist.length > 0) {
    selectedChecklist.map(s => s.details).forEach(t => {
      selectedChecklistHours += checklistDuration(t);
    });
  }
  return selectedChecklistHours;
}

export function appointmentChecklistRequestFormToSummaryProjector(selectedChecklist: IChecklistRequest[],time:any) {
  const selectedChecklistHours = getChecklistDuration(selectedChecklist);

  let contracting = 0;
  if(time?.deliveryAppointmentDuration > 0){
    contracting = time?.deliveryAppointmentDuration
  } 
  const checklistHoursSummary = {
    key: 'delivery.estimatedDuration',
    value: convertMinsToHourMins(selectedChecklistHours+contracting, true),
    disclaimer: 'otherTexts.theActualDurationMayVaryAtTheDealership'
  };

  return [checklistHoursSummary];
}

export function getChecklistDurationForAvailability(checklistRequest: IChecklistRequest[]) {
  const duration = getChecklistDuration(checklistRequest);
  return { min: duration };
}

export const vehicleToMakeModelYearTextProjector = (vehicleForm: IVehicle) => {
  return (vehicleForm.year && vehicleForm?.model) ? `${vehicleForm.year || ''} ${vehicleForm.model || ''}` : vehicleForm?.vin ? `VIN ${vehicleForm.vin}` : '';
};

export const buildSelectedChecklistInfo = (checklistRequest: IChecklistRequest[]): IChecklist[] => {
  if (checklistRequest && checklistRequest?.length) {
    const checklist = checklistRequest.map(t => {
      return { ...t.details } as IChecklist;
    });
    const mandatory = checklist.filter(x => x.isMandatory);
    const nonMandatory = checklist.filter(x => !x.isMandatory);
    return [...mandatory, ...nonMandatory];
  }
  else {
    return [];
  }

};

export const appointmentFormToDetailMap =
  (data: { appointmentForm: ISalesAppointmentForm, availabilityOptions: IAvailabilityOptions },
    dealerDetails: IDealerInfo) => {
    const appointmentDetails = data.appointmentForm;
    const isRemoteLocation = appointmentDetails.transportationTypeCode === TransportationType.REMOTE;

    const date = `${DateTime.fromISO(appointmentDetails.availability.appointmentStartDate).toFormat('MMMM dd')}`;
    const time = appointmentDetails.availability.timeSlotId;

    const dateTime = `${date} at ${time}`;

    const vehicle = appointmentDetails.vehicle;
    let advisorName;
    const advisorId = appointmentDetails?.availability?.advisorId;
    if (Number(advisorId) === -1) {
      advisorName = 'Any consultant';
    }
    else {
      advisorName = data.availabilityOptions?.advisors
        ?.find(advisor => advisor.id === appointmentDetails.availability.advisorId)
        ?.displayName;
    }
    const checklistData = buildSelectedChecklistInfo(appointmentDetails.checklistRequest);
    let checklist = '';

    if (checklistData && checklistData.length > 0) {
      for (let i = 0; i < checklistData.length; i++) {
        if (i < 5) {
          checklist += `${checklistData[i].displayName}\n`;
        }
        else if (i > 4 && checklistData.length > 5) {
          const remaining = checklistData.length - 5;
          checklist += `... + Additional ${remaining} checklist item${remaining > 1 ? 's' : ''}`;
          break;
        }
      }
    }
    /* need to work on customer comment */
    // const apptComment = appointmentDetails.tradeIn.customerComments ? appointmentDetails.tradeIn.customerComments : '';

    let customerAddress = '';
    if (isRemoteLocation) {
      const custAdd = appointmentDetails.address;
      customerAddress = `testDrive.weWillBringTheVehicleTo\n${custAdd.addressLine1}, ${custAdd.addressLine2 ? custAdd.addressLine2 + ', ' : ''} ${custAdd.city}, ${custAdd.state} - ${custAdd.zip}`;
    }

    const makeModelYear = `${vehicle.year || ''} ${titleCase(vehicle.make) || ''} ${vehicle.model}`;


    const dealershipAddress = `${dealerDetails.addressShort}`;

    const dealerComment = appointmentDetails.tradeIn.dealerComments;
    const additionalComments = appointmentDetails.tradeIn.additionalComments;

    const detailMap = {
      'selectService.time': dateTime,
      'selectService.vehicle': makeModelYear,
      'transportation.consultant': advisorName,
      'delivery.checkList': checklist,
      'delivery.commentsFromDealer': dealerComment,
      'delivery.additionalComments': additionalComments,
      'transportation.location': `${(isRemoteLocation ? customerAddress : dealershipAddress)}`
    };
    if (!dealerComment) {
      delete detailMap['delivery.commentsFromDealer']
    }
    if (!additionalComments) {
      delete detailMap['delivery.additionalComments']
    }
    return detailMap as { [key: string]: string };
  }

export const UpdateSalesFormData = (state: SalesAppointmentState, payload: {
  value: ISalesAppointmentForm; appointmentType?: AppointmentType;}) => {
  let appointment: ISalesAppointmentForm;
  if (payload.appointmentType === AppointmentType.DELIVERY) {
    appointment = {
      ...payload.value,
      checklistRequest: state.appointment.checklistRequest,
      availability: {
        ...payload.value.availability,
        timeSlotId: state.appointment.availability.timeSlotId,
        appointmentStartDate: state.appointment.availability.appointmentStartDate
      }
    };
  }
  else {
    appointment = { ...payload.value };
  }
  if (!(_.isEqual(state.appointment, appointment))) {
    return produce(state, draftState => {
      draftState.appointment = produce(appointment, draftValue => {
        if (draftValue.availability && state.appointment.availability) {
          draftValue.availability.advisorId = updateAdvisorOnLanguageChange(state, draftValue.availability);
          draftValue.availability.timeSlotId =
            updateTimeSlotOnDateChange(state, draftValue.availability);
        }
        if (draftValue.address && draftValue.transportationTypeCode === "REMOTE") {
          draftValue.address.zip = draftValue.remoteZipCode;
        }
      });
    });
  }
  else {
    return state;
  }
}
