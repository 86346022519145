import { AuthService } from './../shared/services/auth.service';
import { Observable } from 'rxjs';
import { LoadModelYearAndModels, UpdateServiceForm, UpdateCurrentStep, UpdateIsRedirected } from './../store/service-appointment/service-appointment.actions';
import { Store } from '@ngrx/store';
import { IToyotaLexusModelDetails, ServiceAppointmentState } from './../store/service-appointment/service-appointment.reducer';
import { VehicleModelYearList } from './../shared/models/service-appointments/series-data';
import { SubscriptionList } from './../shared/models/asp.types';
import { Make } from './../shared/models/brand.model';
import { AnalyticsService } from './../shared/services/analytics.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { Router } from '@angular/router';
import * as fromValidator from '../../modules/service-appointment/service-appointment.validator';
import * as fromSelectors from '../../modules/store/service-appointment/service-appointment.selectors';
import { VehicleService } from '../shared/services/vehicle.service';
import { FormBuilder, FormGroup, ValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import * as util from '../shared/services/util.service';
import { DashboardState } from '../store/dashboard/dashboard.reducer';
import * as dashboardActions from '../store/dashboard/dashboard.actions';
import * as dealerActions from '../store/dealer/dealer.actions';
import { selectAppointments } from '../store/dashboard/dashboard.selectors';
import { DealerState } from '../store/dealer/dealer.reducer';
import { serviceSettings } from '../store/dealer/dealer.selectors';
import { DateTime } from 'luxon';
import {TranslateService} from '@ngx-translate/core';
import { UserVehicleService } from './../shared/services/user-vehicle.service';
import { Telematics } from './../shared/models/dashboard/telematics.model';
import {ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  step: FormGroup;
  makes: Make[] = [];
  models : string[] = [];
  yearmodels: any[] = [];
  isMakeOther = false;
  brand = 'toyota';
  registerUrl = '';
  inDealerSite = false;
  viewMode = 'tab1';
  estimatedMiles: string;
  enterMiles: string;
  milesIsRequired: string;
  private subs: SubscriptionList = {};
  private modelYearSeries: VehicleModelYearList;
  private toyotaLexusModelDetails: IToyotaLexusModelDetails = {} as IToyotaLexusModelDetails;
  private modelYearAndModelsData$: Observable<IToyotaLexusModelDetails>;
  isVinMandate: boolean;

  searchForm: FormGroup;
  hasExistingAppt = false;
  existingApptText = '';
  telematicsLoading: boolean=false;
  logedIn: boolean =  false;
  isLoginVisible: boolean = true;

  constructor(private readonly bannerService: AspBannerService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly vehicleService: VehicleService,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly authService: AuthService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly dealerState: Store<DealerState>,
    private readonly analyticsService: AnalyticsService,
    private readonly translate: TranslateService,
    private readonly userVehicleService: UserVehicleService,
    private readonly activatedRoute : ActivatedRoute
  ) {
    this.authService.user.subscribe(user => {
      if (user && !this.authService.isExpired()) {
        this.logedIn = true;
        util.navigateToRoute('dashboard', '/dashboard', this.router);
      }
    });
    this.translate.get(['COUNTRYTRANSLATION.estimatedMiles','COUNTRYTRANSLATION.enterMiles','COUNTRYTRANSLATION.milesIsRequired']).subscribe(translated => {
      this.translate.get([`welcome.${translated['COUNTRYTRANSLATION.estimatedMiles']}`,`welcome.${translated['COUNTRYTRANSLATION.enterMiles']}`,`welcome.${translated['COUNTRYTRANSLATION.milesIsRequired']}`]).subscribe(finaltranslation => {
      
        this.estimatedMiles =  finaltranslation[`welcome.${translated['COUNTRYTRANSLATION.estimatedMiles']}`]
        this.enterMiles = finaltranslation[`welcome.${translated['COUNTRYTRANSLATION.enterMiles']}`]
        this.milesIsRequired = finaltranslation[`welcome.${translated['COUNTRYTRANSLATION.milesIsRequired']}`]
      })
    })
    this.brand = util.environment.brand;
    this.registerUrl = util.environment.registerUrl;
    this.makes = util.getMakes();
    this.bindThis();

    this.inDealerSite  = util.inDealerSite();
    this.viewMode = this.inDealerSite ? 'tab2' : this.viewMode;

    this.vehicleService.getSeriesData('TOY').subscribe(t => {
     
    });

    this.setupForm();
    this.trackPageLoad();
    this.isLoginVisible = (util.countryCd==='US')?true:false;
  }

  login() {
    const loginLinkTrackingData = {
      content_section: 'my appointments',
      link_module: 'login module',
      link_text: 'login'
    };

    this.analyticsService.trackLink(loginLinkTrackingData);
    this.authService.loginB2c();
  }

  newUser() {
    const newUserLinkTrackingData = {
      content_section: 'my appointments',
      link_module: 'login module',
      link_text: 'signup'
    };

    this.analyticsService.trackLink(newUserLinkTrackingData);

    return true;
  }

  ngOnInit() {
    
    this.bannerService.hide();
    this.prepareForm();
    this.serviceAppointmentState.dispatch(new LoadModelYearAndModels());
    this.dealerState.dispatch(new dealerActions.LoadSettings({departments: 'service'}));
    this.modelYearAndModelsData$ = this.serviceAppointmentState.select(fromSelectors.selectModelYearAndModelsData);

    this.modelYearAndModelsData$.subscribe(data => {
      this.toyotaLexusModelDetails = data;
      this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
      const namesToDeleteSet = new Set(util.scionModelList);
      if(this.modelYearSeries){
        const modelArray = Object.keys(this.modelYearSeries).sort()
        const toyotaOrLexusModels = modelArray.filter(r=>{
          return !namesToDeleteSet.has(r);
        })
        const scionModels = modelArray.filter(r=>{
          return namesToDeleteSet.has(r);
        })
        if(this.step.value.make && this.step.value.make.toLocaleLowerCase() === 'scion'){
          this.models = scionModels;
        }else{
          this.models = toyotaOrLexusModels;
        }
      }else{
        this.models = [];
      }
    });
  }

  trackPageLoad() {
    setTimeout(() => {
      const myAppointmentTrackingData: any = {
        content_section: 'my appointments',
      }
      this.analyticsService.trackPageLoad(myAppointmentTrackingData);
    }, 3000);
  }

  navigateToTestDrive() {
    util.navigateToRoute('testdrive', '/testdrive', this.router);
  }

  navigateToService() {
    util.navigateToRoute('service', '/service', this.router);
  }

  logout() {
    this.authService.logoutB2c();
  }

  changeView(tab: string) {
    const navLinkTrackingData = {
      content_section: 'my appointments',
      link_module: 'tab header',
      link_text: tab === "tab1" ? "Schedule Appointment" : "Manage Appointment"
    }
    this.analyticsService.trackLink(navLinkTrackingData);
    this.viewMode = tab;
  }

  private setupForm() {
    this.searchForm = this.formBuilder.group({
      searchText: ['']
    });

   
  }

  searchTextTracking() {
    const searchFormTrackingData = {
      content_section: 'my appointments',
      link_module: 'manage appointment',
      link_text: 'VIN, phone or emai'
    };
    this.analyticsService.trackLink(searchFormTrackingData);
  }

  onSearchClicked() {
    const searchLinkTrackingData = {
      content_section: 'my appointments',
      link_module: 'manage appointment',
      link_text: 'search'
    };
    this.analyticsService.trackLink(searchLinkTrackingData);
    util.navigateToRoute('dashboard', '/dashboard', this.router, {
      queryParams: {
        searchText: this.searchForm.value.searchText
      }
    });
  }

  continueToService() {
    const nextLinkTrackingData = {
      content_section: 'my appointments',
      link_module: 'schedule appointment',
      link_text: "next"
    };
    let formTrackingFields = ['make', 'otherMake', 'model', 'year', 'mileage'];
    formTrackingFields.forEach(field => {
      nextLinkTrackingData['vehicle_' + field] = this.step.get(field).value;
    });
    this.analyticsService.trackLink(nextLinkTrackingData, true);
    this.serviceAppointmentState.dispatch(new UpdateServiceForm({ path: 'vehicleForm', value: { ...this.step.value } }));
    this.serviceAppointmentState.dispatch(new UpdateIsRedirected({ isRedirect: true }));
    this.serviceAppointmentState.dispatch(new UpdateCurrentStep({ step: 1 }));
    util.navigateToRoute('service', '/service', this.router);
  }

  setFormSubscription() {
    const commonTrackingData = {
      content_section: 'my appointments',
      link_module: 'schedule appointment',
    }
    this.subs.makeSub = this.step.get('make').valueChanges.subscribe((make: string) => {
      const makeSelectTrackingData = {
        ...commonTrackingData,
        link_text: 'make',
        link_input_field: make
      }
      this.analyticsService.serviceSchedulerVehicleData = { ...this.analyticsService.serviceSchedulerVehicleData, make: make }
      this.analyticsService.trackLink(makeSelectTrackingData);
      if (this.isOtherMake(make)) {
        this.isMakeOther = true;
        this.step.controls.year.reset(null, { emitEvent: false });
        this.step.controls.model.reset(null, { emitEvent: false });
      } else {
        this.isMakeOther = false;
        if (make && make?.toLocaleLowerCase() === 'toyota') {
          this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
        } else if (make && make?.toLocaleLowerCase() === 'lexus') {
          this.modelYearSeries = this.toyotaLexusModelDetails.lexus;
        }
        this.step.controls.year.reset('', { emitEvent: false });
        this.step.controls.model.reset('', { emitEvent: false });
        const namesToDeleteSet = new Set(util.scionModelList);
        if(this.modelYearSeries){
          const modelArray = Object.keys(this.modelYearSeries).sort()
          const toyotaOrLexusModels = modelArray.filter(r=>{
            return !namesToDeleteSet.has(r);
          })
          if(make && make.toLocaleLowerCase() === 'scion'){
            const modelYearSeries = this.toyotaLexusModelDetails.toyota;
            const scionModels = Object.keys(modelYearSeries).sort().filter(r=>{
              return namesToDeleteSet.has(r);
            })
            this.models = scionModels;
          }else{
            this.models = toyotaOrLexusModels;
          }
        }else{
          this.models = [];
        }
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels = [...this.modelYearSeries[chosenYear]].reverse() ;
    
        }
      }
      this.resetGroupTwoFields(make);
    });

    this.subs.yearSub = this.step.get('model').valueChanges.subscribe((data: string) => {
      const yearSelectTrackingData = {
        ...commonTrackingData,
        link_text: 'model name',
        link_input_field: data
      }
      if (!this.isMakeOther) {
        this.analyticsService.serviceSchedulerVehicleData = { ...this.analyticsService.serviceSchedulerVehicleData, year: data };
        this.analyticsService.trackLink(yearSelectTrackingData);
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels = [...this.modelYearSeries[chosenYear]].reverse();
        }
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.modelSub = this.step.get('year').valueChanges.subscribe((data: string) => {
      const nameSelectTrackingData = {
        ...commonTrackingData,
        link_text: 'model year',
        link_input_field: data
      }
      if (!this.isMakeOther) {
        this.analyticsService.serviceSchedulerVehicleData = { ...this.analyticsService.serviceSchedulerVehicleData, model: data };
        this.analyticsService.trackLink(nameSelectTrackingData);
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.vinSub = this.step.get('vin').valueChanges.subscribe((data: string) => {
      this.resetGroupOneFields(data);
    });

  }

  inputBlurEvent(fieldName: string) {
    const inputFieldTrackingData = {
      content_section: 'my appointments',
      module_link: 'schedule appointment',
      link_text: fieldName,
    };
    if (fieldName !== "vin") {
      inputFieldTrackingData['link_input_field'] = this.step.get(fieldName).value;
    }
    let vehicleData = { ...this.analyticsService.serviceSchedulerVehicleData };
    vehicleData[fieldName] = this.step.get(fieldName).value;
    this.analyticsService.serviceSchedulerVehicleData = vehicleData;
    this.analyticsService.trackLink(inputFieldTrackingData);
  }

  private isOtherMake(make: string) {
    return make.toLowerCase() === 'other';
  }

  resetGroupOneFields(value: string) {
    if (!!value) {
      this.hasExistingAppt = false;
      if (value.length >= 17 ) {
        if(this.logedIn){
        const queryText = value;
        this.dashboardState.dispatch(new dashboardActions.LoadAppointments({ queryText }));
        const appointments = this.dashboardState.select(selectAppointments);
        appointments.subscribe(response => {
          if (response && response.length > 0 && response[0]) {
            const dateArray = [];
            let count = 0;
            const today = DateTime.now().toFormat('yyyy-MM-dd');
            const currentdate = DateTime.fromFormat(today, 'yyyy-MM-dd');
            const length = response.length;
            for (let i = 0; i < length; i++) {
              const minDate = DateTime.fromFormat(response[i].appointmentStartDate, 'yyyy-MM-dd');
              if (minDate >= currentdate && count <= 9) {
                const displayDate = minDate.toFormat('MMM dd');
                dateArray.push(displayDate);
                this.hasExistingAppt = true;
                count++;
              }
            }
            const lastElement = count > 1 && dateArray.length > 0 ? 'and ' + dateArray.splice(count - 1, 1) : '';
            this.existingApptText = `${this.translate.instant('welcome.vehicleHasUpcomingAppointmentsScheduledOn')} ${dateArray.join()} ${lastElement}`;
          }
          else {
            this.hasExistingAppt = false;
            this.step.controls.isInputVinBased.setValue(true);
          }
          //if user is logedIn then telematics call will be performed
          if(this.logedIn){
            this.fetchTelematics(value);
          }
        })
      }
      else {
        this.hasExistingAppt = false;
        this.step.controls.isInputVinBased.setValue(true);
      }
    }
      this.step.controls.make.reset('', { emitEvent: false });
      this.step.controls.year.reset('', { emitEvent: false });
      this.step.controls.model.reset('', { emitEvent: false });
    }
  }

  fetchTelematics(vin:string){
    if(!this.telematicsLoading){
        this.step.controls.mileage.setValue(this.translate.instant('welcome.fetchingDataFromTelematics'));
        this.setTelematics(vin,null);
    }
  }

  private setTelematics(vin,previousMilage) {
    this.telematicsLoading = true;
    this.userVehicleService.getVehicleHealthReport(undefined,vin, vin)
      .subscribe(data => {
        if (data.status.code === '200') {
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          const telematicsData = Telematics.getTelematics(vehicleStatus, vehicleAlerts);
          const mileage = telematicsData?.mileage ? telematicsData?.mileage : previousMilage;
          this.step.controls['mileage'].setValue(mileage);
        }
        else{
        this.step.controls['mileage'].setValue(previousMilage); 
        }
        this.step.controls['mileage'].enable();
        this.telematicsLoading = false;
      },
      err=>{
            this.step.controls['mileage'].setValue(previousMilage);
          this.step.controls['mileage'].enable();
          this.telematicsLoading = false;
      });
    
  }


  resetGroupTwoFields(value: string) {
    if (!!value) {
      this.step.controls.isInputVinBased.setValue(false);
      this.hasExistingAppt = false;
      this.step.controls.vin.reset('', { emitEvent: false });
    }
  }

  private prepareForm() {
    this.step = this.formBuilder.group({
      isOwnVehicle: [false],
      isInputVinBased: [false],
      description: [''],
      imageUrl: [''],
      vehicleId: [''],
      vin: [''],
      make: [''],
      otherMake: [''],
      year: [''],
      model: [''],
      mileage: [null],
    }
    );
    this.step.setValidators(fromValidator.chooseVehicleValidator());
    this.dealerState.select(serviceSettings).subscribe(settings => {
      this.isVinMandate = settings?.portal?.mandateVin;
      if (this.isVinMandate) {
        this.step.controls.vin.setValidators(this.vinValidator());
      }
    });

    this.setFormSubscription();
  }

  isNot17Digit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[.]{17}$'
    );
  }

  isNotCharAndDigit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[\\w\\d]*$'
    );
  }

  private bindThis() {
    this.isNotCharAndDigit = this.isNotCharAndDigit.bind(this);
    this.isNot17Digit = this.isNot17Digit.bind(this);
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }
  vinValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (!this.step.controls.vin.value) {
        return { required: this.translate.instant('welcome.vinIsRequired') };
      } else {
        return null;
      }
    };
  }
}
