import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AspBannerService } from '../../../shared/components/asp-banner/asp-banner.service';
import { DateTime } from 'luxon';
import { TimeSlot } from '../../../shared/models/sales-appointments/time-slot';
import { AvailabilityOptionsData } from '../../../../assets/sampledata/appt-availability-options';
import { AppointmentService } from '../../../shared/services/asp/appointment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentType, IDeliveryAppointment, ISalesAppointment, LocationType } from '@signal/asp-data-commons';
import { TranslateService } from '@ngx-translate/core';
import * as util from '../../../shared/services/util.service'
import { buildSelectedChecklistInfo, getChecklistDuration } from '../../../store/sales-appointment/sales-appointment.adapter';
import { SubscriptionList } from '../../../shared/models/asp.types';
import { DealerState } from '../../../store/dealer/dealer.reducer';
import { Store } from '@ngrx/store';
import * as fromDealerSelector from '../../../store/dealer/dealer.selectors';

@Component({
  selector: 'app-confirmed-summary-info',
  templateUrl: './confirmed-summary-info.component.html',
  styleUrls: ['./confirmed-summary-info.component.scss']
})
export class ConfirmedSummaryInfoComponent implements OnInit, OnDestroy {
  view = 'confirm';
  appointmentType = AppointmentType.DELIVERY;
  appointment: IDeliveryAppointment;
  makeModelYear: string;
  detailMap: { [key: string]: string | number };
  email: string;
  id: string;
  contracting:number = 0;
  response: string;
  timeSlots: TimeSlot[] = AvailabilityOptionsData.timeSlots;
  subs: SubscriptionList = {};
  dur:number;

  constructor(private readonly bannerService: AspBannerService,
    private readonly appointmentService: AppointmentService,
    private readonly route: ActivatedRoute,
    private readonly dealerState: Store<DealerState>,
    private readonly translate: TranslateService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router) {
    
      const urlState = this.router.getCurrentNavigation()?.extras?.state as ISalesAppointment;
      
      if(urlState?.appointmentId) {
        this.appointment = urlState;
        this.response = urlState.appointmentStatus;
        this.setDetailMap(urlState);
      } else {
      this.subs.route = this.route.queryParams.subscribe(params => {
        this.email = params.email;
        this.id = params.id;
        if (this.id) {
          this.subs.getAppt = this.appointmentService.get(AppointmentType.DELIVERY, this.id).subscribe(res => {
            if (res && res.data) {
              this.appointment = res.data;
              this.response = res.data.appointmentStatus;
              this.setDetailMap(res.data);
            }
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.bannerService.hide();
  }

  setDetailMap(appointmentDetails) {
    this.dealerState.select(fromDealerSelector.time).subscribe(time =>{
      const vehicle = appointmentDetails.vehicle;

      let address = '';
      let displayText = `${appointmentDetails.dealer.address.addressLine1} ${appointmentDetails.dealer.address.city}, ${appointmentDetails.dealer.address.state} ${appointmentDetails.dealer.address.zip}`;

      if (appointmentDetails.transportation.category === LocationType.REMOTE) {
        const custAdd = appointmentDetails.dropOffAddress;
        address = custAdd.addressLine1 + ', ' + (custAdd.addressLine2 ? custAdd.addressLine2 + ', ' : '') + custAdd.city + ', ' + custAdd.state + ' - ' + custAdd.zip;
        displayText = `${this.translate.instant('testDrive.weWillBringTheVehicleTo')} \n ${address}`
      }

      let checklist = '';
      let estimatedDuration = 0;
      if (this.appointment.appointmentType === AppointmentType.DELIVERY) {
        estimatedDuration = getChecklistDuration(appointmentDetails.checklistRequest);
        const checklistData = buildSelectedChecklistInfo(appointmentDetails.checklistRequest);
        if (checklistData && checklistData.length > 0) {
          for (let i = 0; i < checklistData.length; i++) {
            if (i < 5) {
              checklist += `${checklistData[i].displayName}\n`;
            }
            else if (i > 4 && checklistData.length > 5) {
              const remaining = checklistData.length - 5;
              checklist += `... + Additional ${remaining} checklist item${remaining > 1 ? 's' : ''}`;
              break;
            }
          }
        }
      }

      const advisorName = appointmentDetails.advisor.displayName;
      this.makeModelYear = `${vehicle.year || ''} ${util.titleCase(vehicle.make) || ''} ${vehicle.model || ''}`;
    
      const dealerComment = appointmentDetails.appointmentComments ? this.getComment(appointmentDetails.appointmentComments, 'Comments-From-Dealer') : '';
      const additionalComments = appointmentDetails.appointmentComments ? this.getComment(appointmentDetails.appointmentComments, 'Customer-Additional-Comments') : '';

      this.dur = time?.deliveryAppointmentDuration || 0;
      this.detailMap = {
        'selectService.time': DateTime.fromISO(appointmentDetails.appointmentStartDate).toFormat('MMM dd') + ' at ' + appointmentDetails.timeSlotId,
        'selectService.vehicle': this.makeModelYear,
        'delivery.checkList': checklist,
        'delivery.estimatedDuration' : util.convertMinsToHourMins(estimatedDuration+this.dur,true),
        'delivery.dealerComments': dealerComment,
        'delivery.additionalComments': additionalComments,
        'transportation.consultant': advisorName,
        'transportation.location': displayText
      };
      
      if(!dealerComment) {
        delete this.detailMap['delivery.dealerComments'];
      }
      if(!additionalComments) {
        delete this.detailMap['delivery.additionalComments'];
      }
    })
  }

  getComment(data, field) {
    let returnComment = '';
    data.split(':::').forEach(comment=> {
      if(comment.includes(field)) {
        returnComment = comment.replace(`${field} - `, '').trim();
      }
    })
    return returnComment;
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

}

