import produce from 'immer';

import * as fromActions from './sales-appointment.actions';
import * as fromAdapter from './sales-appointment.adapter';
import { Transport } from '../../shared/models/sales-appointments/transport';
import { SeriesData } from '../../shared/models/sales-appointments/series-data';
import { VehicleColor } from '../../shared/models/sales-appointments/vehicle-color';
import { IAddress, IAsyncData, ICustomer, IVehicle, IAvailabilityOptions, IAvailability, ITestDriveVehicleInventory, IChecklist, AppointmentType } from '@signal/asp-data-commons';
import { getDateTime, fromAvailabilityOptions } from '../app.adapter';
import { IAppointment, IChecklistRequest, IVehicleDetails } from '@signal/asp-data-commons';
import _ from 'lodash';

export function salesAppointmentReducer(
    state: SalesAppointmentState = initialState,
    action: fromActions.SalesAppointmentType): SalesAppointmentState {

    switch (action.type) {
        case fromActions.SalesAppointmentActionTypes.UPDATE_SALES_FORM_DATA: {
            const payload = action instanceof fromActions.UpdateSalesFormData ? action.payload : undefined;
            return fromAdapter.UpdateSalesFormData(state, payload); 
        }

        case fromActions.SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.CreateSalesAppointment ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId : null,
                    appointmentType: payload?.appointmentType
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.CreateSalesAppointmentSuccess ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId: payload.appointmentModel.appointmentId,
                    isSubmitted: true,
                    appointment:payload.appointmentModel
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.CreateSalesAppointmentFail ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId: '',
                    isSubmitted: false
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.GET_EDIT_DATA: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.GetEditData ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId: payload.appointmentId,
                    isSubmitted: false,
                    isEditMode : true
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.GET_EDIT_DATA_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.GetEditDataSuccess ? action.payload : undefined;
                draftState.appointment = fromAdapter.editDataPopulatedInState(payload.appointmentModel);
                draftState.editDetails.appointmentStatus = payload.appointmentModel.appointmentStatus;
                draftState.editDetails.dealerCode = payload.appointmentModel.dealerCode;
            });
        }

        case fromActions.SalesAppointmentActionTypes.GET_EDIT_DATA_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.GetEditDataFail ? action.payload : undefined;
            });
        }

        case fromActions.SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.EditSalesAppointment ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentType: payload.appointmentType,
                    ...draftState.editDetails
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.EditSalesAppointmentSuccess ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId: payload.appointmentModel.appointmentId,
                    isSubmitted: true,
                    appointment:payload.appointmentModel
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.EditSalesAppointmentFail ? action.payload : undefined;
                draftState.editDetails = {
                    appointmentId: '',
                    isSubmitted: false
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.UPDATE_PATCH_SALES_FORM: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdatePatchSalesForm ? action.payload : undefined;
                if(payload.path === 'mergeAll'){
                    draftState.appointment = {...draftState.appointment,...payload.value}
                    return;
                }
              draftState.appointment[payload.path] = payload.value;
            });
          }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY: {
            return produce(state, draftState => {
                draftState.availability = {
                    data: state.availability.data,
                    loading: true,
                    loaded: false,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAvailabilitySuccess ? action.payload : undefined;
                const availability = state.appointment.availability;
                if (!(availability?.timeSlotId && availability?.appointmentStartDate)) {
                    let dateTime;
                    if (availability && !availability.appointmentStartDate) {
                        dateTime = getDateTime(payload.availability);
                    } else {
                        dateTime = {
                            appointmentStartDate: availability?.appointmentStartDate,
                            timeSlotId: availability?.timeSlotId
                        };
                    }
                    draftState.appointment.transportationTypeCode = state.appointment.transportationTypeCode;
                    draftState.appointment.availability = {
                        ...availability,
                        ...dateTime
                    };
                }
                draftState.availability = {
                    data: payload.availability,
                    loading: false,
                    loaded: true,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAvailabilityFail ? action.payload : undefined;
                draftState.availability = {
                    data: {} as IAvailability,
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS: {
            return produce(state, draftState => {
                draftState.availabilityOptions = {
                    data: {} as IAvailabilityOptions,
                    loading: true,
                    loaded: false,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAvailabilityOptionsSuccess ? action.payload : undefined;
                draftState.availabilityOptions = {
                    data: payload.availabilityOptions,
                    loading: false,
                    loaded: true,
                    error: ''
                };
                if (!state.editDetails?.isEditMode) {
                    const availability = fromAvailabilityOptions(payload.availabilityOptions,state.appointment.transportationTypeCode);
                    draftState.appointment.availability = availability.availability;
                    draftState.appointment.transportationTypeCode = availability.transport;
                }
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAvailabilityOptionsFail ? action.payload : undefined;
                draftState.availabilityOptions = {
                    data: {} as IAvailabilityOptions,
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_SERIES_TRIM: {
            return produce(state, draftState => {
                draftState.seriesDetails = {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE: {
            return produce(state, draftState => {
                draftState.vehicleInventory = {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: ''
                }
            })
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadInventoryVehicleSuccess ? action.payload : undefined;
                draftState.vehicleInventory = {
                    data: payload.vehicleDetails,
                    loading: false,
                    loaded: true,
                    error: ''
                }
            })
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadInventoryVehicleFail ? action.payload : undefined;
                draftState.seriesDetails = {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST: {
            return produce(state, draftState => {
                draftState.allChecklist = {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: ''
                }
            })
          }
      
        case fromActions.SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAllChecklistSuccess ? action.payload : undefined;
                draftState.allChecklist = {
                    data: payload.checklistDetails,
                    loading: false,
                    loaded: true,
                    error: ''
                }
            })
          }
      
        case fromActions.SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadAllChecklistFailure ? action.payload : undefined;
                draftState.allChecklist = {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
          }      

        case fromActions.SalesAppointmentActionTypes.CREATE_CHECKLIST: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.CreateChecklist ? action.payload : undefined;

                draftState.appointment.checklistRequest = payload.data;
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_SERIES_TRIM_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadSeriesAndTrimSuccess ? action.payload : undefined;
                draftState.seriesDetails = {
                    data: payload.seriesDetails,
                    loading: false,
                    loaded: true,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_SERIES_TRIM_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadSeriesAndTrimFail ? action.payload : undefined;
                draftState.seriesDetails = {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS: {
            return produce(state, draftState => {
                draftState.exteriorColors = {
                    data: [],
                    loading: true,
                    loaded: false,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadExteriorColorsSuccess ? action.payload : undefined;
                draftState.exteriorColors = {
                    data: payload.exteriorColors,
                    loading: false,
                    loaded: true,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS_FAILURE: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadExteriorColorsFail ? action.payload : undefined;
                draftState.exteriorColors = {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: payload.error
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.RESET_SALES_APPOINTMENT_STATE: {
            return initialState;
        }

        case fromActions.SalesAppointmentActionTypes.RESET_SALES_APPOINTMENT_FORM: {
            return produce(state, draftState => {
                draftState.appointment = initialAppointment;
            });
        }

        case fromActions.SalesAppointmentActionTypes.RESET_EXTERIOR_COLOR: {
            return produce(state, draftState => {
                draftState.exteriorColors = {
                    data: [],
                    loading: false,
                    loaded: false,
                    error: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_CUSTOMER_DETAILS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadCustomerDetails ? action.payload : undefined;
                draftState.appointment.customer = {
                    firstName: payload.signedInUserDetails.firstName,
                    lastName: payload.signedInUserDetails.lastName,
                    emailAddress: payload.signedInUserDetails.emailAddress,
                    phoneNumber: ''
                };
            });
        }

        case fromActions.SalesAppointmentActionTypes.LOAD_TESTDRIVE_VIN_VEHICLE_SUCCESS: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.LoadTestDriveVinVehiclecSuccess ? action.payload : undefined;
                let vehicle:IVehicleDetails;
                if(payload?.data?.vin) {
                     vehicle = {
                        year: payload?.data.year,
                        model: payload?.data.model,
                        vin:payload?.data.vin,
                        imageUrl:payload?.data.imageUrl,
                        make: payload?.data.make,
                        description: payload?.data.description,
                        source: payload?.data.source,
                        unitId: payload?.data.unitId,
                        dealerCode: payload?.data.dealerCode,
                    };
                } else {
                    vehicle = {
                        year: "",
                        model: "",
                        vin: "",
                        imageUrl: "",
                        make: "vinIsOffline",
                        description: "",
                        source: undefined,
                        unitId: undefined,
                        dealerCode: "",
                    };
                }
                
                /* this return is to handle exception where vin build data is not available */
                draftState.appointment.vehicle ={...vehicle };
            });
        }

        case fromActions.SalesAppointmentActionTypes.UPDATE_CURRENT_STEP: {
            return produce(state, draftState => {
                const payload = action instanceof fromActions.UpdateCurrentStep ? action.payload : undefined;
                draftState.currentStep = payload?.step;
            });
        }

        default:
            return state;
    }

}

const initialVehicle: IVehicle = {
    year: null,
    model: null,
    trim: null,
    exteriorColor: null,
    vin:null,
    imageUrl: null
};
const initialVehicleDetails: IVehicleDetails = {
    ...initialVehicle,
    marketingTitle:null
}
const initialOtherVehicles: IVehicle[] = [{
    year: null,
    model: '',
    trim: ''
}];

const initialTradeIn: ITradeInVehicle = {
    tradeInCheckbox: false,
    vehicle: [{
        year: null,
        make: null,
        model: null,
        vin: null,
        mileage: null,
        trim: null,
        isOwnVehicle: false
    }],
    title: '',
    trepresentativeInfo: '',
    tradeInComments: '',
    dealerComments: ''
};

const initialAddress: IAddress = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA'
};

const initialCustomer: ICustomerForm = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: ''
};

const initialAvailability: IAvail = {
    timeSlotId: '',
    advisorId: '',
    languageId: '',
    appointmentStartDate: '',
};

const initialAppointment: ISalesAppointmentForm = {
    vehicle: initialVehicle,
    otherVehicles: initialOtherVehicles,
    tradeIn: initialTradeIn,
    transportationTypeCode: '',
    remoteZipCode: '',
    availability: initialAvailability,
    customer: initialCustomer,
    appointmentComments: '',
    checklistRequest:[],
    address: initialAddress
};

const initialState = {
    editDetails: undefined,
    appointment: initialAppointment,
    currentStep: 0,
    transportOptions: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
    },
    availabilityOptions: {
        data: {} as IAvailabilityOptions,
        loading: false,
        loaded: false,
        error: ''
    },
    availability: {
        data: {} as IAvailability,
        loading: false,
        loaded: false,
        error: ''
    },
    seriesDetails: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
    },
    allSeriesDetails: {
        data: {
            ...initialVehicleDetails
        },
        loading: false,
        loaded: false,
        error: ''
    },
    exteriorColors: {
        data: [],
        loading: false,
        loaded: false,
        error: ''
    },
    vehicleInventory:{
        data:[],
        loading: false,
        loaded: false,
        error: ''
    },
    allChecklist:{
        data:[],
        loading:false,
        loaded:false,
        error:''
    }
};

export interface SalesAppointmentState {
    editDetails: IEditDetails;
    appointment: ISalesAppointmentForm;
    transportOptions: IAsyncData<Transport[]>;
    availabilityOptions: IAsyncData<IAvailabilityOptions>;
    availability: IAsyncData<IAvailability>;
    seriesDetails: IAsyncData<SeriesDetailsForYear[]>;
    allSeriesDetails: IAsyncData<IVehicle>;
    exteriorColors: IAsyncData<VehicleColor[]>;
    vehicleInventory: IAsyncData<ITestDriveVehicleInventory[]>;
    allChecklist: IAsyncData<IChecklist[]>;
    currentStep: number;
}

export interface IEditDetails {
    isEditMode?: boolean;
    email?: string;
    appointmentId: string;
    dealerCode?: string;
    appointmentStatus?: string;
    isSubmitted?: boolean;
    appointment?:IAppointment;
    appointmentType?: string;
}

export interface SeriesDetailsForYear {
    year: string;
    seriesDetails: SeriesData[];
}

export interface ITradeInVehicle {
    tradeInCheckbox: boolean;
    vehicle: IVehicle[];
    title?: string;
    trepresentativeInfo?: string;
    dealerComments?: string;
    tradeInComments?: string;
    internalComments?: string;
    additionalComments?: string;
}

export interface IAvail {
    timeSlotId: string;
    advisorId: string;
    languageId: string;
    appointmentStartDate: string;
}

export type ICustomerForm = ICustomer ;

export interface ISalesAppointmentForm {
    vehicle: IVehicle;
    otherVehicles: IVehicle[];
    tradeIn: ITradeInVehicle;
    transportationTypeCode: string;
    remoteZipCode: string;
    availability: IAvail;
    customer: ICustomerForm;
    appointmentComments?:string;
    checklistRequest?: IChecklistRequest[];
    address: IAddress;
}

export interface IFooterSummary {
    key: string;
    value: string | number;
    disclaimer?: string;
  }
