import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IDashboardVehicle } from '../../shared/models/dashboard.model';
import * as fromDashboard from '../../store/dashboard/dashboard.selectors';
import { Store } from '@ngrx/store';
import { DashboardState } from '../../store/dashboard/dashboard.reducer';
import { CancelAppointmentFromDashboard } from '../../store/dashboard/dashboard.actions';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import { CreateServiceAppointment, SetMakeModelYrPageDefault } from '../../store/service-appointment/service-appointment.actions';
import { AppointmentType, IVehicle ,ISalesInfo} from '@signal/asp-data-commons';
import { navigateToRoute, environment } from '../../shared/services/util.service';
import { AnalyticsService } from './../../shared/services/analytics.service';
import {DateTime} from 'luxon';
import {TranslateService} from '@ngx-translate/core';
import * as fromSelectors from '../../store/dealer/dealer.selectors';
const salesTypes=['Test Drive' ,'Delivery','Learning']
@Component({
  selector: 'app-appointment-section',
  templateUrl: './appointment-section.component.html',
  styleUrls: ['./appointment-section.component.scss'],
})
export class AppointmentSectionComponent implements OnInit {
  salesSettings$:Observable<ISalesInfo>;
  static readonly CONFIG = {
    [AppointmentType.TEST_DRIVE]: {
      title: 'dashboardAppointments.testDrive',
      singleNotificationTxt: (count) => `dashboardAppointments.upcomingTestDrive`,
      multipleNotificationTxt: (count) => `dashboardAppointments.upcomingTestDrives`,
      noNotificationTxt: `dashboardAppointments.youHaveNoUpcomingAppointments`,
      bookNewAppointmentTxt: 'dashboardAppointments.scheduleATestDrive',
      loadingTxt:'dashboardAppointments.loadingTestDriveAppointments',
    },
    [AppointmentType.SERVICE]: {
      title: 'dashboardAppointments.service',
      singleNotificationTxt: (count) => `dashboardAppointments.upcomingService`,
      multipleNotificationTxt: (count) => `dashboardAppointments.upcomingServices`,
      noNotificationTxt: `dashboardAppointments.youHaveNoUpcomingAppointments`,
      bookNewAppointmentTxt: 'dashboardAppointments.scheduleService',
      loadingTxt:'dashboardAppointments.loadingServiceAppointments',
    },
    [AppointmentType.DELIVERY]: {
      title: 'dashboardAppointments.delivery',
      singleNotificationTxt: (count) => `dashboardAppointments.upcomingDelivery`,
      multipleNotificationTxt: (count) => `dashboardAppointments.upcomingDeliveries`,
      noNotificationTxt: `dashboardAppointments.youHaveNoUpcomingAppointments`,
      bookNewAppointmentTxt: 'dashboardAppointments.scheduleADelivery',
      loadingTxt:'dashboardAppointments.loadingDeliveryAppointments',
    },
    [AppointmentType.LEARNING]: {
      title: 'dashboardAppointments.learning',
      singleNotificationTxt: (count) => `dashboardAppointments.upcomingLearning`,
      multipleNotificationTxt: (count) => `dashboardAppointments.upcomingLearning`,
      noNotificationTxt: 'dashboardAppointments.youHaveNoUpcomingAppointments',
      bookNewAppointmentTxt: 'dashboardAppointments.scheduleALearning',
      loadingTxt:'dashboardAppointments.loadingLearningAppointments',
    },
    "default": {
      title: 'dashboardAppointments.myVehicles',
      singleNotificationTxt: (count) => `dashboardAppointments.youHaveVehicle`,
      multipleNotificationTxt: (count) => `dashboardAppointments.youHaveVehicle`,
      noNotificationTxt: `dashboardAppointments.youHaveNoVehicles`,
      bookNewAppointmentTxt: 'dashboardAppointments.selectADifferentVehicle',
      loadingTxt:'dashboardAppointments.loadingMyVehicles',
    }
  };
  @Input() appointmentType: AppointmentType;
  @Input() signedIn: boolean;
  isTestDrive: boolean;
  isService: boolean;
  isDelivery: boolean;
  isLearning: boolean;
  isMyVehicle:boolean = false;
  isThisLastSection: boolean;
  /* Observables for vehicles */
  salesOrServiceAppointmentVehicles$: Observable<IDashboardVehicle[]>;
  userVehicles$: Observable<IVehicle[]>;
  salesOrServiceAppointmentCount$: Observable<number>;
  appointments$ : Observable<any>;
  showLoader : boolean = false;
  enableDrive:boolean=false;
  appointnmentCount :boolean;
  constructor(private readonly router: Router,
    private readonly dashboardStore: Store<DashboardState>,
    private readonly serviceAppointmentStore: Store<ServiceAppointmentState>,
    private readonly analyticsService : AnalyticsService,
    private readonly translate: TranslateService) {
  }

  ngOnInit(): void {
    this.salesSettings$ = this.dashboardStore.select(fromSelectors.selectSalesDetails);
    this.salesSettings$.subscribe(item=> {     
      if(this.appointmentType === "Test Drive")
      {
        this.enableDrive= item.enableTestDrive
      } 
      else if(this.appointmentType === "Learning")
      {
        this.enableDrive= item.enableLearning
      } 
      else if(this.appointmentType === "Delivery")
      {
        this.enableDrive= item.enableDelivery
      } 
      else
      {
      this.enableDrive= true
      }
    })
    switch (this.appointmentType) {
      case AppointmentType.TEST_DRIVE:
        this.salesOrServiceAppointmentVehicles$ = this.dashboardStore.select(fromDashboard.selectTestDriveApptForDashboard);
        this.salesOrServiceAppointmentCount$ = this.dashboardStore.select(fromDashboard.selectTestDriveAppointmentsCount);
        this.isTestDrive = true;
        this.isThisLastSection = this.signedIn? !environment.showDelivery : !this.signedIn;
        break;
      case AppointmentType.DELIVERY:
        this.salesOrServiceAppointmentVehicles$ = this.dashboardStore.select(fromDashboard.selectDeliveryApptForDashboard);
        this.salesOrServiceAppointmentCount$ = this.dashboardStore.select(fromDashboard.selectDeliveryAppointmentsCount);
        this.isDelivery = true;
        this.isThisLastSection = !environment.showDelivery;
        break;
        case AppointmentType.LEARNING:
        this.salesOrServiceAppointmentVehicles$ = this.dashboardStore.select(fromDashboard.selectLearningApptForDashboard);
        this.salesOrServiceAppointmentCount$ = this.dashboardStore.select(fromDashboard.selectLearningAppointmentsCount);
        this.isLearning = true;
        this.isThisLastSection = environment.showDelivery;
        break;
      case AppointmentType.SERVICE:
        this.showLoader = true;
        this.salesOrServiceAppointmentVehicles$ = this.dashboardStore.select(fromDashboard.selectServiceAppointmentsForDashboard);
        this.salesOrServiceAppointmentCount$ = this.dashboardStore.select(fromDashboard.selectServiceAppointmentsCount);
        this.dashboardStore.select(fromDashboard.selectUserVehiclesAndAppointmentsLoading).subscribe(value=>{
          if(!value.vechilesStatus && !value.appointmentStatus){
              this.showLoader = false;
            }
        })
        this.isService = true;
        if(this.salesOrServiceAppointmentVehicles$){
          this.salesOrServiceAppointmentVehicles$.subscribe(vehicles=>{
            this.groupVehicles(vehicles)
          });
        }
        break;
        default :
          this.showLoader = true;
          this.userVehicles$ = this.dashboardStore.select(fromDashboard.selectMyUserVehicles);
          this.salesOrServiceAppointmentCount$ = this.dashboardStore.select(fromDashboard.selectMyUserVehiclesCount);
          this.dashboardStore.select(fromDashboard.selectUserVehiclesLoading).subscribe(value=>{
            if(!value){
              this.showLoader = false;
            }
        })
        this.isMyVehicle = true;
          break;
    }
    
    this.salesOrServiceAppointmentCount$?.subscribe(item=> {     
     if(item === 0 && salesTypes.includes(this.appointmentType))
     this.appointnmentCount=false
     else if(item >=1 && salesTypes.includes(this.appointmentType))
     this.appointnmentCount=true
     else
     this.appointnmentCount=true
   })

  }


  groupVehicles(vehicles : Array<any>){
    let groupedVehicles = [...vehicles.reduce((final,current)=>{
      const key = (current?.model+"-"+ current?.make+"-" + current?.model+"-" + current?.vin).toLowerCase();
      let appointmentGroup;
      if(final.has(key)){
          appointmentGroup = final.get(key);
          appointmentGroup.appointment.push(current.appointment);
      }else{
        appointmentGroup = Object.assign({},current,{
          appointment : current.appointment ? [current.appointment] : [],
          dealer : current.appointment?.dealer
        });
      }
      return final.set(key, appointmentGroup);
    },new Map).values()];
    groupedVehicles = this.sortAppointments(groupedVehicles);
    this.appointments$ = of(groupedVehicles);
  }

  sortAppointments(vehicles) : Array<any>{
    vehicles.forEach( vehicle => {
      if(vehicle.appointment.length>1){
        vehicle.appointment.sort((appt1,appt2)=>{
          const startDate1 = DateTime.fromFormat(`${appt1.appointmentStartDate} , ${appt1.timeSlotId}`,'yyyy-MM-dd , t');
          const startDate2 = DateTime.fromFormat(`${appt2.appointmentStartDate} , ${appt2.timeSlotId}`,'yyyy-MM-dd , t');
          return startDate1.toMillis() - startDate2.toMillis();
        })
      }
    });
    return vehicles;
  }

  onNewAppointmentClick() {
    const linkTrackingData = {
      content_section : 'scheduled services and test drives',
      link_module : 'service module',
      link_text : 'new appointment clicked'
    }
    this.analyticsService.trackLink(linkTrackingData,true);
    switch (this.appointmentType) {
      case AppointmentType.TEST_DRIVE:
        navigateToRoute('testdrive', '/testdrive', this.router);
        break;
      case AppointmentType.DELIVERY:
        navigateToRoute('delivery', '/delivery', this.router);
        break;
      case AppointmentType.SERVICE:
        this.serviceAppointmentStore.dispatch(new CreateServiceAppointment());
        navigateToRoute('service', '/service', this.router);
        break;
      case AppointmentType.LEARNING:
        navigateToRoute('learning', '/learning', this.router);
        break;
      default :
        this.serviceAppointmentStore.dispatch(new CreateServiceAppointment());
        this.serviceAppointmentStore.dispatch(new SetMakeModelYrPageDefault());
        navigateToRoute('service', '/service', this.router);
        break;
    }
  }

  onServiceAppointmentCancel(vehicle: IDashboardVehicle,index:number) {
    this.dashboardStore.dispatch(
      new CancelAppointmentFromDashboard({
        appointment: vehicle.appointment[index]
      }));
  }
  onSalesAppointmentCancel(vehicle: IDashboardVehicle) {
    this.dashboardStore.dispatch(
      new CancelAppointmentFromDashboard({
        appointment: vehicle.appointment
      }));
  }

  getTitle(): string {
    return this.translate.instant(this.getConfig().title);
  }

  getNotificationTxt(count): string {
    if (count === 0) {
      this.appointnmentCount= false;
      return this.translate.instant(this.getConfig().noNotificationTxt);
    } else if (count === 1) {
      this.appointnmentCount= true;
      return this.translate.instant(this.getConfig().singleNotificationTxt(count), {count});
    } else if (count > 1) {
      this.appointnmentCount= true;
      return this.translate.instant(this.getConfig().multipleNotificationTxt(count), {count});
    }
    return ;
  }

  getLoadingTxt(): string {
    return this.getConfig().loadingTxt;
  }
  getNewAppointmentTxt(): string {
    return this.translate.instant(this.getConfig().bookNewAppointmentTxt);
  }

  getConfig() {
    return AppointmentSectionComponent.CONFIG[this.appointmentType];
  }
}
