<div class="container mt " id="checklist-container">
  <div class="checklist-content select-checklist">
    <div id="checklist-request-container" class="container-fluid">
      <div *ngIf="allChecklistLoading$ | async" id="loading-spinner">
        <app-loading size="0.5" color="var(--primary-sec-color)"></app-loading>
      </div>
      <div *ngIf="!(allChecklistLoading$ | async) && step?.controls">
        <div class="title-container">
          <span class="step-title checklist-title">{{ 'selectService.chooseFromChecklist' | translate }}</span>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <form [formGroup]="step">
              <div *ngFor="let item of allOnlineCategories">
                <span class="step-subtitle">{{item}}<div class="checklist-counter ml-3">
                    {{selectedCategoryTracker[item].selected}}</div>
                  <div class="step-deselect ml-4" (click)="clearSelections(item)">{{
                    (selectedCategoryTracker[item].selectionText === 'select all' ? 'selectService.selectAll' :
                    'selectService.deselectAll') | translate}}</div>
                </span>
                <div [formArrayName]="item" *ngFor="let checklist of step.get(item)?.controls, let i =index">
                  <mat-expansion-panel class="your-info-expansion" [expanded]="checklist.value.isExpanded"
                    (opened)="checklist.get('isExpanded').patchValue(true)"
                    (closed)="checklist.get('isExpanded').patchValue(false)" [formGroupName]="i">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="step-title">
                        <div class="title-description">
                          <div>
                            <mat-checkbox [disabled]="checklist.value.isMandatory"
                              [checked]="checklist.value?.isChecked" [name]="checklist.value?.displayName"
                              class="checklist-checkbox" disableRipple (change)="onParentCheckboxChange($event,item, i)"
                              (click)="$event.stopPropagation()" [id]='checklist.value.checklistId'>
                            </mat-checkbox><span class="mat-title">{{checklist.value?.displayName}}</span>
                          </div>
                          <div>
                            <p [ngClass]="checklist.value.isExpanded ? 'description-expanded' : 'description'">
                              {{checklist.value.description}}</p>
                          </div>
                        </div>
                        <span class="duration-span ml-4 ml-md-auto">{{checklist.value.displayTotalDuration}}</span>
                      </mat-panel-title>

                    </mat-expansion-panel-header>
                    <div class="expansion-body">

                      <textarea *ngIf="checklist.get('userComments').value" (keyup)="handleTextArea(item, i)"
                        class="checklist-comments form-control"
                        [ngClass]="{'invalid-border': checklist.get('commentsFromUser').invalid}"
                        [placeholder]="checklist.value.isChecked ? 'Enter comments here':''" rows='3'
                        [readonly]="!checklist.value.isChecked" #textInput
                        formControlName="commentsFromUser">{{checklist?.value.commentsFromUser}}</textarea>
                      <mat-error class="comments-warning" *ngIf="commentsFromUserControlHasError(item, i)">
                        {{'selectService.commentsAreRequired' | translate}}</mat-error>

                      <div *ngIf="checklist?.value.isChildChecklistEnabled" class="includes-text">Includes</div>

                      <div *ngIf="checklist?.value.isChildChecklistEnabled">
                        <div class="step-title child-checkbox"
                          *ngFor="let child of checklist.value.childChecklist, let childIndex = index">
                          <ng-template [ngIf]="child?.online">
                            <div class="child-title">
                              <mat-checkbox [disabled]="checklist.value?.isMandatory" [checked]="child?.isChecked"
                                [name]="child?.name" class="checklist-checkbox" disableRipple
                                (change)="onChildCheckboxChange($event,item,checklist.value.checklistId,child.id, i, childIndex)"
                                [id]="child?.id">
                              </mat-checkbox><span>{{child?.name}}</span>
                            </div>
                            <span class="child-duration-span">{{child?.displayTime}}</span>
                          </ng-template>
                        </div>
                      </div>

                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>