<div class='appt-time-loader' *ngIf="(availabilityOptionsLoading$ | async)">
  <app-loading size=".5"></app-loading>
</div>
<div class='appointment-container' *ngIf="!(availabilityOptionsLoading$ | async)">
  <form [formGroup]='step'>

    <div class='availability'>
      <section class='availability-header'>
        {{'transportation.appointmentAvailability'| translate}}
      </section>
      <app-availability *ngIf="languageId && isEditMode;else noEdit" class='app-availability' [apptType]="apptType" formControlName='availability'
        [options]="availabilityOptions$ | async" [isAvailabilityLoading]="availabilityLoading$ | async"
        [availability]="availability$ | async" [selectedTransport]="transport" (monthChanged)="onMonthChange($event)"
        (timeSlotValidator)="setValidatorsForTimeSlot($event)" [lockSalesConsultant]="lockSalesConsultant" [checklistDuration]="checklistDuration" [customerLanguage]="languageId" [isEditMode]="isEditMode"></app-availability>
        <ng-template #noEdit>
        <app-availability class='app-availability' [apptType]="apptType" formControlName='availability'
          [options]="availabilityOptions$ | async" [isAvailabilityLoading]="availabilityLoading$ | async"
          [availability]="availability$ | async" [selectedTransport]="transport" (monthChanged)="onMonthChange($event)"
          (timeSlotValidator)="setValidatorsForTimeSlot($event)" [lockSalesConsultant]="lockSalesConsultant" [checklistDuration]="checklistDuration"></app-availability>
        </ng-template>
    </div>
  </form>
</div>