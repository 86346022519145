import { NavigationExtras, Params, Router } from '@angular/router';
import { Make } from '../models/brand.model';
import { VehicleModelYearList } from '../models/service-appointments/series-data';
import { SubscriptionList } from '../models/asp.types';
import { IServiceOpcode, IVehicle, PricingType, Brand } from '@signal/asp-data-commons';
import { environmentConst } from '../../../environments/environment';

export const imageBaseURL = 'https://www.toyota.com/';

export let dealerCd;

export let languageCd;
export let countryCd: string = "US";
export let currentCurrency: string = 'USD';
export let currentLanguageLocale: string = 'en-US';

export const assetUrl = (url: string): string => {
  // @ts-ignore
  const publicPath = environment.spaHost;
  const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
  const urlPrefix = url.startsWith('/') ? '' : '/'

  return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
}
export const setDefaultImg = (event, val) => {
  event.target.onerror = null;
  event.target.src = assetUrl(val);
}

/**
 * Returns the the range including start and end with stepper as 1
 *
 * @param start number for producing the range
 * @param end number for producing the range
 * @returns number[]
 */
export const range = (start: number, end: number): number[] => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};
export function setCurrentCurrency(currency: string) {
  currentCurrency = currency;
}
export function setCurrentLanguageLocale(languageLocale: string) {
  currentLanguageLocale = languageLocale;
}
/**
 * Returns brand object array
 */
export const getMakes = (): Make[] => {
  const brand = environment.brand.toLocaleLowerCase();
  const toyota: Make = { name: 'Toyota', code1: 'T', code3: 'TOY' };
  const lexus: Make = { name: 'Lexus', code1: 'L', code3: 'LEX' };
  return [
    brand === Brand.TOYOTA.toLocaleLowerCase() ? toyota : lexus,
    brand === Brand.TOYOTA.toLocaleLowerCase() ? lexus : toyota,
    { name: 'Scion', code1: 'T', code3: 'TOY' },
    { name: 'Other', code1: 'O', code3: 'OTH' }
  ];
};

/**
 * Extracts the years list from the given model year and series object
 *
 * @param modelYearSeries input model year and series
 */
export const getModelYears = (modelYearSeries: VehicleModelYearList): number[] => {
  const years = getIntegerKeys(modelYearSeries);
  return sort(years, 'DESC');
};

/**
 * Extracts the object keys as number array
 *
 * @param object the input object
 * @param addOnlyNumber flag to exclude if a key is not a proper number
 *        Default is true
 * @param roundOff flag to add only integers
 *        Default is true
 */
export const getIntegerKeys = (object: any, addOnlyNumber: boolean = true, roundOff: boolean = true): number[] => {
  const keys = [];
  if (!object) {
    return keys;
  }
  Object.keys(object).forEach(key => {
    const parsedNumber = roundOff ? parseInt(key, 10) : parseFloat(key);
    if (!isNaN(parsedNumber) && addOnlyNumber) {
      keys.push(parsedNumber);
    } else if (!addOnlyNumber) {
      keys.push(key);
    }
  });
  return keys;
};

/**
 * Sorting an array
 *
 * @param array input array
 * @param order order of sorting.
 *        - ASC - Ascending
 *        - DESC - Descending
 *        - Default is ASC
 */
export const sort = (array: any[], order: 'ASC' | 'DESC' = 'ASC') => {
  return array.sort((a, b) => {
    if (order === 'ASC') {
      return a - b;
    } else {
      return b - a;
    }
  });
};

export const inDealerSite = () => {
  return (window as any).ASPHub?.isDealerSite !== undefined ? (window as any).ASPHub?.isDealerSite : true;
};

export const groupBy = (items: any[], key: string): { [key: string]: any[] } => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );
};

export const toFixed = (value: number, precision: number) => {
  const power = Math.pow(10, precision || 0);
  return (Math.round(value * power) / power);
};

export const toUsDollar = ((input: number) => {
  currentLanguageLocale = currentLanguageLocale ? currentLanguageLocale : 'en-US';
  currentCurrency = currentCurrency ? currentCurrency : 'USD';
  if (countryCd === 'MX') {
    return 'MX' + new Intl.NumberFormat(currentLanguageLocale, { style: 'currency', currency: currentCurrency }).format(input);
  }
  return new Intl.NumberFormat(currentLanguageLocale, { style: 'currency', currency: currentCurrency }).format(input);
})


export const getVinDisplayText = (vehicle: IVehicle) => {
  return `VIN ${vehicle.vin || 'Not available'}`;
};

export const getCarImageAbsoluteURL = (imageUrl: string) => {
  let url = imageBaseURL + (imageUrl || 'toyota-owners-theme/_assets/vehicles/Sedan_Generic/Sedan_Generic_base_default_34_f_d_490x280.png');
  if(imageUrl && imageUrl.startsWith('/',0)){
    url = imageBaseURL + `${imageUrl.substring(1)}`;
   } else if(imageUrl && imageUrl.includes('https')){
    url = imageUrl;
   }
return url;
};

export const titleCase = (str) => {
  return str?.toString().charAt(0)?.toUpperCase() + str?.toString()?.substr(1)?.toLowerCase();
}

export const getMakeModelYearText = (vehicle: IVehicle) => {
  return (vehicle?.model ? `${titleCase(vehicle?.make)} ${(vehicle?.model)} ${vehicle?.year}` : '');

};

export const unsubscribeSubscriptions = (subscriptions: SubscriptionList) => {
  for (const subs of Object.keys(subscriptions)) {
    if(subscriptions[subs] || Object.keys(subs).length>0) {
      subscriptions[subs]?.unsubscribe();
    }
  }
};

export const deepCopy = (object: any) => JSON.parse(JSON.stringify(object));

export function setCountryCd(countryCode) {
  countryCd = countryCode && countryCode !== 'USA' ? 'MX' : 'US';
}
export function upsert(array, item, predicate = temp => temp.id === item.id) {
  const i = array.findIndex(predicate);
  if (i > -1) {
    array[i] = item;
  }
  else {
    array.push(item);
  }
  return array;
}
export function getOpcodeDisplayPricing(serviceOpcode: IServiceOpcode) {
  return `${getOpcodePricingText(serviceOpcode)} ${getOpcodePricing(serviceOpcode)}`;
}
export function getOpcodePricing(serviceOpcode: IServiceOpcode) {
  switch (serviceOpcode?.pricing?.pricingType) {
    case PricingType.FIXED:
      return toUsDollar(serviceOpcode.pricing[PricingType.FIXED]);
    case PricingType.STARTS_AT:
      return toUsDollar(serviceOpcode.pricing[PricingType.STARTS_AT]);
    case PricingType.RANGE:
      return `${toUsDollar(serviceOpcode.pricing[PricingType.RANGE].from)} - ${toUsDollar(serviceOpcode.pricing[PricingType.RANGE].to)}`;
    default:
      return 0;
  }
}

export function getOpcodePricingText(serviceOpcode: IServiceOpcode) {
  switch (serviceOpcode?.pricing?.pricingType) {
    case PricingType.FIXED:
      return '';
    case PricingType.STARTS_AT:
      return 'Starts at';
    case PricingType.RANGE:
      return `Ranges from`;
    default:
      return '';
  }
}

export const GLOBAL_CONSTANT = {
  appointmentDateFormat: 'YYYY-MM-DDTHH:mm:ss.000',
  apptScheduledInfoFormat: 'MMM DD [at] hh:mm a',
  telematicsDisclaimerText: `dashboardAppointments.telematicsDisclaimerText`,
  schedulingDisclaimerText: 'testDrive.privacyPolicyTxt',
  placeholderCarImage: '/toyota-owners-theme/_assets/vehicles/Sedan_Generic/Sedan_Generic_base_default_34_f_d_490x280.png',
  dcsConfig: {
    timeout: 10000,
    retryCount: 3
  },
  defaultLanguage: 'en',
  errorMessage: 'dashboardAppointments.errorMessage'
};

export function getRouteFromWindow(routeName: string) {
  return (window as any).ASPHub?.pageRoutes ? (window as any).ASPHub?.pageRoutes[routeName] : undefined;
}

function buildParams(params?: Params) {
  if (params) {
    params = { queryParams: { ...params.queryParams, dealerCd: dealerCd, languageCd } };
  }
  else {
    params = {
      queryParams: { dealerCd: dealerCd, languageCd }
    }
  }
  return params;
}

export function navigateToRoutePromise(absolutePath: string, router: Router, navigationExtras?: NavigationExtras) {
  let parameters = buildParams(navigationExtras);
  if(navigationExtras?.state?.appointmentId) {
    parameters = {...parameters, state: navigationExtras.state};
  }
  return router.navigate((absolutePath ? [absolutePath] : []), parameters);
}

export function navigateToRoute(dealerRouteName: string, absolutePath: string, router: Router, params?: Params) {
  const parameters = buildParams(params);
  if ((window as any).ASPHub?.pageRoutes
    && (window as any).ASPHub?.pageRoutes.baseRoute !== undefined
    && (window as any).ASPHub?.pageRoutes[dealerRouteName] !== undefined) {
    const tree = router.createUrlTree([`${(window as any).ASPHub?.pageRoutes.baseRoute + (window as any).ASPHub?.pageRoutes[dealerRouteName]}`], parameters);
    const serializedPath = router.serializeUrl(tree);
    window.location.href = `${window.location.origin}${serializedPath}`;
  }
  else {
    router.navigate([absolutePath], parameters);
  }

}

declare let FontFace: any;

export function loadFonts() {
  const ToyotaType = new FontFace('ToyotaType',
    `url("${assetUrl('fonts/Toyota/ToyotaType-Regular.otf')}") format("opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Regular.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Regular.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Regular.woff')}") format("woff"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Regular.woff2')}") format("woff")`);
  ToyotaType.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const toyotaTypeBold = new FontFace('ToyotaType-Bold',
    `url("${assetUrl('fonts/Toyota/ToyotaType-Bold.otf')}") format("opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Bold.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Bold.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Bold.woff')}") format("woff"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Bold.woff2')}") format("woff")`);
  toyotaTypeBold.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const toyotaTypeBoldIt = new FontFace('ToyotaType-BoldIt',
    `url("${assetUrl('fonts/Toyota/ToyotaType-BoldIt.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-BoldIt.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-BoldIt.woff')}") format("woff"),
      url("${assetUrl('fonts/Toyota/ToyotaType-BoldIt.woff2')}") format("woff")`);
  toyotaTypeBoldIt.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const toyotaTypeBook = new FontFace('ToyotaType-Book',
    `url("${assetUrl('fonts/Toyota/ToyotaType-Book.otf')}") format("opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Book.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Book.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Book.woff')}") format("woff"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Book.woff2')}") format("woff")`);
  toyotaTypeBook.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });


  const toyotaTypeLight = new FontFace('ToyotaType-Light',
    `url("${assetUrl('fonts/Toyota/ToyotaType-Light.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Light.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Light.woff')}") format("woff"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Light.woff2')}") format("woff")`);
  toyotaTypeLight.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const ToyotaTypeSemiBold = new FontFace('ToyotaType-Semibold',
    `url("${assetUrl('fonts/Toyota/ToyotaType-Semibold.otf')}") format("opentype"),
  url("${assetUrl('fonts/Toyota/ToyotaType-Semibold.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Toyota/ToyotaType-Semibold.ttf')}") format("truetype"),
          url("${assetUrl('fonts/Toyota/ToyotaType-Semibold.woff')}") format("woff"),
              url("${assetUrl('fonts/Toyota/ToyotaType-Semibold.woff2')}") format("woff")`);
  ToyotaTypeSemiBold.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const nobelBold = new FontFace('Nobel-Bold',
    `url("${assetUrl('fonts/Lexus/nobel-bold.woff')}") format("woff")`);
  nobelBold.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const nobelBook = new FontFace('Nobel-Book',
    `url("${assetUrl('fonts/Lexus/nobel-book.woff')}") format("woff")`);
  nobelBook.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const nobelLight = new FontFace('Nobel-Light',
    `url("${assetUrl('fonts/Lexus/nobel-light.woff')}") format("woff")`);
  nobelLight.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const robotoBold = new FontFace('Roboto-Bold',
    `url("${assetUrl('fonts/Roboto/Roboto-Bold.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Roboto/Roboto-Bold.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Roboto/Roboto-Bold.woff')}") format("woff"),
      url("${assetUrl('fonts/Roboto/Roboto-Bold.woff2')}") format("woff")`);
  robotoBold.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const robotoLight = new FontFace('Roboto-Light',
    `url("${assetUrl('fonts/Roboto/Roboto-Light.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Roboto/Roboto-Light.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Roboto/Roboto-Light.woff')}") format("woff"),
      url("${assetUrl('fonts/Roboto/Roboto-Light.woff2')}") format("woff")`);
  robotoLight.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const robotoMedium = new FontFace('Roboto-Medium',
    `url("${assetUrl('fonts/Roboto/Roboto-Medium.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Roboto/Roboto-Medium.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Roboto/Roboto-Medium.woff')}") format("woff"),
      url("${assetUrl('fonts/Roboto/Roboto-Medium.woff2')}") format("woff")`);
  robotoMedium.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const robotoRegular = new FontFace('Roboto-Regular',
    `url("${assetUrl('fonts/Roboto/Roboto-Regular.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Roboto/Roboto-Regular.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Roboto/Roboto-Regular.woff')}") format("woff"),
      url("${assetUrl('fonts/Roboto/Roboto-Regular.woff2')}") format("woff")`);
  robotoRegular.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });

  const robotoThin = new FontFace('Roboto-Thin',
    `url("${assetUrl('fonts/Roboto/Roboto-Thin.eot')}") format("embedded-opentype"),
      url("${assetUrl('fonts/Roboto/Roboto-Thin.ttf')}") format("truetype"),
      url("${assetUrl('fonts/Roboto/Roboto-Thin.woff')}") format("woff"),
      url("${assetUrl('fonts/Roboto/Roboto-Thin.woff2')}") format("woff")`);
  robotoThin.load().then((font) => {
    // tslint:disable-next-line:no-string-literal
    document['fonts'].add(font);
  }).catch((error) => {
    console.log(error);
  });
}

export let environment = environmentConst.LOCAL;

export function loadEnvironmentVariables(window: any) {
  if (window.location.hostname.includes('dev')) {
    environment = environmentConst.DEV;
  } else if (window.location.hostname.includes('test')) {
    environment = environmentConst.TEST;
  } else if (window.location.hostname.includes('qa')) {
    environment = environmentConst.QA;
  } else if (window.location.hostname.includes('stage')) {
    environment = environmentConst.STAGE;
  } else if (window.location.hostname.includes('localhost')) {
    environment = environmentConst.LOCAL;
  } else {
    environment = environmentConst.PROD;
  }
  setBrand(window);
}

function setBrand(window: any) {
  dealerCd = getParameterByName('dealerCd') || window.ASPHub?.dealerCd;
  languageCd = getParameterByName('languageCd') || window.ASPHub?.languageCd;
  if (!languageCd) {
    //if no languageCd provided assign language code to default english
    languageCd = "en";
    const params = new URLSearchParams(window.location.search);
    buildParams(params);
  }
  // If Dealer Code starts with 6, it is a Lexus dealer
  if (dealerCd && dealerCd.startsWith('6')) {
    environment.brand = Brand.LEXUS.toLocaleLowerCase();
    environment.registerUrl = environment.lexusRegisterUrl;
    environment.xBrand = 'L';
    environment.xClient = 'LD-WEB';
  }
  // Else Toyota dealer
  else {
    environment.brand = Brand.TOYOTA.toLocaleLowerCase();
    environment.registerUrl = environment.toyotaRegisterUrl;
    environment.xBrand = 'T';
    environment.xClient = 'TO-WEB';
  }
}

function getParameterByName(name, url = window.location.href) {
  name = name?.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2]?.replace(/\+/g, ' '));
}

export const stringContains = (regExp: string, testString: string): boolean => {
  // @ts-ignore
  const regex = new RegExp(regExp);
  return regex.test(testString);
}

export const scionModelList = ["FR-S", "iA", "iM", "iQ", "iQ EV", "tC", "xA", "xB", "xD"]

export function convertMinsToHourMins(duration: number, isRoundedValue = false) {
  if(duration > 0) {
    const hours = Math.floor(duration / 60);
    const mins = isRoundedValue ? Math.round(duration % 60) : (duration % 60);
    const hr = hours > 0 ? `${hours}hr` : '';
    const min = mins > 0 ? `${mins}min` : '';
    return `${hr} ${min}`;
  }
  else {
    return '';
  }
}
