import { ActivatedRoute } from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { ISettingsResponse } from './../../models/settings/settings.model';
import {AppointmentBookingSource, AppointmentType, IAvailability, IAvailabilityOptions, IDealerInfo, IResponse} from '@signal/asp-data-commons';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import { StateDetails } from './../../models/state';
import { dealerCd, environment } from '../util.service';


@Injectable({
  providedIn: 'root'
})
export class DealerAdminService{
  private readonly dealerCode: string;
  private readonly aspB2cGuestApi: string;

  constructor(private readonly http: HttpClient, private readonly route: ActivatedRoute) {
    this.dealerCode = dealerCd || (window as any).ASPHub?.dealerCd;
    this.aspB2cGuestApi = environment.aspB2cGuestApi;
  }

  getStates(): Observable<IResponse<StateDetails[]>> {
    return this.http.get(`${this.aspB2cGuestApi}catalog/${this.dealerCode}/states`).pipe(
      map((data: IResponse<StateDetails[]>) => {
          return data;
        }
      )
    );
  }

  getCities(): Observable<IResponse< { [key: string]: string[]}> > {
    return this.http.get(`${this.aspB2cGuestApi}catalog/${this.dealerCode}/cities`).pipe(
      map((data: IResponse<{ [key: string]: string[]}>) => {
          return data;
        }
      )
    );
  }

  getSettings(): Observable<IResponse<IDealerInfo>> {
    return this.http.get(`${this.aspB2cGuestApi}generalsettings/getdealersettings?dealerCode=${this.dealerCode}`).pipe(
      map((data: IResponse<IDealerInfo>) => {
          return data;
        }
      )
    );
  }

  getGeneralSettings(department: ('service'| 'sales' | string)): Observable<ISettingsResponse> {
    return this.http.get(`${this.aspB2cGuestApi}generalsettings/getdealersettings?dealerCode=${this.dealerCode}&department=${department}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getAvailabilityOptions(department: 'service'| 'sales', appointmentType:string = AppointmentType.SERVICE): Observable<IResponse<IAvailabilityOptions>>{
    let params = new HttpParams();
    if(department === 'sales') {
      const apptTypeOBJ = {
            [AppointmentType.SERVICE]: "service",
            [AppointmentType.TEST_DRIVE]:"testdrive",
            [AppointmentType.DELIVERY]:"delivery",
            [AppointmentType.LEARNING]:"learning"
      }
      params = params.append('appointmentType', apptTypeOBJ[appointmentType]);
    }
    return this.http.get(`${(this.aspB2cGuestApi)}availability/${this.dealerCode}/${department}/options`, {params: params}).pipe(
      map((data: IResponse<IAvailabilityOptions>) => {
          return data;
        }
      )
    );
  }


  getAvailability(department: 'service'| 'sales', 
                            by: {date: string, advisorId: string, transport: string,enddt?:string,vin? :string, appointmentId?:string, appointmentType?: string, checklistDuration?: {min: number} },
                            opcodes: string[] = undefined, recallCodes: string[] = undefined): Observable<IResponse<IAvailability>>{
    const body = {
      transport: by.transport, 
      advisorId: by.advisorId,
      vin : by.vin ? by.vin.split(',') : '',
      appointmentId : by.appointmentId ? by.appointmentId : '',
      appointmentType: by.appointmentType
    };
    if(!by.vin){
      delete body.vin;
      delete body.appointmentId;
    }
    if(by.vin && !(by.appointmentId)){
      delete body.appointmentId;
    }
    if(department === 'service'){
      body["opcodes"] = opcodes;
      if (recallCodes) {
        body["recallCodes"] = recallCodes;
      }
    }
    body["appointmentBookingSource"] = AppointmentBookingSource.ASP;
    if(by.appointmentType === AppointmentType.DELIVERY) {
      body["checklistDuration"] = by.checklistDuration;
    }
    let param;
    if(by.enddt)
    {
      param={startDate : by.date,endDate:by.enddt}
    }
    else{
      param={startDate : by.date}
    }

    return this.http.post(
      //revert this dealerCode hardcode change later
        `${this.aspB2cGuestApi}availability/${this.dealerCode}/${department}`, 
        body,
        {params: param}
      ).pipe(
      map((data: IResponse<IAvailability>) => {
        data.data.minDate = DateTime.fromISO(data.data.minDate).minus({ month: 3 }).toFormat('MM-dd-yyyy');
        data.data.maxDate = DateTime.fromISO(data.data.maxDate).plus({ month: 3 }).toFormat('MM-dd-yyyy');
          return data;
        }
      )
    );
  }
}
