<div class='appt-time-loader' *ngIf="(availabilityOptionsLoading$ | async)">
  <app-loading size=".5"></app-loading>
</div>
<div class='appointment-container' *ngIf="!(availabilityOptionsLoading$ | async)">
  <form [formGroup]='step' appConnectStore="availabilityForm">
    <div class='container-header'>
      <div class='choose'>{{'transportation.chooseYourAppointment'| translate}}</div>
      <div class='choose-desc'>{{'transportation.pleaseChoosePreferredTransportationLanguageAdvisorAndApptDateTime'| translate}}
      </div>
    </div>

    <app-splitter class='app-splitter'  [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

    <section class='transport-container'>
      <section class='availability-header'>
        {{'transportation.location'| translate}}
      </section>
      <app-toggle-location class='app-toggle-location'
        formControlName="transport"
        (click)="checkZipCodes(step.value.remoteZipCode)"
        [allTransportOptions]="transportOptions$ | async"
        [department]="department">
      </app-toggle-location>
      <ng-container *ngIf="(step.value.transport === 'REMOTE' || step.value.transport === 'PICKUP' || step.value.transport === 'DELIVERY')">
        <div>{{'transportation.pleaseEnterYourZipcodeToCheckService'| translate}}</div>
        <div class="zip" [ngClass]="{'error' : !isValidRemoteZip}">
          <mat-form-field appearance="outline">
            <mat-label>{{'transportation.zipcode'| translate}}</mat-label>
            <input matInput required  #zip [placeholder]="'transportation.zipcode'| translate" formControlName="remoteZipCode" (input)="checkZipCodes(zip.value)" (blur)='zipTracker($event)'/>
            <mat-error>{{'transportation.sorryRemoteServicesAreNotAvailableOnTheEnteredZipcode'| translate}}</mat-error>
          </mat-form-field>
          <div class = "tick" [ngClass]="{'selected' : isValidRemoteZip}" [title]="isValidRemoteZip ? ('transportation.weServiceAtYourLocation' | translate) : ''"></div>
        </div>
      </ng-container>
    </section>

    <app-splitter class='app-splitter'  [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

    <div class='availability'>
      <section class='availability-header'>
        {{'transportation.appointmentAvailability'| translate}}
      </section>
      <app-availability *ngIf="languageId!=='' && isEditMode; else noEdit" class='app-availability'
                        formControlName='availability'
                        [options]="availabilityOptions$ | async"
                        [isAvailabilityLoading]="availabilityLoading$ | async"
                        [availability]="availability$ | async"
                        [selectedTransport]="step?.controls?.transport?.value"
                        (monthChanged)="onMonthChange($event)"
                        (timeSlotValidator)="setValidatorsForTimeSlot($event)"
                        [customerLanguage]="languageId"
                        [isEditMode]="isEditMode"
                        [apptAdvisor]="apptAdvisor"
                        [apptType]="apptType">
      </app-availability>
      <ng-template #noEdit>
        <app-availability class='app-availability'
                        formControlName='availability'
                        [options]="availabilityOptions$ | async"
                        [isAvailabilityLoading]="availabilityLoading$ | async"
                        [availability]="availability$ | async"
                        [selectedTransport]="step?.controls?.transport?.value"
                        (monthChanged)="onMonthChange($event)"
                        (timeSlotValidator)="setValidatorsForTimeSlot($event)"
                        [apptType]="apptType">
        </app-availability>
      </ng-template>
    </div>
  </form>
</div>
