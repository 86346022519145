import {IAvailability, IAvailabilityOptions, IResponse, LocationType} from '@signal/asp-data-commons';
import {IAvailabilityForm} from './service-appointment/service-appointment.reducer';
import {Observable, of, throwError} from 'rxjs';
import {GLOBAL_CONSTANT, countryCd} from '../shared/services/util.service';

/* Projectors */
export function toData<T>(response: IResponse<T>, successCode: number = 200): Observable<T> {
  if (response.status.code === successCode) {
    return of(response.data);
  } else {
    return throwError(`${response.status.code} - ${response.status.messages.join(', ')}`);
  }
}

export function fromAvailabilityOptions(availabilityOptions: IAvailabilityOptions,transportationTypeCode?:string): IAvailabilityForm {
  let transport;
  transport = availabilityOptions.transport.transportOptions
    .filter(t => t.isActive && t.category === LocationType.DEALER)[0]?.id;
  if(transportationTypeCode?.length){
      transport = availabilityOptions.transport.transportOptions
      .filter(t => t.isActive && t.id === transportationTypeCode)[0]?.id;
    }

  const languageId = (countryCd==='MX')? 'es': 'en';
  const advisorId = '-1';
  return {
    transport,
    remoteZipCode : '',
    availability: {
      languageId,
      advisorId,
      appointmentStartDate: '',
      timeSlotId: ''
    }
  };
}

export function getDateTime(availability: IAvailability) {
  let appointmentStartDate = '';
  let timeSlotId = '';

  if (availability.suggestedTimes.length) {
    appointmentStartDate = availability.suggestedTimes[0].date;
    timeSlotId = availability.suggestedTimes[0].timeSlots[0].timeSlotId;
  }

  return {
    appointmentStartDate,
    timeSlotId,
  };
}
