import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AppointmentType, Department, IAvailability, IAvailabilityOptions, IEmployee, ILanguage, ITimeSlot, TransportationType } from '@signal/asp-data-commons';
import { SubscriptionList } from '../../models/asp.types';
import { assetUrl, unsubscribeSubscriptions } from '../../services/util.service';
import { ServiceAppointmentState } from '../../../store/service-appointment/service-appointment.reducer';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { LoadAvailability } from '../../../store/service-appointment/service-appointment.actions';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import * as fromServiceSelectors from '../../../store/service-appointment/service-appointment.selectors';
import * as fromSalesSelectors from '../../../store/sales-appointment/sales-appointment.selectors';
import { IAvail, SalesAppointmentState } from '../../../store/sales-appointment/sales-appointment.reducer';
import * as fromActions from '../../../store/sales-appointment/sales-appointment.actions';
import _ from 'lodash';
import { languages } from '../../../../../test/data/languages';

export interface AvailabilityFormValues {
  advisorId: string;
  languageId: string;
  timeSlotId: string;
  appointmentStartDate: string;
}

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvailabilityFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AvailabilityFormComponent),
      multi: true
    }
  ]
})

export class AvailabilityFormComponent implements ControlValueAccessor, OnDestroy, OnInit, OnChanges {

  @Input() options: IAvailabilityOptions;
  @Input() availability: IAvailability;
  @Input() isAvailabilityLoading: boolean;
  @Input() selectedTransport: string;
  @Output() monthChanged: EventEmitter<DateTime> = new EventEmitter();
  @Output() timeSlotValidator: EventEmitter<boolean> = new EventEmitter();
  @Input() vin:string;
  @Input() appointmentId:string;
  @Input() source = '';
  @Input() apptType: AppointmentType;
  @Input() checklistDuration: { hr: number; min: number; };
  @Input() lockSalesConsultant: string;
  @Input() hideSalesConsultant=false;

  form: FormGroup;
  subscriptions: SubscriptionList = {};
  availableDates: string[] = [];
  isTimeSlotDisabled: boolean;
  currentMonth: DateTime;

  advisors: IEmployee[] = [];
  timeSlots: ITimeSlot[] = [];
  selected: string = '';
  dealerAdvisors: IEmployee[] = [];
  currTransportOption:string;

  oldValue: any = {};
  exludedForTransportCheck = [TransportationType.WAITER, TransportationType.REMOTE];
  languages:ILanguage[]=[];
  checkIfLanguageAvailalble:any;
  @Input() isEditMode:boolean;
  @Input() customerLanguage='';
  @Input() apptAdvisor = '';
  apptLanguage = '';

  get value(): AvailabilityFormValues {
    return this.form?.value;
  }

  set value(value: AvailabilityFormValues) {
    this.form.setValue(value);
    this.selected = value.advisorId;
    this.onChange(value);
    this.onTouched();
  }

  constructor(private readonly formBuilder: FormBuilder,
    private readonly serviceAppointmentStore: Store<ServiceAppointmentState>,
    private readonly analyticsService : AnalyticsService,
    private readonly translate: TranslateService,
    private readonly salesAppointmentStore: Store<SalesAppointmentState>,
    ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      timeSlotId: ['', [Validators.required]],
      advisorId: ['', [Validators.required]],
      languageId: ['', [Validators.required]],
      appointmentStartDate: ['', [Validators.required]]
    });

    this.advisors = this.options.advisors;
    const data$ = this.serviceAppointmentStore.select(fromServiceSelectors.selectAppointmentForm).subscribe((data:any) => {
      const selectedServices = data.serviceForm.selectedServices || [];
      if(selectedServices && selectedServices.length){
        const txmOpcodes = selectedServices.filter(service => service.details.isTXM)
        const languageId = data.availabilityForm?.availability?.languageId;
        if(txmOpcodes && txmOpcodes.length && txmOpcodes.length === selectedServices.length){
          this.advisors = (this.isEditMode && (this.languages.length > this.options?.languages?.length) && languageId === this.apptLanguage) ? this.options?.advisors : this.options.advisors.filter(a => (a.isTxm || a.id === '-1') && (a.languages && a.languages.some(l => l.languageId === languageId)))
        }else{
          this.advisors = (this.isEditMode && (this.languages.length > this.options?.languages?.length) && languageId === this.apptLanguage) ? this.options?.advisors : this.options.advisors.filter(a => a.languages && a.languages.some(l => l.languageId === languageId));
        }
      }
    });
    this.checkIfLanguageAssigned();
    this.setFormSubscription();
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  getAssetUrl(event) {
    event.target.onerror = null;
    event.target.src = assetUrl('icons/svg/unknown.svg');
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    this.availableDates = this.availability.availableDates?.filter(a => a.isOpen).map(a => a.date);
  }

  /* check if language coming from appt is assinged to the consultant or add otherwise if its an edit mode*/
  checkIfLanguageAssigned()
  {
    if(this.customerLanguage!=='' && this.languages.length && this.isEditMode)
    {
      this.apptLanguage = this.customerLanguage;
      const checkIfLanguageAvailalble = this.languages?.find(l => l.languageId === this.customerLanguage);
      if (!checkIfLanguageAvailalble) {
        const language = languages.find(l => l.languageId === this.customerLanguage).language;
        this.languages.push({ languageId: this.customerLanguage, language });
      }
      else if(checkIfLanguageAvailalble && this.apptAdvisor !== '' && this.options?.advisors?.length) {
        const isAdvisorLanguageAvailable = this.options?.advisors?.find(x=> x.id === this.apptAdvisor && x.languages.some(l=> l.languageId === this.customerLanguage ) );
        if(!isAdvisorLanguageAvailable) {
        const language = languages?.find(l => l.languageId === this.customerLanguage)?.language;
        const advisor =  this.options.advisors?.find(x=> x.id === this.apptAdvisor);
        const advisorsExAppt = this.options.advisors?.filter(x=> x.id !== this.apptAdvisor) || [];
        this.options =  {...this.options, advisors: [...advisorsExAppt, {...advisor, languages: [...(advisor?.languages || []), { languageId: this.customerLanguage, language }]}]}
        }
      }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { availability: { valid: false } };
  }

  onSuggestedTimesSelected(date: string, timeSlotId: string) {
    const suggestedTimeLinkTrackingData = {
      content_section : 'appointment tab',
      link_module : 'appointment availability',
      link_text : 'suggested time selected',
      link_input_field : `${date} , ${timeSlotId}`
    }
    this.analyticsService.trackLink(suggestedTimeLinkTrackingData,true);
    this.form.get('appointmentStartDate').patchValue(date);
    this.form.get('timeSlotId').patchValue(timeSlotId);
    this.timeSlotValidator.emit(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty(('availability'))) {
      if(this.apptType !== AppointmentType.SERVICE){
        this.handleOnchangeAvailabilitySales()
      }
      this.availableDates = changes.availability.currentValue.availableDates?.filter(a => a.isOpen).map(a => a.date);
      if (this.form) {
        this.timeSlots = this.availability.availableDates
          ?.find(a => a.date === this.form.value.appointmentStartDate)
          ?.timeSlots;
        this.setDisabledTimeSlot();
      }
    }

    if (changes.hasOwnProperty('options')) {
      this.advisors = this.options.advisors;
      if(this.apptType !== AppointmentType.SERVICE){
        this.handleOnchangeOptionsSales()
      }
    }

    if (changes.hasOwnProperty('selectedTransport')) {
      this.currTransportOption = changes.selectedTransport.currentValue
      if(this.apptType !== AppointmentType.SERVICE){
        this.handleOnchangeTransportSales()
      }
      this.checkAvailabilityByTransport();
    }

    /* we need to populate options.languages in the languages array for the first
      time only */
    if (changes?.options?.currentValue?.languages?.length && !this.languages.length) {
      this.languages = [...changes.options.currentValue.languages] || [];
      this.checkIfLanguageAssigned();
    }
  }

  handleOnchangeAvailabilitySales(){
    this.salesAppointmentStore.select(fromSalesSelectors.appointmentDetails).subscribe(appt =>{
      const languageId:any = appt.availability.languageId
      if(appt.transportationTypeCode === TransportationType.REMOTE){
        this.advisors = this.options.advisors?.filter(data => (data?.remoteConsultant || data?.id === '-1'))?.filter(a => a.languages && a.languages.some(l => l.languageId === languageId))
      }else{
        this.advisors = this.options.advisors?.filter(a => a.languages && a.languages.some(l => l.languageId === languageId))
      }
    })
  }

  handleOnchangeOptionsSales(){
    this.dealerAdvisors = this.advisors
      this.salesAppointmentStore.select(fromSalesSelectors.appointmentDetails).subscribe(data =>{
        if(data.transportationTypeCode === TransportationType.REMOTE){
          this.advisors = this.advisors?.filter(data => data?.remoteConsultant || data?.id === '-1')
        }
      })
  }

  handleOnchangeTransportSales(){
    if(this.currTransportOption === TransportationType.REMOTE){
      this.advisors = this.advisors?.filter(data => data?.remoteConsultant || data?.id === '-1')
    }else{
      this.advisors = this.dealerAdvisors
    }
  }

  private checkAvailabilityByTransport() {
    if (this.options.department === Department.SERVICE
      && this.selectedTransport
    ) {
      const formDate = this.form?.value?.appointmentStartDate;
      const advisorId = this.form?.value?.advisorId;
      const vin = this.vin;
      const appointmentId = this.appointmentId;
      if (formDate && advisorId) {
        const date = DateTime.fromISO(formDate)
          .startOf('month')
          .toFormat('yyyy-MM-dd');
        this.serviceAppointmentStore.dispatch(new LoadAvailability(
          { date, advisorId, transport: this.selectedTransport,vin,appointmentId  }));
      } else {
        this.serviceAppointmentStore.dispatch(new LoadAvailability(
          { date: "", advisorId: '-1', transport: this.selectedTransport,vin,appointmentId  }));
      }
    }
  }
  languageChanged(language){
    const languageChangeTrackingData = {
      content_section : 'appointment tab',
      link_module: 'appointment availability',
      link_text : 'language changed',
      link_input_field : language.value
    };
    this.analyticsService.trackLink(languageChangeTrackingData,true);
  }

  advisorChanged(advisor){
    const advisorChangeTrackingData = {
      content_section : 'appointment tab',
      link_module: 'appointment availability',
      link_text : 'service advisor changed',
      link_input_field : advisor.value
    };
    this.analyticsService.trackLink(advisorChangeTrackingData,true);
  }

  private setFormSubscription() {
    this.subscriptions.formSub = this.form.valueChanges.subscribe(value => {
      if(_.isEqual(this.oldValue, value)) {
        return;
      }
      // any time the inner form changes update the parent of any change
      this.onChange(value);
      this.onTouched();
      if (this.oldValue.languageId !== value.languageId) {
        /*if its an edit mode and appt language is not assigned to the consultant, populate consultants as it is without filtering */
        this.advisors = (this.isEditMode && this.languages.length > this.options?.languages?.length && value.languageId === this.apptLanguage) ? this.options?.advisors :this.options.advisors?.filter(a => a.languages && a.languages.some(l => l.languageId === value.languageId));
        if(this.apptType !== AppointmentType.SERVICE){
          this.salesAppointmentStore.select(fromSalesSelectors.appointmentDetails).subscribe(data =>{
            if(data.transportationTypeCode === TransportationType.REMOTE){
              this.advisors = this.advisors?.filter(data => data?.remoteConsultant || data?.id === '-1')
            }
          })
        }
         if(this.apptType === AppointmentType.SERVICE && this.advisors?.length && !(this.isEditMode && this.languages.length > this.options?.languages?.length && value.languageId === this.apptLanguage)) {
          this.updateAdvisorOnLanguageChange(this.advisors, value);
        }
      }

      if (this.oldValue.appointmentStartDate !== value.appointmentStartDate) {
        this.timeSlots = this.availability.availableDates
          ?.find(a => a.date === value.appointmentStartDate)
          ?.timeSlots;
          this.setDisabledTimeSlot();
      }

      //PHASE-2: This logic is to fetch advisor based availability
      if (this.options.department === Department.SERVICE
        && this.oldValue.advisorId !== value.advisorId) {
          const formDate = this.currentMonth ? this.currentMonth : DateTime.now();
          const date = DateTime.fromISO(formDate.toString())
          .startOf('month')
          .toFormat('yyyy-MM-dd');
          this.serviceAppointmentStore.dispatch(new LoadAvailability({ date: date, advisorId: value.advisorId || -1, transport:this.selectedTransport }));
      }
      else if (this.options.department === Department.SALES
        && this.oldValue.advisorId !== value.advisorId && value.advisorId) {
        const formDate = this.currentMonth ? this.currentMonth : DateTime.now();
        const date = DateTime.fromISO(formDate.toString())
          .startOf('month')
          .toFormat('yyyy-MM-dd');
        if (this.apptType === AppointmentType.DELIVERY) {
          const isAvailabilityAllowed = this.apptType === AppointmentType.DELIVERY ? this.source !== 'sales' : true;
          let payload = {
            selectDateAndSlot: false,
            date: date, advisorId: value.advisorId || -1, transport: this.selectedTransport,
            vin: this.vin, appointmentType: this.apptType, checklistDuration: this.checklistDuration
          };
          payload['selectDateAndSlot'] = true;
          payload['checklistDuration'] = this.checklistDuration;
          if (this.selectedTransport && isAvailabilityAllowed) {
            this.salesAppointmentStore.dispatch(new fromActions.LoadAvailability(payload));
          };
        }
      }

      this.oldValue = value;
    });
  }

  onMonthChange($event: DateTime) {
    const monthChangeTrackingData = {
      content_section : 'appointment tab',
      link_module : 'appointment availability',
      link_text : 'month changed in calendar',
      link_input_field : $event.toFormat('MM')
    };
    this.analyticsService.trackLink(monthChangeTrackingData,true);
    this.currentMonth = $event;
    this.monthChanged.emit($event);
  }
  setDisabledTimeSlot() {
    const availableTimeSlotDates = this.availability.availableDates?.filter(data => data.isOpen);
    if (availableTimeSlotDates?.find(a => a.date === this.form.value.appointmentStartDate)?.timeSlots === undefined)
      {
        this.isTimeSlotDisabled = false;
      }
    else
      {
        this.isTimeSlotDisabled = true;
      }
      const timeslots = availableTimeSlotDates?.find(a => a.date === this.form.value.appointmentStartDate)?.timeSlots;
      if (timeslots?.length > 0 && timeslots?.find(time => time.timeSlotId === this.form.value.timeSlotId)?.isOpen) {
        this.timeSlotValidator.emit(!this.isTimeSlotDisabled);
      }
      else {
        this.timeSlotValidator.emit(true)
      }
    // this.timeSlotValidator.emit(!this.isTimeSlotDisabled);
  }

  getMonth(month){
    return month ? this.translate.instant(`common.${month}`) : month;
  }
  getLanguage(language){
    return language ? this.translate.instant(`common.${language}`) : language;
  }

  getTranslation(value){
    return value ? this.translate.instant(`common.${value}`) : value;
  }

  emitTimeSlotValidator(event: boolean) {
    this.timeSlotValidator.emit(event)
  }

  updateAdvisorOnLanguageChange(advisorsList, value: IAvail) {
    let advisorId = value.advisorId;
    const advisors = advisorsList
      ?.filter(a => a?.languages && a?.languages.length ? a?.languages?.some(l => l.languageId === value.languageId) : []);
    if (advisors && !advisors.find(x => x.id === advisorId)) {
      advisorId = '-1';
    }
    if(advisorId !== value.advisorId) {
    this.selected = advisorId;
    this.form.get('advisorId').patchValue(advisorId);
    }
  }
}
