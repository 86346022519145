<div class='container'>
  <form [formGroup]='testDriveForm'>
    <div class='testdrive-content'>
      <div *ngIf="!bookingFromDirectVin">
        <ng-container *ngIf="!(isUpdate || !!vinTestDrive) && withoutFleetVehicle" >
          <asp-vehicle-configurator   [ngClass]="{'disabled' : isUpdate}" formControlName="vehicle" [vehicleDetails]="vehicleDetails$ | async" 
          [isEditMode]="isUpdate || !!vinTestDrive"
          [withoutFleetVehicle] = "withoutFleetVehicle"
          [vin]="vinTestDrive">
          </asp-vehicle-configurator>
        </ng-container>
        <ng-container *ngIf="!(isUpdate || !!vinTestDrive) && !withoutFleetVehicle" >
          <asp-vehicle-configurator   [ngClass]="{'disabled' : isUpdate}" formControlName="vehicle" [vehicleDetails]="fullDataWithYearSchedule$ | async" 
          [isEditMode]="isUpdate || !!vinTestDrive"
          [withoutFleetVehicle] = "withoutFleetVehicle"
          [vin]="vinTestDrive">
          </asp-vehicle-configurator>
        </ng-container>
        <ng-container *ngIf="(isUpdate || !!vinTestDrive) && !withoutFleetVehicle" >
          <asp-vehicle-configurator formControlName="vehicle" [vehicleDetails]="fullDataWithYear" 
          [isEditMode]="isUpdate || !!vinTestDrive"
          [withoutFleetVehicle] = "withoutFleetVehicle"
          [appt] = "appointmentVehicle"
          [vin]="vinTestDrive">
          </asp-vehicle-configurator>
        </ng-container>
        <ng-container *ngIf="(isUpdate || !!vinTestDrive) && withoutFleetVehicle" >
          <app-vehicle-details   [ngClass]="{'disabled' : isUpdate  || !!vinTestDrive}" formControlName="vehicle" [vehicleDetails]="vehicleDetails$ | async" 
          [isEditMode]="isUpdate || !!vinTestDrive"
          [withoutFleetVehicle] = "withoutFleetVehicle"
          [vin]="vinTestDrive">
          </app-vehicle-details>
        </ng-container>
      </div>
      
      <!-- <app-selected-vehicle-info *ngIf="vehicleDetails?.vin" [vehicleDetails]="vehicleDetails"></app-selected-vehicle-info> -->

      <!-- <div class='other-vehicles'>
        <mat-expansion-panel #otherVehicle [disabled]='!otherVehicleChecked' [expanded]='otherVehicleChecked'>
          <mat-expansion-panel-header [ngClass]="{'show-cursor': !otherVehicleChecked}" >
            <mat-panel-title>
              <mat-checkbox disableRipple (change)="onOtherVehicleChecked()" [checked]="otherVehicleChecked">
              </mat-checkbox>
              <span class='checkbox-text'>{{ 'testDrive.areYouInterestedInAnyOtherVehicles' | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div formArrayName='otherVehicles' *ngIf="otherVehiclesArray?.length">
            <div class='dropdown' *ngFor="let other of otherVehiclesArray?.controls, let i = index" [formGroupName]="i">
              <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

              <mat-form-field appearance="outline">
                <mat-label>{{ 'testDrive.year' | translate}}</mat-label>
                <mat-select formControlName='year' [required]="otherVehicleChecked">
                  <mat-option *ngFor="let year of yearList$ | async" [value]="year">{{year}}</mat-option>
                </mat-select>
                <mat-error>{{ 'testDrive.pleaseSelectAValidYear' | translate}}</mat-error>
                <mat-hint *ngIf="!!other.value.year && !(modelList$[i] | async)?.length">{{ 'testDrive.noModelsForTheYear' | translate}}</mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline"
              [ngClass]="{'disabled' : !(modelList$[i] | async)?.length}">
                <mat-label>{{ 'testDrive.model' | translate}}</mat-label>
                <mat-select formControlName='model' [required]="otherVehicleChecked">
                  <mat-option *ngFor="let model of modelList$[i] | async" [value]="model.modelCode">{{model.modelTitle}}
                  </mat-option>
                </mat-select>
                <mat-error>{{ 'testDrive.pleaseSelectAValidModel' | translate}}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline"
              [ngClass]="{'disabled' : !(trimList$[i] | async)?.length}">
                <mat-label>{{ 'testDrive.trim' | translate}}</mat-label>
                <mat-select formControlName='trim' [required]="otherVehicleChecked">
                  <mat-option *ngFor="let trim of trimList$[i] | async" [value]="trim.trimCode">{{trim.trimTitle}}
                  </mat-option>
                </mat-select>
                <mat-error>{{ 'testDrive.pleaseSelectAValidTrim' | translate}}</mat-error>
              </mat-form-field>
              <app-icon class='app-icon' name='ic-20-utility-delete-trash.svg' (click)='onDeleteVehicle(i)'></app-icon>
            </div>
          </div>

          <div class='add-vehicle'  [ngClass]="{'disabled': otherVehiclesArray?.controls?.length >= 3}">
            <app-icon class='app-icon' name='element-btn-48-icon-primary.svg' (click)='onAddVehicle()'></app-icon>
            <span class='desc' (click)='onAddVehicle()'>{{ 'testDrive.addAnotherVehicle' | translate}}</span>
          </div>
        </mat-expansion-panel>
      </div> -->
      <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>

      <asp-trade-in-info formControlName="tradeIn"></asp-trade-in-info>

      <div class="availability">
        <div class='availability-header'>{{ 'transportation.appointmentAvailability' | translate}}</div>
        <div class="availability-content">
          <div *ngIf="isUpdate ? testDriveForm.value.transportationTypeCode : true">
            <app-toggle-location *ngIf="hasRemoteLocation" [ngClass]="{'app-toggle-location': hasRemoteLocation }"
              [allTransportOptions]="transportOptions$ | async" [formControlName]="'transportationTypeCode'"
              (click)="checkZipCodes(testDriveForm.value.remoteZipCode)" [department]="department">
            </app-toggle-location>
          </div>

          <ng-container *ngIf="testDriveForm.value.transportationTypeCode === 'REMOTE'">
            <div>{{ 'transportation.pleaseEnterYourZipcodeToCheckService' | translate }}</div>
            <div class="zip" [ngClass]="{'error' : !isValidRemoteZip}">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'transportation.zipcode' | translate }}</mat-label>
                <input matInput required  #zip placeholder="ZipCode" formControlName="remoteZipCode" (input)="checkZipCodes(zip.value)"/>
                <mat-error>{{ 'transportation.sorryRemoteServicesAreNotAvailableOnTheEnteredZipcode' | translate }}</mat-error>
              </mat-form-field>
              <div class = "tick" [ngClass]="{'selected' : isValidRemoteZip}"></div>
            </div>
          </ng-container>
          <app-availability *ngIf="isUpdate && languageId!=='';else noEdit" class='app-availability'
                        formControlName='availability'
                        [options]="availabilityOptions$ | async"
                        [isAvailabilityLoading]="availabilityLoading$ | async"
                        [availability]="availability$ | async"
                        [selectedTransport]="testDriveForm.value.transportationTypeCode"
                        (monthChanged)="onMonthChange($event)"
                        [isEditMode]="isUpdate" 
                        [customerLanguage]="languageId"
                        [hideSalesConsultant]="hideSalesConsultant">
                        </app-availability>
          <ng-template #noEdit>
            <app-availability class='app-availability'
                        formControlName='availability'
                        [options]="availabilityOptions$ | async"
                        [isAvailabilityLoading]="availabilityLoading$ | async"
                        [availability]="availability$ | async"
                        [selectedTransport]="testDriveForm.value.transportationTypeCode"
                        (monthChanged)="onMonthChange($event)"
                        [hideSalesConsultant]="hideSalesConsultant">
                        </app-availability>
          </ng-template>
        </div>
        <app-splitter class='app-splitter' [config]="'horizontal 100% 0 -4px 550px'"></app-splitter>
      </div>

      <app-contact-email-info formControlName="customer" [apptType]="apptType"></app-contact-email-info>
      <app-customer-info formControlName="address" [location]="testDriveForm.get('transportationTypeCode').value"
        [department]="'sales'"  [remoteValidZipcodes]="remoteValidZipcodes" ></app-customer-info>
      <div id="accept-terms">
            <span class="label">
              {{getDisclaimerText | translate}}
            </span>
      </div>
      <div class='appointment-submit'>
        <button class='submit' [class.submitting]="isSubmitting" (click)='onSubmit()'
          [ngClass]="isValidForm() ? 'enabled':'disable'" [disabled]="isSubmitting">
          {{(isSubmitting ? 'appointmentInformation.submitting' : 'appointmentInformation.submit') | translate}}
          <app-loading *ngIf="isSubmitting" loadingSymbol="dots" color="white" size=".6"></app-loading>
        </button>
      </div>
      <div class='appointment-details'>
        {{ "testDrive.yourAppointmentIsOn" | translate}} {{ getTranslation(testDriveForm.get('availability').value.appointmentStartDate | date : 'MMMM')}}
      {{testDriveForm.get('availability').value.appointmentStartDate | date : 'dd'}} {{'testDrive.aT' | translate }} {{testDriveForm.get('availability').value.timeSlotId }}
        {{ (testDriveForm.get('transportationTypeCode').value == 'REMOTE' ? ('testDrive.atRemoteLocation') :  ('testDrive.atDealershipLocation')) | translate }}
      </div>
      </div>
  </form>
</div>
