import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy,Output, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as util from '../services/util.service';
import {Make} from '../models/brand.model';
import {VehicleModelYearList} from '../models/service-appointments/series-data';
import {ActivatedRoute} from '@angular/router';
import {iif, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IToyotaLexusModelDetails, ServiceAppointmentState} from '../../store/service-appointment/service-appointment.reducer';
import * as fromSelectors from '../../store/service-appointment/service-appointment.selectors';
import {LoadModelYearAndModels} from '../../store/service-appointment/service-appointment.actions';
import {SubscriptionList} from '../models/asp.types';
import { mergeMap, switchMap} from 'rxjs/operators';
import * as fromValidator from '../../service-appointment/service-appointment.validator';
import {VehicleService} from '../services/vehicle.service';
import {VehicleImage} from '../models/vehicle-image';
import {IVehicle} from '@signal/asp-data-commons';
import {DashboardState} from '../../store/dashboard/dashboard.reducer';
import * as BdcDashboardActions from '../../store/dashboard/dashboard.actions';
import {selectUserVehicles, selectUserVehiclesLoading, selectMyUserVehiclesCount} from '../../store/dashboard/dashboard.selectors';
import {LearningVehicleCardComponent} from './learning-vehicle-card/learning-vehicle-card.component';
import * as fromDashboard from '../../store/dashboard/dashboard.selectors';
import { serviceSettings } from '../../store/dealer/dealer.selectors';
import { DealerState } from '../../store/dealer/dealer.reducer';
import { Telematics } from '../models/dashboard/telematics.model';
import { DateTime } from 'luxon';
import { FactoryPrintService } from '../components/factory-services-dialog/factory-print.service';
import { AspBannerService } from '../components/asp-banner/asp-banner.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SalesAppointmentState } from '../../store/sales-appointment/sales-appointment.reducer';
import { UpdatePatchSalesForm } from '../../store/sales-appointment/sales-appointment.actions';
import * as fromSalesSelectors from '../../store/sales-appointment/sales-appointment.selectors';
import { UserVehicleService } from '../services/user-vehicle.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-select-learning-vehicle',
  templateUrl: './select-learning-vehicle.component.html',
  styleUrls: ['./select-learning-vehicle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectLearningVehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() stepIndex = 0;
  @Input() showMileageField = true;
  @Output() toShowFooter=new EventEmitter();
  vehicles: IVehicle[] = [];
  step: FormGroup;

  title: string;
  switcherText: string;

  isMakeOther = false;
  isOwnVehicle = true;
  isVinMandate=false;
  makes: Make[] = [];
  models : string[] = [];
  yearmodels: string[] = [];
  preSelectedVin: string;
  telematicsLoading : boolean = false;
  isOwnVehicle$: Observable<number>;
  isUserVehiclesLoading$: Observable<boolean>;
  isEditMode$: Observable<any>;
  modelYearAndModelsLoading$: Observable<boolean>;
  newvehicle:any;
  milesPlaceholder : string;
  milesIsRequired : string;

  hasExistingAppt = false;
  existingApptText = '';
  isVehicleSelected:boolean=false;
  selectedVehicleDetails : {
    make:string,
    year:string,
    model:string,
  };
  previousMakeModelYear = '';
  vehicleSelect:boolean = false;
  signedIn:boolean = false;

  private modelYearSeries: VehicleModelYearList;
  private subs: SubscriptionList = {};
  private modelYearAndModelsData$: Observable<IToyotaLexusModelDetails>;
  public isEditMode: boolean;
  private lastVehicleSelected: string;
  private toyotaLexusModelDetails: IToyotaLexusModelDetails;
  private lastValue : any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly vehicleService: VehicleService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    private readonly bdcDashboardState: Store<DashboardState>,
    private readonly bannerService: AspBannerService,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly dealerState: Store<DealerState>,
    private factoryPrintService: FactoryPrintService,
    private readonly userVehicleService: UserVehicleService,
    private readonly translate: TranslateService,
    private readonly authService: AuthService
  ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      console.log('ss'+loggedIn)
      this.signedIn = loggedIn;
    });
    this.makes = util.getMakes();
    this.bindThis();
    this.milesPlaceholder = this.translate.instant(`service.${this.translate.instant('COUNTRYTRANSLATION.enterMile')}`);
    this.milesIsRequired = this.translate.instant(`service.${this.translate.instant('COUNTRYTRANSLATION.milesIsRequired')}`);

    this.activatedRoute.queryParams.subscribe(params => {
      this.isVehicleSelected = params.selectedVehicle ? true :false;
      if(params.selectedVehicle && !params.vin){
        this.selectedVehicleDetails = {
          year : params.year,
          make : params.make,
          model : params.model
        }
      }else{
        this.preSelectedVin = params.vin;
      }
    });
  }

  ngOnInit(): void {
    this.bdcDashboardState.select(fromDashboard.selectLearningAppointmentsForDashboard).subscribe((response)=>{
      const temp = []
      const uniqueResponse = response.map(obj => {
        if(!temp.includes(obj.vin)){
          temp.push(obj.vin)
          return obj
        }
      }).filter(f => f)
      this.newvehicle = uniqueResponse.filter((item)=>{
        return item.isOwnVehicle;
      });
     });
    this.ready();
  }

  onVehicleFieldChange(
    change: { field: string; value: string },
    vehicle: IVehicle
  ) {
    /* for now only mileage is changing, hence no checking for field & value */
    setTimeout(() => {
      if (this.step.controls.vin.value !== vehicle.vin) {
        this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
          path: 'vehicle',
          value: {...vehicle, mileage: +(change?.value || 0), isOwnVehicle: true}
        }));
      } else {
        this.setFormControlValue('mileage', +(change?.value || 0));
      }
    });
  }

  /**
   * Sets the necessary fields and form validators based on vehicle type 'Own' or 'Different'
   */
  toggleVehicleType() {
    this.resetAllInputs();
    if (this.isOwnVehicle) {
      this.setOwnVehicleFalse();
    } else {
      this.setOwnVehicleTrue();
    }
  }

  /**
   * Sets the subscriptions of the current step form
   *
   * 1. Make formControl - to check whether 'Other' is chosen or not
   */
  setFormSubscription() {
    this.subs.makeSub = this.step.get('make').valueChanges.subscribe((make: string) => {
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      if (this.isOtherMake(make)) {
        this.isMakeOther = true;
        this.step.controls.year.reset(null, {emitEvent: false});
        this.step.controls.model.reset(null, {emitEvent: false});
      } else {
        this.isMakeOther = false;
        if (make === 'Toyota') {
          this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
        } else if (make === 'Lexus') {
          this.modelYearSeries = this.toyotaLexusModelDetails.lexus;
        }
        this.step.controls.year.reset('', {emitEvent: false});
        this.step.controls.model.reset('', {emitEvent: false});
        const namesToDeleteSet = new Set(util.scionModelList);
        if(this.modelYearSeries){
          const modelArray = Object.keys(this.modelYearSeries).sort()
          const toyotaOrLexusModels = modelArray.filter(r=>{
            return !namesToDeleteSet.has(r);
          })
          const scionModels = modelArray.filter(r=>{
            return namesToDeleteSet.has(r);
          })
          if(make && make.toLocaleLowerCase() === 'scion'){
            this.models = scionModels;
          }else{
            this.models = toyotaOrLexusModels;
          }
        }else{
          this.models = [];
        }
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels = [...this.modelYearSeries[chosenYear]].reverse() as string[];
        }
      }
      this.resetGroupTwoFields(make);
    });

    this.subs.yearSub = this.step.get('model').valueChanges.subscribe((data: string) => {
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      if (!this.isMakeOther) {
        const chosenYear = this.step.controls.model.value;
        if (this.modelYearSeries && chosenYear) {
          this.yearmodels =  [...this.modelYearSeries[chosenYear]].reverse() as string[];
        }
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.modelSub = this.step.get('year').valueChanges.subscribe((data: string) => {
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      this.resetGroupTwoFields(data);
    });

    this.subs.vinSub = this.step.get('vin').valueChanges.subscribe((data: string) => {
      if (this.step.get('isOwnVehicle')?.value) {
        return;
      }
      this.resetGroupOneFields(data);
      if(data && data.length === 17)
     {   this.vehicleService.getVehicleColorForLexus({vin : data}, false).subscribe(data=>{
        if(data  && data.status?.code === 201 && data.data && data.data.length> 0){
          this.bannerService.show();
          this.step.controls.imageUrl.setValue(data.data[0].imageUrl);
          this.showVehicle(data.data[0].imageUrl);
        }
      },err=>{
        this.step.controls.imageUrl.setValue(undefined);
        this.bannerService.show();
        this.hideVehicle();
        })
    }
    });

    this.subs.vehicleImageSub = this.step.valueChanges.subscribe(value => {
      // const rawValue = this.step.getRawValue();
      /* When the vehicle info is partial, no need to set image */
      if (!(value.make && value.year && value.model)) {
        if(!this.isEditMode && !(value.vin)){
          this.hideVehicle();
        }
        return;
      }

      const chosenVehicle = `${value.make}#${value.year}#${value.model}`;
      if (this.lastVehicleSelected === chosenVehicle) {
        return;
      }

      this.lastVehicleSelected = chosenVehicle;
        /* Other makes don't have images in EFC */
        if (this.isOtherMake(value.make)) {
          this.step.controls.imageUrl.setValue(util.GLOBAL_CONSTANT.placeholderCarImage);
          return;
        }
        const brand = value.make.toUpperCase().substr(0, 3);
      let model;
      if(this.previousMakeModelYear !== `${value.make}${value.model}${value.year}`){
        this.previousMakeModelYear = `${value.make}${value.model}${value.year}`;
        this.hideVehicle();
      }
      if (value.make.toLowerCase() === 'toyota') {
        model = value.model.toLowerCase().replace(' ', '');
        if(!value.isOwnVehicle){
          this.vehicleService.getVehicleColors(brand, value.year, model, '').subscribe((data: VehicleImage) => {
            const exteriorColors = data.colors?.exterior;
            const exteriorColor = exteriorColors[0];
            const relativeURL = brand?.toLowerCase() === 'lex'?
            exteriorColor?.applicable[0]?.override?.images?.on_swatch_image :
            exteriorColor?.applicable[0]?.override?.images?.car_jelly_image ;
  
            /*Updating the image url in the form*/
            this.step.controls.imageUrl.setValue(relativeURL);
            this.bannerService.show();
            this.showVehicle(relativeURL);
          },err=>{
            this.step.controls.imageUrl.setValue(undefined);
            this.factoryPrintService.setUrl(undefined);
            this.bannerService.show();
            this.hideVehicle();
          });
        }else{
          this.showVehicle(value.imageUrl);
        }
      } else if (value.make.toLowerCase() === 'lexus') {
        model = value.model.toLowerCase().replace(/[0-9]/g, '').replace(' ', '');
        if(!value.isOwnVehicle){
          this.vehicleService.getVehicleColorForLexus(value, false).subscribe(data=>{
          if(data  && data.status?.code === 201 && data.data && data.data.model && data.data.model.length> 0){
            this.bannerService.show();
            this.step.controls.imageUrl.setValue(data.data.model[0]);
            this.showVehicle(data.data.model[0]);
          }else{
            this.step.controls.imageUrl.setValue(undefined);
            this.hideVehicle();
          }
        },err=>{
          this.step.controls.imageUrl.setValue(undefined);
          this.bannerService.show();
          this.hideVehicle();
          })
        }else{
          this.showVehicle(value.imageUrl);
        }
      }else{
        this.hideVehicle();
      }
    });

    this.subs.updateVehicleChanges = this.step.valueChanges.subscribe((data)=>{
      if(this.isValueOld(data)){
        return
      }
      
      this.lastValue = data;
      this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
        path: 'vehicle',
        value: {...this.vehicles[0], ...this.lastValue}
      }));
    })
  }

  resetGroupOneFields(value: string) {
    if (!!value) {
      this.hasExistingAppt = false;
     if(value.length === 17)
     {
       const queryText = value;
        this.bdcDashboardState.dispatch(new BdcDashboardActions.LoadAppointmentsForVin({ queryText }));
        this.bdcDashboardState.select(fromDashboard.selectAppointmentsForVin).subscribe(response=>{
          if(response && response.length>0 && response[0]){
            const dateArray = [];
            let count=0;
            const today = DateTime.now().toFormat('yyyy-MM-dd');
            const currentdate = DateTime.fromFormat(today,'yyyy-MM-dd');
            const length = response.length;
            for(let i=0;i<length;i++){
              const minDate=DateTime.fromFormat(response[i].appointmentStartDate,'yyyy-MM-dd');
              if(minDate>=currentdate && count <=9)
             {
              const displayDate = minDate.toFormat('MMM dd');
              dateArray.push(displayDate);
              this.hasExistingAppt = true;
              count++;
              }
            }
            const sortedDatesArray = this.sortDateArray(dateArray);
          const lastElement = count>1 && sortedDatesArray.length>0 ? 'and '+ sortedDatesArray.splice(count-1,1) : '';
            this.existingApptText = `${this.translate.instant('service.vehicleHasUpcomingAppointmentsScheduledOn')} ${dateArray.join()} ${lastElement}`;
          }else{
            this.hasExistingAppt = false;
            this.step.controls.isInputVinBased.setValue(true);
          }
          this.fetchMilageFromTelematics(value);
        });
     }
      this.step.controls.imageUrl.setValue(util.GLOBAL_CONSTANT.placeholderCarImage);
      this.step.controls.make.reset('', {emitEvent: false});
      this.step.controls.year.reset('', {emitEvent: false});
      this.step.controls.model.reset('', {emitEvent: false});
      this.step.controls.otherMake.reset('', {emitEvent: false});
    }
  }

  fetchMilageFromTelematics(vin:string){
    if(!this.telematicsLoading){
      if(this.isEditMode){
        this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
          path: 'vehicle',
          value: { ...this.vehicles[0], mileage: 'fetching data from telematics...' }
        }));
      }else{
        this.setFormControlValue("mileage",this.translate.instant('service.fetchingDataFromTelematics'));
      }
      if(this.isEditMode){
        this.setTelematics(vin,this.vehicles[0].mileage);
      }else{
        this.setTelematics(vin,null);
      }
    }
  }

  private setTelematics(vin,previousMilage) {
    this.telematicsLoading = true;
    const milesPlaceholderTxt = this.translate.instant(`service.${this.translate.instant('COUNTRYTRANSLATION.enterMile')}`);
    //this.vehicleService.getTelematics(vin)
    this.userVehicleService.getVehicleHealthReport(undefined,vin,vin)
      .subscribe(data => {
        if (data.status.code === '200') {
          this.milesPlaceholder = milesPlaceholderTxt;
          const vehicleStatus = data.vhr.vehicleStatus;
          const vehicleAlerts = data.vhr.vehicleAlerts;
          const telematicsData = Telematics.getTelematics(vehicleStatus, vehicleAlerts);
         
          const mileage = telematicsData?.mileage ? telematicsData?.mileage : previousMilage;
         
          if(this.isEditMode){
            this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
              path: 'vehicle',
              value: { ...this.vehicles[0], mileage: mileage }
            }));
          }else{
            this.step.controls['mileage'].setValue(mileage);
          }
        }
        this.step.controls['mileage'].enable();
        this.telematicsLoading = false;
      },err=>{
          this.milesPlaceholder = milesPlaceholderTxt;
          if(this.isEditMode){
            this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
              path: 'vehicle',
              value: { ...this.vehicles[0], mileage: previousMilage  }
            }));
          }else{
            this.step.controls['mileage'].setValue(previousMilage);
          }
          this.step.controls['mileage'].enable();
          this.telematicsLoading = false;
      });
  }

  resetGroupTwoFields(value: string) {
    if (!!value) {
      this.step.controls.isInputVinBased.setValue(false);
      this.hasExistingAppt = false;
      this.step.controls.mileage.setValue('');
      this.step.controls.vin.reset('', {emitEvent: false});
    }
  }

  /**
   * Triggers the value change event of the given formGroup, by re-setting one of the form control value
   *
   */
  triggerValueChangeEvent(step: FormGroup) {
    this.setFormControlValue('isOwnVehicle', step?.controls.isOwnVehicle.value);
  }

  /**
   * Sets the form control value to the given form control name in the formGroup
   */
  setFormControlValue(formControlName: string, formControlValue: any) {
    this.step.controls[formControlName].markAsDirty();
    this.step.controls[formControlName].setValue(formControlValue);
  }

  /**
   * Validates the VIN input having error for 17 digit validation
   *
   * @returns true if control is valid
   */
  isNot17Digit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[.]{17}$'
    );
  }

  /**
   * Validates the VIN input having error for alpha numeric validation
   */
  isNotCharAndDigit(): boolean {
    return (
      this.step.controls.vin.errors?.pattern &&
      this.step.controls.vin.errors?.pattern?.requiredPattern === '^[\\w\\d]*$'
    );
  }

  onKeyPress($event) {
    
  }

  ready() {
    this.prepareForm();
    this.setUpStore();
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
  }

  ngAfterViewInit(): void {
    
  }

  vehicleSelected(vehicle: IVehicle, vehicleCardComponent: LearningVehicleCardComponent) {
    this.vehicleSelect = true;
    this.step.patchValue({...vehicle});
    vehicleCardComponent.triggerOnMileageChange();
  }

  private resetAllInputs() {
    ['mileage', 'description', 'imageUrl'].forEach(fc => this.step.controls[fc].reset('', {emitEvent: false}));
    this.resetGroupOneFields('value');
    this.resetGroupTwoFields('value');
  }

  /**
   * Prepares the form group for vehicle selection with necessary form controls with default value
   */
  private prepareForm() {
    this.step = this.formBuilder.group({
        isOwnVehicle: [true],
        isInputVinBased: [false],
        description: [''],
        imageUrl: [''],
        vin: [''],
        make: [''],
        otherMake: [''],
        year: [''],
        model: [''],
        mileage: [0],
        protectionProducts: [],
        ppmEligible: [false]
      }
    );
    this.step.setValidators(fromValidator.chooseVehicleValidator());
    this.setFormSubscription();
    
  }

  isValueOld(newValue) {
    const lastValueString = JSON.stringify(this.lastValue);
    const newValueString = JSON.stringify(newValue);
    return lastValueString === newValueString;
  }

  private bindThis() {
    this.isNotCharAndDigit = this.isNotCharAndDigit.bind(this);
    this.isNot17Digit = this.isNot17Digit.bind(this);
  }

  private setOwnVehicleTrue() {
    this.isOwnVehicle = true;
    this.title = this.translate.instant('welcome.chooseFromMyVehicle');
    this.switcherText = this.translate.instant('testDrive.chooseAnotherVehicle');
    this.step.controls.isOwnVehicle.setValue(this.isOwnVehicle);
    if(!!this.preSelectedVin){
      if(this.preSelectedVin && this.preSelectedVin.length === 17)
      {   
        this.vehicleService.getVehicleColorForLexus({vin : this.preSelectedVin}, false).subscribe(data=>{
          if(data  && data.status?.code === 201 && data.data && data.data.length> 0){
            this.bannerService.show();
            this.step.patchValue({...data.data[0]});
            this.showVehicle(data.data[0].imageUrl);
          }
          },err=>{
          this.step.controls.imageUrl.setValue(undefined);
          this.bannerService.show();
          this.hideVehicle();
          })
      }
    }
  }

  private setOwnVehicleFalse() {
    this.isOwnVehicle = false;
    this.title = this.translate.instant('welcome.enterYourVehicleDetails');
    this.switcherText = this.translate.instant('welcome.chooseFromMyVehicle');
    this.step.controls.isOwnVehicle.setValue(this.isOwnVehicle);
  }

  private isOtherMake(make: string) {
    return make.toLowerCase() === 'other';
  }

  private setInitialVehicleSelection() {
    if (this.preSelectedVin && this.vehicles?.length) {
      const vehicle = this.vehicles.find(v => v.vin === this.preSelectedVin);
      if(vehicle === undefined && this.isVehicleSelected){
        this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
          path: 'vehicle', 
          value: {vin : this.preSelectedVin, isOwnVehicle: false}}));
        return;
      }
      if(this.isVehicleSelected){
        this.vehicles = this.vehicles.filter(vehicle=>{
         if( vehicle.vin===this.preSelectedVin) {
           this.step.patchValue({...vehicle});
          return vehicle;
         }
        });
      }
      if (this.step.controls.vin.value !== vehicle.vin) {
        this.setTelematics(vehicle.vin,vehicle.mileage);
        this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
          path: 'vehicle',
          value: {...vehicle, ppmEligible: this.step.get('ppmEligible').value, protectionProducts: this.step.get('protectionProducts').value,  isOwnVehicle: true}}));
      }
    }else if(this.selectedVehicleDetails && this.isVehicleSelected){
      this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
        path: 'vehicle',
        value: { make : this.selectedVehicleDetails.make , model : this.selectedVehicleDetails.model , year : this.selectedVehicleDetails.year , isOwnVehicle: false}}));
    }
  }

  private setUpStore() {
    this.serviceAppointmentState.dispatch(new LoadModelYearAndModels());

    /* Observables */
    this.isUserVehiclesLoading$ = this.bdcDashboardState.select(selectUserVehiclesLoading);
    this.isEditMode$ = this.salesAppointmentState.select(fromSalesSelectors.isEditModeInEditDetails);
    this.isOwnVehicle$ = this.bdcDashboardState.select(selectMyUserVehiclesCount);
    this.modelYearAndModelsData$ = this.serviceAppointmentState.select(fromSelectors.selectModelYearAndModelsData);
    this.modelYearAndModelsLoading$ = this.serviceAppointmentState.select(fromSelectors.selectModelYearAndModelsLoading);

    /* Explicit subscriptions to the store slices */
    this.subs.isOwnVehicleSub = this.isOwnVehicle$.subscribe(vec => {
      vec>0 ? this.setOwnVehicleTrue() : this.setOwnVehicleFalse();

    });
    this.dealerState.select(serviceSettings).subscribe(settings => {
      this.isVinMandate = settings?.portal?.mandateVin;
      if (this.isVinMandate) {
        this.step.controls.vin.setValidators(this.vinValidator());
      }
    });

      this.isEditMode$.pipe(
        switchMap((obj: any) =>
          iif(() => {
              this.isEditMode = obj;
              return this.isEditMode;
            },
          ))).subscribe((customer)=>{
            if(!this.isEditMode && !customer){
              this.salesAppointmentState.dispatch(new UpdatePatchSalesForm({
                path: 'vehicle',
                value: { ...this.step.value, mileage: "", isOwnVehicle: false }
              }));
          }
          }
          )
    /* For the guest user the default value for 'isOwnVehicle' is false */
    /*if (!this.signedIn) {
      const vehicle = {...this.step.value, isOwnVehicle: false};
      this.serviceAppointmentState.dispatch(new UpdateServiceForm({path: 'vehicleForm', value: vehicle}));
    }*/

    this.subs.displayVehiclesSub =
      this.isEditMode$.pipe(
        switchMap((obj: any) =>
          iif(() => {
              this.isEditMode = obj;
              return this.isEditMode;
            },
            this.salesAppointmentState.select(fromSalesSelectors.editAppointmentVehicle),
            this.bdcDashboardState.select(selectUserVehicles)
          ))).subscribe(vehicles => {
        this.vehicles = vehicles;
        if (this.isEditMode) {
            this.prepareFormForEdit(vehicles);
          } else {
            this.setInitialVehicleSelection();
          }
      });

    this.subs.modelYearAndModelsDataSub = this.isEditMode$.pipe(
      mergeMap((isEditMode: boolean) =>
        iif(() => !isEditMode, this.modelYearAndModelsData$)
      )
    ).subscribe(data => {
      this.toyotaLexusModelDetails = data;
      if (this.toyotaLexusModelDetails.lexus) {
        this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
        this.modelYearSeries = this.toyotaLexusModelDetails.toyota;
      const namesToDeleteSet = new Set(util.scionModelList);
      if(this.modelYearSeries){
        const modelArray = Object.keys(this.modelYearSeries).sort()
        const toyotaOrLexusModels = modelArray.filter(r=>{
          return !namesToDeleteSet.has(r);
        })
        const scionModels = modelArray.filter(r=>{
          return namesToDeleteSet.has(r);
        })
        if(this.step.value.make && this.step.value.make.toLocaleLowerCase() === 'scion'){
          this.models = scionModels;
        }else{
          this.models = toyotaOrLexusModels;
        }
      }else{
        this.models = [];
      }
      }
    });
  }

  private prepareFormForEdit(vehicles) {
    this.bannerService.update(vehicles[0].imageUrl);
    this.bannerService.showCarImage();
    this.unsubCreateModeSubscription();

    /* Disable the formControls (fc) which are not editable */
    ['make', 'otherMake', 'year', 'model', 'vin']
      .forEach(fc => this.step.controls[fc]
        .disable({emitEvent: false}));
    if(vehicles[0].vin){
      this.fetchMilageFromTelematics(vehicles[0].vin)
    }
    this.yearmodels = vehicles.map(v => v.year);
    this.models = vehicles.map(v => v.model);
    this.isMakeOther=(vehicles[0].make && vehicles[0].make.toString().toLowerCase()==='other' && 
    vehicles[0].isOwnVehicle===false) ? true : false;
    this.step.patchValue(vehicles[0]);
    this.telematicsLoading = true;
    this.subs.displayVehiclesSub?.unsubscribe();
    this.subs.updateVehicleChanges.unsubscribe();
  }

  private unsubCreateModeSubscription() {
    ['vinSub', 'modelSub', 'yearSub', 'makeSub']
      .forEach( sub => this.subs[sub]?.unsubscribe());
  }
  toShowFooterDetails(event){
    this.toShowFooter.emit(event);
  }
  vinValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (!this.step.controls.vin.value) {
        return { required: this.translate.instant('service.vinIsRequired') };
      } else {
        return null;
      }
    };
  }
  private sortDateArray(dateArray:string[]):string[]{
    const sortedDatesArray = dateArray.sort((a, b) => {
      return <any>new Date(b) - <any>new Date(a);
    });
    return sortedDatesArray.reverse()
  }
  showVehicle(relativeURL){
    this.bannerService.showCarImage();
    this.bannerService.update(relativeURL);
    this.factoryPrintService.setUrl(relativeURL);
  }
  hideVehicle(){
    this.bannerService.hideCarImage();
    this.bannerService.update(undefined);
    this.factoryPrintService.base64CarImg = undefined;
  }
  isVehicleAtOwnedVehicles(){
    return _.every(this.newvehicle.map(vehicle => this.vehicles[0]?.vin !== vehicle?.vin));
  }
}
